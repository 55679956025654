// Config(s)
import { VIEW } from '../utils/constants';

const AIRPAX_MODE_OPTION = {
  type: 'select',
  options: [
    {
      value: 'AIR_PASSENGER',
      label: 'Air passenger',
    },
  ],
};

const MODE_OPTIONS = {
  [VIEW.RORO]: {
    type: 'checkboxes',
    options: [
      {
        value: 'RORO_UNACCOMPANIED_FREIGHT',
        label: 'RoRo unaccompanied freight',
      },
      {
        value: 'RORO_ACCOMPANIED_FREIGHT',
        label: 'RoRo accompanied freight',
      },
      {
        value: 'RORO_TOURIST',
        label: 'RoRo Tourist',
      },
    ],
  },
  [VIEW.AIRPAX]: AIRPAX_MODE_OPTION,
  [VIEW.AIRPAX_V2]: AIRPAX_MODE_OPTION,
  [VIEW.COMMODITIES]: AIRPAX_MODE_OPTION,
  [VIEW.CTBP]: AIRPAX_MODE_OPTION,
  [VIEW.IDP]: AIRPAX_MODE_OPTION,
  [VIEW.OSDT]: AIRPAX_MODE_OPTION,
  [VIEW.GENERAL_AVIATION]: {
    type: 'select',
    options: [
      {
        value: 'GENERAL_AVIATION',
        label: 'General aviation',
      },
    ],
  },
};

const DATETIME_FILTER_OPTIONS = [
  {
    value: 'IS',
    label: 'is',
  },
  {
    value: 'IS_IN_DATE_RANGE',
    label: 'is in date range',
  },
  {
    value: 'IS_IN_DATE_AND_TIME_RANGE',
    label: 'is in date and time range',
  },
];

const getClaimedByComponent = (claimedBy) => {
  return {
    id: 'claimedByMe',
    fieldId: 'claimedByMe',
    type: 'checkboxes',
    className: 'govuk-checkboxes--small',
    label: 'Claimed/actioned by me',
    data: {
      options: [
        {
          value: claimedBy,
          label: 'Claimed/actioned by me',
        },
      ],
    },
  };
};

const toPlaceHolderText = (view) => {
  const DEFAULT_PLACEHOLDER = 'Passenger Name or Task ID';

  if ([VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.COMMODITIES, VIEW.CTBP, VIEW.IDP, VIEW.OSDT].includes(view)) {
    return DEFAULT_PLACEHOLDER;
  }

  if (VIEW.GENERAL_AVIATION === view) {
    return `ARN, ${DEFAULT_PLACEHOLDER}`;
  }

  return `VRN, TRN, ${DEFAULT_PLACEHOLDER}`;
};

const tasksFilter = (claimedBy, showClaimedBy, view) => {
  return {
    id: 'filter',
    version: '1.0.0',
    name: 'filter',
    type: 'form',
    components: [],
    pages: [
      {
        id: 'filter',
        name: 'filter',
        components: [
          showClaimedBy && getClaimedByComponent(claimedBy),
          {
            id: 'targetingTeams',
            fieldId: 'targetingTeams',
            label: 'Targeting Teams',
            'aria-label': 'Targeting Teams',
            type: 'autocomplete',
            openOnClick: true,
            dynamicOptions: true,
            multi: true,
            useCustomOptions: true,
            sort: {
              direction: 'ASC',
              sortBy: 'value',
            },
            show_when: {
              field: 'mode',
              op: 'eq',
              value: 'AIR_PASSENGER',
            },
            hideOptionalSuffix: true,
          },
          {
            id: 'rules',
            fieldId: 'rules',
            label: 'Rule matches',
            'aria-label': 'Rule Matches',
            type: 'autocomplete',
            openOnClick: true,
            dynamicOptions: true,
            multi: true,
            useCustomOptions: true,
            sort: {
              direction: 'ASC',
              sortBy: 'name',
            },
            hideOptionalSuffix: true,
          },
          {
            id: 'selectors',
            fieldId: 'selectors',
            label: 'Selectors',
            type: 'radios',
            className: 'govuk-radios--small',
            required: true,
            dynamicOptions: true,
            data: {
              options: [
                {
                  value: 'NOT_PRESENT',
                  label: 'Has no selector',
                },
                {
                  value: 'PRESENT',
                  label: 'Has selector',
                },
                {
                  value: 'ANY',
                  label: 'Both',
                },
              ],
            },
          },
          {
            id: 'journeyDirections',
            fieldId: 'journeyDirections',
            label: 'Direction of travel',
            className: 'govuk-checkboxes--small',
            type: 'checkboxes',
            dynamicOptions: true,
            data: {
              options: [
                {
                  value: 'INBOUND',
                  label: 'Inbound',
                },
                {
                  value: 'OUTBOUND',
                  label: 'Outbound',
                },
                (view !== VIEW.GENERAL_AVIATION
                && {
                  value: 'UNKNOWN',
                  label: 'Unknown',
                }),
              ].filter((option) => !!option),
            },
            hideOptionalSuffix: true,
          },
          {
            id: 'passengerStatuses',
            fieldId: 'passengerStatuses',
            label: 'Passenger status',
            type: 'checkboxes',
            className: 'govuk-checkboxes--small',
            dynamicOptions: true,
            data: {
              options: [
                {
                  value: 'BOOKED_PASSENGER',
                  label: 'Booked passenger',
                },
                {
                  value: 'CHECKED_IN',
                  label: 'Checked in',
                },
                {
                  value: 'DEPARTURE_CONFIRMED',
                  label: 'Departure confirmed',
                },
                {
                  value: 'DEPARTURE_EXCEPTION',
                  label: 'Departure exception',
                },
              ],
            },
            show_when: {
              field: 'mode',
              op: 'eq',
              value: 'AIR_PASSENGER',
            },
            hideOptionalSuffix: true,
          },
          {
            id: 'journeyId',
            fieldId: 'journeyId',
            label: 'Search by Voyage Code',
            type: 'text',
            hideOptionalSuffix: true,
          },
          {
            id: 'arrivalDate',
            fieldId: 'arrivalDate',
            label: 'Arrival date in UK',
            'aria-label': 'Arrival date in UK',
            type: 'autocomplete',
            openOnClick: true,
            clearable: true,
            hideOptionalSuffix: true,
            data: {
              options: DATETIME_FILTER_OPTIONS,
            },
          },
          {
            id: 'arrivalIsStartDate',
            fieldId: 'arrivalIsStartDate',
            label: 'Date',
            type: 'date',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0 no-label',
            show_when: {
              field: 'arrivalDate.value',
              op: 'eq',
              value: 'IS',
            },
          },
          {
            id: 'arrivalIsFromStartDate',
            fieldId: 'arrivalIsFromStartDate',
            label: 'From',
            type: 'date',
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'arrivalDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_RANGE',
            },
          },
          {
            id: 'arrivalIsToEndDate',
            fieldId: 'arrivalIsToEndDate',
            label: 'To',
            type: 'date',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'arrivalDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_RANGE',
            },
          },
          {
            id: 'arrivalRangeIsStartDate',
            fieldId: 'arrivalRangeIsStartDate',
            label: 'From',
            type: 'datetime',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'arrivalDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_AND_TIME_RANGE',
            },
          },
          {
            id: 'arrivalRangeIsEndDate',
            fieldId: 'arrivalRangeIsEndDate',
            label: 'To',
            type: 'datetime',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'arrivalDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_AND_TIME_RANGE',
            },
          },
          {
            id: 'departureDate',
            fieldId: 'departureDate',
            label: 'Departure date from UK',
            'aria-label': 'Departure date from UK',
            type: 'autocomplete',
            openOnClick: true,
            clearable: true,
            hideOptionalSuffix: true,
            data: {
              options: DATETIME_FILTER_OPTIONS,
            },
          },
          {
            id: 'departureIsStartDate',
            fieldId: 'departureIsStartDate',
            label: 'Date',
            type: 'date',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0 no-label',
            show_when: {
              field: 'departureDate.value',
              op: 'eq',
              value: 'IS',
            },
          },
          {
            id: 'departureIsFromStartDate',
            fieldId: 'departureIsFromStartDate',
            label: 'From',
            type: 'date',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'departureDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_RANGE',
            },
          },
          {
            id: 'departureIsToEndDate',
            fieldId: 'departureIsToEndDate',
            label: 'To',
            type: 'date',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'departureDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_RANGE',
            },
          },
          {
            id: 'departureRangeIsStartDate',
            fieldId: 'departureRangeIsStartDate',
            label: 'From',
            type: 'datetime',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'departureDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_AND_TIME_RANGE',
            },
          },
          {
            id: 'departureRangeIsEndDate',
            fieldId: 'departureRangeIsEndDate',
            label: 'To',
            type: 'datetime',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'departureDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_AND_TIME_RANGE',
            },
          },
          {
            id: 'taskCreatedDate',
            fieldId: 'taskCreatedDate',
            label: 'Date created',
            'aria-label': 'Date created',
            type: 'autocomplete',
            openOnClick: true,
            clearable: true,
            hideOptionalSuffix: true,
            data: {
              options: DATETIME_FILTER_OPTIONS,
            },
          },
          {
            id: 'taskCreatedDateIsStartDate',
            fieldId: 'taskCreatedDateIsStartDate',
            label: 'Date',
            type: 'date',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0 no-label',
            show_when: {
              field: 'taskCreatedDate.value',
              op: 'eq',
              value: 'IS',
            },
          },
          {
            id: 'taskCreatedDateIsFromStartDate',
            fieldId: 'taskCreatedDateIsFromStartDate',
            label: 'From',
            type: 'date',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'taskCreatedDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_RANGE',
            },
          },
          {
            id: 'taskCreatedDateIsToEndDate',
            fieldId: 'taskCreatedDateIsToEndDate',
            label: 'To',
            type: 'date',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'taskCreatedDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_RANGE',
            },
          },
          {
            id: 'taskCreatedDateRangeIsStartDate',
            fieldId: 'taskCreatedDateRangeIsStartDate',
            label: 'From',
            type: 'datetime',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'taskCreatedDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_AND_TIME_RANGE',
            },
          },
          {
            id: 'taskCreatedDateRangeIsEndDate',
            fieldId: 'taskCreatedDateRangeIsEndDate',
            label: 'To',
            type: 'datetime',
            clearable: true,
            hideOptionalSuffix: true,
            className: 'margin-bottom-0 padding-left-0',
            show_when: {
              field: 'taskCreatedDate.value',
              op: 'eq',
              value: 'IS_IN_DATE_AND_TIME_RANGE',
            },
          },
          {
            id: 'searchText',
            fieldId: 'searchText',
            label: 'Search',
            type: 'text',
            placeholder: toPlaceHolderText(view),
            'aria-label': `Search by ${toPlaceHolderText(view)}`,
            hideOptionalSuffix: true,
          },
          {
            id: 'mode',
            fieldId: 'mode',
            label: 'Mode',
            type: MODE_OPTIONS[view].type,
            className: 'govuk-checkboxes--small',
            dynamicOptions: true,
            data: {
              options: MODE_OPTIONS[view].options,
            },
            hideOptionalSuffix: true,
            show_when: {
              field: 'mode',
              op: 'nin',
              values: ['AIR_PASSENGER', 'GENERAL_AVIATION'],
            },
          },
        ],
        actions: [
          {
            type: 'submit',
            validate: true,
            label: 'Apply',
          },
        ],
      },
    ],
  };
};

export default tasksFilter;
