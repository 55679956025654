// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';

import ExtendedListRowElement from './ExtendedListRowElement';
import './ExtendedListRow.scss';

const ExtendedListRow = ({ id, activeClass, mobile, type, values, onResizeFactory }) => {
  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 50,
    onResize: onResizeFactory('container'),
  });

  return (
    <div
      id={`${id}-extended-summary-list__row`}
      ref={ref}
      className={classNames(
        'govuk-extended-summary-list__row',
        `govuk-extended-summary-type-${type}`,
        activeClass !== '' && activeClass,
      )}
    >
      {values.map(
        (value, index) => {
          return (
            <ExtendedListRowElement
              key={value}
              value={value}
              index={index}
              id={id}
              activeClass={activeClass}
              mobile={mobile}
              onResizeFactory={onResizeFactory}
            />
          );
        },
      )}
    </div>
  );
};

ExtendedListRow.propTypes = {
  activeClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  mobile: PropTypes.bool,
  type: PropTypes.string.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
  ]).isRequired,
  onResizeFactory: PropTypes.func,
};

ExtendedListRow.defaultProps = {
  activeClass: '',
  mobile: false,
  onResizeFactory: () => {},
};

export default memo(ExtendedListRow);
