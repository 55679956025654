import React, { createContext, useContext, useMemo, useState } from 'react';

// Config
import {
  FILTER_DISPLAY_CHOICE,
  FILTER_DISPLAY_DEFAULT,
  FILTER_DISPLAY_SHOW,
  FILTER_DISPLAY_HIDE,
} from '../utils/constants';

const FilterToggleContext = createContext({
  toggleState: 'hide',
  setToggleState: () => {},
});

const FilterToggleProvider = ({ children }) => {
  const [toggleState, setFilterState] = useState(FILTER_DISPLAY_DEFAULT);

  // Different name for the function, but we need to over-ride
  // here to add the property in local storage; this will last
  // over the life time of the browser - the only way we can
  // "store" the user's choice;
  const setToggleState = (str) => {
    const currentState = localStorage.getItem(FILTER_DISPLAY_CHOICE);
    const nextState = currentState === FILTER_DISPLAY_DEFAULT
      ? FILTER_DISPLAY_SHOW
      : FILTER_DISPLAY_HIDE;
    if (str && [FILTER_DISPLAY_SHOW, FILTER_DISPLAY_HIDE].includes(str)) {
      localStorage.setItem(FILTER_DISPLAY_CHOICE, str);
      setFilterState(str);
    } else {
      localStorage.setItem(FILTER_DISPLAY_CHOICE, nextState);
      setFilterState(nextState);
    }
  };

  const value = useMemo(() => ({
    toggleState,
    setToggleState,
  }), [toggleState, setToggleState]);

  return (
    <FilterToggleContext.Provider value={value}>{children}</FilterToggleContext.Provider>
  );
};

const useFilterToggle = () => useContext(FilterToggleContext);

export { FilterToggleContext, FilterToggleProvider, useFilterToggle };
