import { FORM_ACTIONS } from '../utils/constants';

const YES = { value: 'yes', label: 'Yes I need to view PNR data' };
const NO = { value: 'no', label: 'No I do not need to view PNR data' };

export default {
  id: 'view-pnr-data-request',
  version: '1.0.1',
  name: 'cop-view-pnr-data',
  title: 'View passenger name record data',
  type: 'wizard',
  components: [],
  pages: [
    {
      id: 'view-pnr-data',
      name: 'view-pnr-data',
      title: 'Do you need to view Passenger Name Record (PNR) data?',
      components: [
        'This includes all PNR from the last 6 months and PNR data more than 6 months old.',
        'You only need access if you\'re working to prevent, detect or investigate terrorist offences or serious crime, or to protect the vital interests of an individual.',
        'This has to be a necessary and proportionate requirement of the work you are doing.',
        {
          id: 'viewPnrData',
          fieldId: 'viewPnrData',
          type: 'radios',
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'Select if you want to view PNR data',
            },
          ],
          data: {
            options: [YES, NO],
          },
        },
      ],
      actions: [
        {
          type: FORM_ACTIONS.NEXT,
          label: 'Continue',
          validate: true,
        },
      ],
    },
    {
      id: 'working-from-approved-site',
      name: 'working-from-approved-site',
      title: 'Working from an approved access site',
      components: [
        'You need to be working from an approved access site to view PNR data. You cannot access PNR data from a home or public space.',
        'Are you working from a site that has been approved to access PNR data?',
        {
          id: 'approvedSite',
          fieldId: 'approvedSite',
          type: 'radios',
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'Select if you are working from an approved site',
            },
          ],
          data: {
            options: [
              {
                ...YES,
                label: 'Yes',
              },
              {
                ...NO,
                label: 'No',
              },
            ],
          },
        },
      ],
      show_when: {
        field: 'viewPnrData',
        op: 'eq',
        value: YES.value,
      },
      actions: [
        {
          type: FORM_ACTIONS.NAVIGATE,
          label: 'Back',
          page: 'view-pnr-data',
          classModifiers: 'secondary',
        },
        {
          type: FORM_ACTIONS.NEXT,
          label: 'Continue',
          validate: true,
        },
      ],
    },
    {
      id: 'continue-without-pnr-data',
      name: 'continue-without-pnr-data',
      title: 'Continue without viewing PNR data',
      components: [],
      show_when: {
        field: 'viewPnrData',
        op: 'eq',
        value: NO.value,
      },
      actions: [
        {
          type: FORM_ACTIONS.NAVIGATE,
          label: 'Back',
          page: 'view-pnr-data',
          classModifiers: 'secondary',
        },
        {
          type: FORM_ACTIONS.FINISH,
          label: 'Continue',
          validate: true,
        },
      ],
    },
    {
      id: 'can-only-view-pnr-data',
      name: 'can-only-view-pnr-data',
      title: 'Working from an approved access site',
      components: [
        'You need to be working from an approved access site to view PNR data.',
      ],
      show_when: {
        field: 'approvedSite',
        op: 'eq',
        value: NO.value,
      },
      actions: [
        {
          type: FORM_ACTIONS.NAVIGATE,
          label: 'Back',
          page: 'working-from-approved-site',
          classModifiers: 'secondary',
        },
        {
          type: FORM_ACTIONS.FINISH,
          label: 'Continue without access to PNR data',
          validate: true,
        },
      ],
    },
    {
      id: 'can-view-pnr-data',
      name: 'can-view-pnr-data',
      components: [
        {
          type: 'html',
          tagName: 'div',
          content: '<h1 class="govuk-panel__title">PNR data will be masked until adequate justification to show it is provided</h1>',
          className: 'govuk-panel govuk-panel__body govuk-panel--confirmation',
        },
        {
          type: 'html',
          tagName: 'h1',
          content: 'What happens next',
          className: 'govuk-heading-m',
        },
        {
          type: 'html',
          tagName: 'ul',
          content: '<li class="govuk-body-m">PNR data up to 6 months old will be hidden unless adequate justification is given for viewing it. Adequate justification means if the crime being investigated carries a sentence of 3 years or more</li>'
            + '<li class="govuk-body-m">PNR data over 6 months old will be hidden but you will be able to provide justifications to view this data</li>',
        },
      ],
      show_when: {
        field: 'approvedSite',
        op: 'eq',
        value: YES.value,
      },
      actions: [
        {
          type: FORM_ACTIONS.FINISH,
          label: 'Continue',
          validate: true,
        },
      ],
    },
  ],
};
