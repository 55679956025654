export const COMPONENT_IDS = {
  ABUSE_TYPES: 'suspectedAbuseType',
  ADDITIONAL: 'additionalChecks',
  BORDER_OFFICER: 'borderOfficer',
  BORDER_OFFICERS: 'borderOfficers',
  CREW: 'crewChecks',
  CREW_OTHER: 'crewChecksOtherDetail',
  CUSTOMS: 'customsChecks',
  FRONTLINE_CHECKS: 'frontlineChecks',
  CUSTOMS_OTHER: 'customsChecksOtherDetail',
  IMMIGRATION: 'immigrationChecks',
  IMMIGRATION_OTHER: 'immigrationChecksOtherDetail',
  INTELLIGENCE: 'intelligenceGatheringChecks',
  INTELLIGENCE_OTHER: 'intelligenceGatheringChecksOtherDetail',
  ISSUING_HUB: 'issuingHub',
  OPERATION: 'operation',
  PNR_ELEMENTS: 'selectedPnrElements',
  PUBLIC_INTEREST_IMMUNITY: 'publicInterestImmunity',
  RECIPIENT: 'targetRecipient',
  RECIPIENTS: 'targetRecipients',
  REFERRAL_REASON: 'referralReason',
  TARGET_CATEGORY: 'targetCategory',
  OTHER_TYPES: 'otherTypes',
  OTHER_TYPES_IDP: 'otherTypesIdp',
  WATCHLIST: 'addedToWatchList',
};

// Update this collection as more components are added.
export const AIRPAX_EXCLUDED_COMPONENTS = [
  COMPONENT_IDS.OTHER_TYPES,
  COMPONENT_IDS.OTHER_TYPES_IDP,
  COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY,
  COMPONENT_IDS.ISSUING_HUB,
  COMPONENT_IDS.OPERATION,
  COMPONENT_IDS.CREW,
  COMPONENT_IDS.CUSTOMS,
  COMPONENT_IDS.IMMIGRATION,
  COMPONENT_IDS.INTELLIGENCE,
  COMPONENT_IDS.CREW_OTHER,
  COMPONENT_IDS.CUSTOMS_OTHER,
  COMPONENT_IDS.IMMIGRATION_OTHER,
  COMPONENT_IDS.INTELLIGENCE_OTHER,
  COMPONENT_IDS.ADDITIONAL,
  COMPONENT_IDS.RECIPIENT,
  COMPONENT_IDS.BORDER_OFFICER,
  COMPONENT_IDS.PNR_ELEMENTS,
];

// Update this collection for GA if needed.
// TODO Remove the recipients once we get the code added in;
export const GA_EXCLUDED_COMPONENTS = [
  COMPONENT_IDS.ABUSE_TYPES,
  COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY,
  COMPONENT_IDS.RECIPIENT,
  COMPONENT_IDS.OTHER_TYPES,
  COMPONENT_IDS.OTHER_TYPES_IDP,
  COMPONENT_IDS.ISSUING_HUB,
  COMPONENT_IDS.OPERATION,
  COMPONENT_IDS.WATCHLIST,
];

export const FRONTLINE_CHECK_OPTION_VALUES = {
  IMMIGRATION_CHECKS: 'IMMIGRATION_CHECKS',
  IMMIGRATION_CHECKS_OTHER_DETAIL: 'IMMIGRATION_CHECKS_OTHER_DETAIL',
  CUSTOMS_CHECKS: 'CUSTOMS_CHECKS',
  CUSTOMS_CHECKS_OTHER_DETAIL: 'CUSTOMS_CHECKS_OTHER_DETAIL',
  INTELLIGENCE_GATHERING_CHECKS: 'INTELLIGENCE_GATHERING_CHECKS',
  INTELLIGENCE_GATHERING_CHECKS_OTHER_DETAIL: 'INTELLIGENCE_GATHERING_CHECKS_OTHER_DETAIL',
  CREW_CHECKS: 'CREW_CHECKS',
  CREW_CHECKS_OTHER_DETAIL: 'CREW_CHECKS_OTHER_DETAIL',
  ADDITIONAL_CHECKS: 'ADDITIONAL_CHECKS',
};

export const FRONTLINE_CHECK_VALUE_OPTIONS = {
  [FRONTLINE_CHECK_OPTION_VALUES.IMMIGRATION_CHECKS]: [
    {
      label: 'Establish how many persons are on board',
      value: 'Establish how many persons are on board',
    },
    {
      label: 'Compare persons to manifest/GAR and check for discrepancies',
      value: 'Compare persons to manifest/GAR and check for discrepancies',
    },
    {
      label: 'Compare immigration documents produced to that listed on manifest/GAR',
      value: 'Compare immigration documents produced to that listed on manifest/GAR',
    },
    {
      label: 'Are the persons producing the immigration documents the rightful holders?',
      value: 'Are the persons producing the immigration documents the rightful holders?',
    },
    {
      label: 'Are the documents genuine?',
      value: 'Are the documents genuine?',
    },
    {
      label: 'Have the persons on board ever been refused entry into the UK previously?',
      value: 'Have the persons on board ever been refused entry into the UK previously?',
    },
    {
      label: 'Do they hold any other nationality?',
      value: 'Do they hold any other nationality?',
    },
    {
      label: 'Have they travelled with their other nationality/immigration documents?',
      value: 'Have they travelled with their other nationality/immigration documents?',
    },
    {
      label: 'Please provide more details of their other nationality/immigration documents',
      value: 'Please provide more details of their other nationality/immigration documents',
    },
    {
      label: 'Do they require a visa, endorsement or concession for entry?',
      value: 'Do they require a visa, endorsement or concession for entry?',
    },
    {
      label: 'Was the visa, endorsement or concession in place before interception?',
      value: 'Was the visa, endorsement or concession in place before interception?',
    },
    {
      label: 'Was the visa, endorsement or concession granted/issued by the Border Force after interception?',
      value: 'Was the visa, endorsement or concession granted/issued by the Border Force after interception?',
    },
  ],
  [FRONTLINE_CHECK_OPTION_VALUES.CUSTOMS_CHECKS]: [
    {
      label: 'Please undertake search of persons on board',
      value: 'Please undertake search of persons on board',
    },
    {
      label: 'Do they have baggage?',
      value: 'Do they have baggage?',
    },
    {
      label: 'How much and type of baggage?',
      value: 'How much and type of baggage?',
    },
    {
      label: 'Is the amount of baggage commensurate with the number of persons and the journey undertaken?',
      value: 'Is the amount of baggage commensurate with the number of persons and the journey undertaken?',
    },
    {
      label: 'How heavy are the bags?',
      value: 'How heavy are the bags?',
    },
    {
      label: 'Please undertake search of baggage',
      value: 'Please undertake search of baggage',
    },
    {
      label: 'Please undertake rummage of aircraft for any stowed undeclared and/or prohibited items',
      value: 'Please undertake rummage of aircraft for any stowed undeclared and/or prohibited items',
    },
    {
      label: 'Please use dog resource if available',
      value: 'Please use dog resource if available',
    },
    {
      label: 'Have they declared any items/commodities subject to Customs Control?',
      value: 'Have they declared any items/commodities subject to Customs Control?',
    },
    {
      label: 'Do the hold any items/commodities subject to Customs Control?',
      value: 'Do the hold any items/commodities subject to Customs Control?',
    },
    {
      label: 'Is paperwork/certification in place for the items/commodities subject to Customs Control?',
      value: 'Is paperwork/certification in place for the items/commodities subject to Customs Control?',
    },
    {
      label: 'Are the items/commodities liable for duty?',
      value: 'Are the items/commodities liable for duty?',
    },
    {
      label: 'Has duty been paid?',
      value: 'Has duty been paid?',
    },
    {
      label: 'Have the items/commodities been seized and/or restored after payment of duty?',
      value: 'Have the items/commodities been seized and/or restored after payment of duty?',
    },
    {
      label: 'Is the aircraft being imported and subject to duty?',
      value: 'Is the aircraft being imported and subject to duty?',
    },
    {
      label: 'Has the import of the aircraft been properly declared and is paperwork/duty fees in place?',
      value: 'Has the import of the aircraft been properly declared and is paperwork/duty fees in place?',
    },
  ],
  [FRONTLINE_CHECK_OPTION_VALUES.INTELLIGENCE_GATHERING_CHECKS]: [
    {
      label: 'What is the purpose of travel?',
      value: 'What is the purpose of travel?',
    },
    {
      label: 'How do the persons on board know each other?',
      value: 'How do the persons on board know each other?',
    },
    {
      label: 'What was the method of payment for this journey?',
      value: 'What was the method of payment for this journey?',
    },
    {
      label: 'Who paid for the journey?',
      value: 'Who paid for the journey?',
    },
    {
      label: 'Occupations of persons on board',
      value: 'Occupations of persons on board',
    },
    {
      label: 'Have they routed through any other countries during this journey?',
      value: 'Have they routed through any other countries during this journey?',
    },
  ],
  [FRONTLINE_CHECK_OPTION_VALUES.CREW_CHECKS]: [
    {
      label: 'How many crew?',
      value: 'How many crew?',
    },
    {
      label: 'Is number of crew commensurate with the number of persons on board, journey undertaken and size of aircraft?',
      value: 'Is number of crew commensurate with the number of persons on board, journey undertaken and size of aircraft?',
    },
    {
      label: 'List job roles of crew members',
      value: 'List job roles of crew members',
    },
    {
      label: 'Are the crew in transit?',
      value: 'Are the crew in transit?',
    },
    {
      label: 'If so where are they flying onwards to?',
      value: 'If so where are they flying onwards to?',
    },
    {
      label: 'Are crew disembarking the aircraft?',
      value: 'Are crew disembarking the aircraft?',
    },
    {
      label: 'Does crew concession apply?',
      value: 'Does crew concession apply?',
    },
    {
      label: 'If crew concession does not apply how do they intend to regularise their stay in the UK?',
      value: 'If crew concession does not apply how do they intend to regularise their stay in the UK?',
    },
  ],
  [FRONTLINE_CHECK_OPTION_VALUES.ADDITIONAL_CHECKS]: [],
};

export const COMPONENT_ERRORS = {
  [COMPONENT_IDS.ABUSE_TYPES]: {
    message: 'Select the suspected abuse type/s',
    summary: 'Select the suspected abuse type/s',
  },
  [COMPONENT_IDS.RECIPIENTS]: {
    message: 'Select a frontline team to send the target to',
    summary: 'Select a frontline team to send the target to',
  },
  [COMPONENT_IDS.BORDER_OFFICERS]: {
    message: 'Select a border force officer to send the target to',
    summary: 'Select a border force officer to send the target to',
  },
  [COMPONENT_IDS.REFERRAL_REASON]: {
    message: 'Provide a reason for referral',
    summary: 'Provide a reason for referral',
  },
  [COMPONENT_IDS.TARGET_CATEGORY]: {
    message: 'Select a target category',
    summary: 'Select a target category',
  },
  [COMPONENT_IDS.OTHER_TYPES]: {
    message: 'Enter the suspected abuse type',
    summary: 'Enter the suspected abuse type',
  },
  [COMPONENT_IDS.OTHER_TYPES_IDP]: {
    message: 'Enter the referral reason',
    summary: 'Enter the referral reason',
  },
  [COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY]: {
    message: 'Enter a public interest immunity',
    summary: 'Public interest immunity must be selected',
  },
  [COMPONENT_IDS.WATCHLIST]: {
    message: 'Indicate whether all passengers have been added to the watchlist',
    summary: 'Indicate whether all passengers have been added to the watchlist',
  },
  [COMPONENT_IDS.CREW]: {
    message: 'Select a crew check for frontline to complete',
    summary: 'Select a crew check for frontline to complete',
  },
  [COMPONENT_IDS.CREW_OTHER]: {
    message: 'Enter a crew check for frontline to complete',
    summary: 'Enter a crew check for frontline to complete',
  },
  [COMPONENT_IDS.CUSTOMS]: {
    message: 'Select a custom check for frontline to complete',
    summary: 'Select a custom check for frontline to complete',
  },
  [COMPONENT_IDS.CUSTOMS_OTHER]: {
    message: 'Enter a custom check for frontline to complete',
    summary: 'Enter a custom check for frontline to complete',
  },
  [COMPONENT_IDS.IMMIGRATION]: {
    message: 'Select an immigration check for frontline to complete',
    summary: 'Select an immigration check for frontline to complete',
  },
  [COMPONENT_IDS.IMMIGRATION_OTHER]: {
    message: 'Enter an immigration check for frontline to complete',
    summary: 'Enter an immigration check for frontline to complete',
  },
  [COMPONENT_IDS.INTELLIGENCE]: {
    message: 'Select an intelligence check for frontline to complete',
    summary: 'Select an intelligence check for frontline to complete',
  },
  [COMPONENT_IDS.INTELLIGENCE_OTHER]: {
    message: 'Enter an intelligence gathering check for frontline to complete',
    summary: 'Enter an intelligence gathering check for frontline to complete',
  },
  [COMPONENT_IDS.ADDITIONAL]: {
    message: 'Enter other information that might be useful',
    summary: 'Enter other information that might be useful',
  },
};
