import { FormGroup, Heading, VisuallyHidden } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

// Config(s)
import { COMPONENT_IDS, CREDIBILITY_FORM_PREFIX, SYSTEM_CHECKS } from './constants';
import { DATE_FORMATS, STRINGS } from '../../../../../../../utils/constants';

// Context(s)
import { useFormData } from '../../../../../../../context/FormDataContext';
import { useTask } from '../../../../../../../context/TaskContext';
import { useTasks } from '../../../../../../../context/TasksContext';
import { useChecksValidation } from '../../../../../../../context/ChecksValidationContext';

// Component(s)
import AdditionalChecksModal from './AdditionalChecksModal';
import AddOtherChecks from './AddOtherChecks';
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import CredibilityCheckModal from './CredibilityCheckModal';
import OtherChecksSummary from './OtherChecksSummary';
import PncCheckSummary from './PncCheckSummary';

// Util(s)
import { toGridItemClassname } from '../../../../../../../components/Utils/Component/toClassName';
import { isGaSubMode } from '../../../../../../../utils/Task/taskUtil';
import PersonUtil from '../../../../../../../utils/Person/Uplift/personUtil';

// Styling
import './CredibilityCheck.scss';
import { DateTimeUtil } from '../../../../../../../utils';

const toChecksContainerId = (passengerPosition) => {
  return `passenger-${passengerPosition}-credibility-check`;
};

const CredibilityCheck = ({ passenger, passengerPosition, setIsAddPncCheckResultsOpen, setPassengerPosition, isEditable }) => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showAdditionalChecksModal, setShowAdditionalChecksModal] = useState(false);
  const { formData, setFormData } = useFormData();
  const { taskState: { isClaimedByUser, isTargetIssued }, isAddDetail, subMode } = useTask();
  const { setReturnToElementId, setReturnToElement } = useTasks();
  const { errors } = useChecksValidation();
  const adjustedPassengerPositionedAt = passengerPosition + 1;
  const containerId = `${CREDIBILITY_FORM_PREFIX}${adjustedPassengerPositionedAt}`;
  const hasPncChecks = passenger?.credibilityChecks?.credibilityChecks?.filter((check) => check.system === 'PNC')?.length > 0;

  const clearPncAndOtherData = () => {
    const cachedFormData = { ...formData };

    const isFieldNameId = (fieldName) => fieldName === 'id';

    const clearCollectionData = (data) => {
      Object.keys(data)
        .forEach((fieldName) => {
          if (!['id', 'dataId'].includes(fieldName)) {
            delete data[fieldName];
          }
        });
    };

    const clearEntityData = (data, key) => {
      Object.keys(data)
        .forEach((fieldName) => {
          if (!isFieldNameId(fieldName)) {
            delete cachedFormData[containerId].otherChecks[key][fieldName];
          }
        });
    };

    const clearPncData = () => {
      Object.keys(cachedFormData[containerId].pnc)
        .forEach((fieldName) => {
          if (!isFieldNameId(fieldName)) {
            delete cachedFormData[containerId].pnc[fieldName];
          }
        });
    };

    const clearOtherChecksData = () => {
      Object.keys(cachedFormData[containerId].otherChecks)
        .filter((key) => key !== 'checks')
        .forEach((key) => {
          if (key === SYSTEM_CHECKS.OTHER) {
            const checkData = cachedFormData[containerId].otherChecks[key];
            checkData.forEach(clearCollectionData);
            return;
          }

          if (key === COMPONENT_IDS.NOT_REQUIRED) {
            delete cachedFormData[containerId].otherChecks[key];
            return;
          }

          const checkData = cachedFormData[containerId].otherChecks[key];
          clearEntityData(checkData, key);
        });
    };

    clearPncData();
    clearOtherChecksData();
    setFormData(cachedFormData);
  };

  const handlePncChange = (event, passengerPositionedAt) => {
    const { target } = event;
    setReturnToElementId(target.id);
    setIsAddPncCheckResultsOpen(true);
    setPassengerPosition(passengerPositionedAt);
  };

  const setModalVisible = (value) => {
    if (value) {
      setShowWarningModal(value);
      return;
    }
    setShowWarningModal(value);
  };

  const updateFormData = (checks, otherSystems = null) => {
    setFormData((prev) => {
      return {
        ...prev,
        [containerId]: {
          ...prev?.[containerId],
          otherChecks: {
            ...prev?.[containerId].otherChecks,
            checks,
            [SYSTEM_CHECKS.OTHER]: [
              ...(otherSystems || prev?.[containerId].otherChecks[SYSTEM_CHECKS.OTHER] || []),
            ],
          },
        },
      };
    });
  };

  const onAdditionalChecksModalProceedAndDiscard = () => {
    const filteredChecks = formData[containerId]?.otherChecks?.checks?.filter((check) => check !== STRINGS.FORM.OTHER);
    updateFormData(filteredChecks, []);
    setShowAdditionalChecksModal(false);
    setReturnToElement(true);
  };

  const onAdditionalChecksModalCancel = () => {
    updateFormData([
      ...formData[containerId]?.otherChecks?.checks || [],
      STRINGS.FORM.OTHER,
    ]);
    setShowAdditionalChecksModal(false);
    setReturnToElementId(null);
  };

  const onWarningModalProceedAndDiscard = () => {
    const filteredChecks = formData[containerId]?.otherChecks?.checks?.filter((check) => check === STRINGS.FORM.NOT_REQUIRED);
    clearPncAndOtherData();
    updateFormData(filteredChecks);
    setModalVisible(false);
    setReturnToElement(true);
  };

  const onWarningModalCancel = () => {
    const filteredChecks = formData[containerId]?.otherChecks?.checks?.filter((check) => check !== STRINGS.FORM.NOT_REQUIRED);
    updateFormData(filteredChecks);
    setModalVisible(false);
    setReturnToElementId(null);
  };

  const hasAnyChecks = () => {
    return !!(formData?.[containerId]?.pnc || formData?.[containerId]?.otherChecks);
  };

  const hasOtherChecks = () => {
    return !!formData?.[containerId]?.otherChecks;
  };

  const isOtherChecksSummaryVisible = () => {
    return !isAddDetail && (!isClaimedByUser || isTargetIssued) && hasOtherChecks();
  };

  const memoizedModal = useMemo(() => {
    if (showWarningModal) {
      return (
        <CredibilityCheckModal
          onProceedAndDiscard={onWarningModalProceedAndDiscard}
          onCancel={onWarningModalCancel}
        />
      );
    }

    if (showAdditionalChecksModal) {
      return (
        <AdditionalChecksModal
          onProceedAndDiscard={onAdditionalChecksModalProceedAndDiscard}
          onCancel={onAdditionalChecksModalCancel}
        />
      );
    }

    return null;
  }, [showWarningModal, showAdditionalChecksModal]);

  return (
    <div className={`credibility-checks-grid-item-${toGridItemClassname(passengerPosition)}`}>
      <FormGroup
        key={`passenger-${adjustedPassengerPositionedAt}`}
        id={toChecksContainerId(adjustedPassengerPositionedAt)}
        label={<></>}
        error={errors?.[containerId]?.[toChecksContainerId(adjustedPassengerPositionedAt)]}
      >
        <VisuallyHidden id={toChecksContainerId(adjustedPassengerPositionedAt)} aria-hidden />
        <Heading id={`credibility-checks-passenger-${adjustedPassengerPositionedAt}-header`} size="m">
          {isGaSubMode(subMode) ? passenger.roleLabel : `Passenger ${adjustedPassengerPositionedAt}`}
        </Heading>
        <p id={`credibility-checks-passenger-${adjustedPassengerPositionedAt}-name`}>
          {PersonUtil.formattedName(PersonUtil.firstname(passenger))(PersonUtil.lastname(passenger))} {DateTimeUtil.format(PersonUtil.dob(passenger), DATE_FORMATS.SHORT)}
        </p>
        <ComponentWrapper show={hasPncChecks}>
          <ComponentWrapper show={!hasAnyChecks() && !isEditable}>
            <Heading id={`credibility-checks-passenger-${adjustedPassengerPositionedAt}-no-checks`} size="s">
              No checks completed
            </Heading>
          </ComponentWrapper>
          <ComponentWrapper show={hasPncChecks}>
            <PncCheckSummary
              passengerPositionedAt={adjustedPassengerPositionedAt}
              formData={formData}
              onPncChange={handlePncChange}
              containerId={containerId}
              isEditable={isEditable}
            />
          </ComponentWrapper>
        </ComponentWrapper>
        <ComponentWrapper show={isOtherChecksSummaryVisible()}>
          <OtherChecksSummary
            passenger={passenger}
            passengerPositionedAt={adjustedPassengerPositionedAt}
          />
        </ComponentWrapper>
        <ComponentWrapper show={isEditable}>
          <AddOtherChecks
            passenger={passenger}
            passengerPositionedAt={adjustedPassengerPositionedAt}
            setModalVisible={setModalVisible}
            setAdditionalModalVisible={setShowAdditionalChecksModal}
            containerId={containerId}
          />
        </ComponentWrapper>
        <ComponentWrapper show={memoizedModal}>
          {memoizedModal}
        </ComponentWrapper>
      </FormGroup>
    </div>
  );
};

CredibilityCheck.propTyps = {
  passenger: PropTypes.shape({}).isRequired,
  passengerPosition: PropTypes.number.isRequired,
  setIsAddPncCheckResultsOpen: PropTypes.func.isRequired,
  setPassengerPosition: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default CredibilityCheck;

export { toChecksContainerId };
