// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Component(s)
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import DepartureStatus from '../../../../../../components/DepartureStatus/DepartureStatus';
import TaskJourneyDetail from './TaskJourneyDetail';

// Utils
import CommonUtil from '../../../../../../utils/Common/Uplift/commonUtil';
import FlightUtil from '../../../../../../utils/Flight/Uplift/flightUtil';
import PersonUtil from '../../../../../../utils/Person/Uplift/personUtil';
import RisksUtil from '../../../../../../utils/Risks/Uplift/risksUtil';
import MovementUtil from '../../../../../../utils/Movement/Uplift/movementUtil';
import { getTaskId } from '../../../../../../utils/Task/taskUtil';

// Styling
import '../../__assets__/TaskDetail.scss';

const TaskDetail = ({ task, width }) => {
  const taskId = getTaskId(task);
  const person = PersonUtil.get(task);
  const flight = FlightUtil.get(task);
  const departureStatus = FlightUtil.departureStatus(flight);
  const flightNumber = FlightUtil.flightNumber(flight);
  const formattedRisks = RisksUtil.formatHighestThreat(task);
  const groupSize = MovementUtil.groupSize(task);
  const fullName = PersonUtil.formattedName(PersonUtil.firstname(person))((PersonUtil.lastname(person)));
  const iso2NationalityCode = PersonUtil.nationality(person);
  const iso3NationalityCode = CommonUtil.iso3Code(iso2NationalityCode);

  return (
    <div className="detail-container__outer">
      <div className="detail-container__inner">
        <div className="task-detail">
          <div className="flight-details" id={`flight-details-${taskId}`}>
            <ComponentWrapper show={flightNumber}>
              <p id={`flight-number-${taskId}`} className="ho-body">
                {flightNumber}
              </p>
            </ComponentWrapper>
          </div>
          <div className="journey-detail">
            <TaskJourneyDetail task={task} width={width} />
          </div>
          <div className="passenger-count-detail">
            <ComponentWrapper show={groupSize}>
              <p id={`matched-passengers-${taskId}`} className="ho-body">
                1 of {groupSize} pax
              </p>
            </ComponentWrapper>
          </div>
        </div>
      </div>
      <div className="detail-container__rules">
        <hr className="detail-line" />
        <div className="rule-detail">
          <div className="matched-rules">
            <ComponentWrapper show={formattedRisks}>
              <p id={`matched-rule-name-${taskId}`} className="ho-heading-s govuk-!-font-weight-bold">
                {formattedRisks}
              </p>
            </ComponentWrapper>
            <p id={`matched-rule-task-id-${taskId}`} className="ho-secondary-text-xs">
              TID: {taskId}
            </p>
          </div>
          <div className="passenger-details">
            <ComponentWrapper show={fullName}>
              <p id={`passenger-name-${taskId}`} className="ho-body">
                {fullName}
              </p>
            </ComponentWrapper>
            <ComponentWrapper show={iso3NationalityCode}>
              <p id={`passenger-nationality-${taskId}`} className="ho-secondary-text-xs">
                {iso3NationalityCode}
              </p>
            </ComponentWrapper>
          </div>
          <div>
            <DepartureStatus id={`passenger-departure-status-${taskId}`} departureStatus={departureStatus} />
          </div>
        </div>
      </div>
    </div>
  );
};

TaskDetail.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  width: PropTypes.number,
};

TaskDetail.defaultProps = {
  width: 0,
};

export default memo(TaskDetail);
