import React from 'react';

// Components
import RenderForm from '../../../../components/RenderForm/RenderForm';
import Layout from '../../../../components/Layout/Layout';

// Contexts
import { usePnrAccess } from '../../../../context/PnrAccessContext';

// Hook
import useSetTabTitle, { TAB_TITLES } from '../../../../utils/Hooks/useSetTabTitle';

// JSON
import viewPnrData from '../../../../forms/viewPnrData';

const RESPONSE = {
  YES: 'YES',
  NO: 'NO',
};

const LoginJustification = ({ onFinish }) => {
  const { requestPnrAccess } = usePnrAccess();

  useSetTabTitle(TAB_TITLES.DO_YOU_WANT_TO_VIEW_PNR);

  return (
    <Layout>
      <div className="govuk-width-container pnr-access-form-container">
        <main className="govuk-main-wrapper govuk-main-wrapper--auto-spacing" role="main">
          <div className="govuk-grid-row">
            <div className="govuk-grid-column-full">
              <RenderForm
                onSubmit={
                  async ({ data }) => {
                    const { YES, NO } = RESPONSE;
                    const payload = {
                      requested: data?.viewPnrData?.toUpperCase() === YES || data?.viewPnrData?.toUpperCase() === NO,
                      secureLocation: data?.approvedSite?.toUpperCase() === YES || false,
                    };
                    await requestPnrAccess(payload);
                  }
                }
                onFinish={onFinish}
                form={viewPnrData}
              />
            </div>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default LoginJustification;
