import { Checkboxes, FormGroup, Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// Config
import { COMPONENT_IDS, SYSTEM_CHECKS, SYSTEM_CHECKS_TO_COMPONENT_IDS } from './constants';
import { STRINGS } from '../../../../../../../utils/constants';
import { SYSTEM_CHECKS_LABELS } from '../../../../../../Target/TargetsListPage/constants';

// Context(s)
import { useChecksValidation } from '../../../../../../../context/ChecksValidationContext';
import { useFormData } from '../../../../../../../context/FormDataContext';
import { useTasks } from '../../../../../../../context/TasksContext';

// Components
import AdditionalChecks from './form/components/AdditionalChecks';
import Comment from './form/components/Comment';
import Trace from './form/components/Trace';

// Util(s)
import CredibilityChecksUtil from '../../../../../../../utils/Person/credibilityChecksUtil';

const AddOtherChecks = ({ passenger, passengerPositionedAt, setModalVisible, setAdditionalModalVisible, containerId }) => {
  const otherChecksRef = useRef(null);
  const selectedChecks = useRef([]); // Keeps track of selected options that are to be operated on at a later point of execution
  const { formData, setFormData } = useFormData();
  const { setReturnToElementId } = useTasks();
  const { errors } = useChecksValidation();

  const onChange = (event) => {
    const { target } = event;
    const cleanedName = target.name.split('/')[0];

    setFormData((prev) => {
      return {
        ...prev,
        [containerId]: {
          ...prev?.[containerId],
          otherChecks: {
            ...prev?.[containerId]?.otherChecks,
            [cleanedName]: target.value,
          },
        },
      };
    });
  };

  const toNotRequired = () => {
    return {
      value: 'NOT_REQUIRED',
      label: 'Credibility checks not necessary',
      children: (
        <Comment
          id={COMPONENT_IDS.NOT_REQUIRED}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          label="Provide a reason"
          containerId={containerId}
          formData={formData}
          required
          error={errors?.[containerId]?.[`${COMPONENT_IDS.NOT_REQUIRED}/${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toTrace = (system) => {
    const componentId = SYSTEM_CHECKS_TO_COMPONENT_IDS[system];
    return {
      value: system,
      label: SYSTEM_CHECKS_LABELS[system],
      children: (
        <Trace
          id={componentId}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          required
          system={system}
          error={errors?.[containerId]?.[`${componentId}/${passengerPositionedAt}`]}
          commentError={errors?.[containerId]?.[`${componentId}Comment/${passengerPositionedAt}`]}
        />
      ),
    };
  };

  const toAdditionalChecks = () => {
    return {
      value: SYSTEM_CHECKS.OTHER,
      label: 'Additional credibility check/s',
      children: (
        <AdditionalChecks
          id={COMPONENT_IDS.OTHER}
          uniqueId={passengerPositionedAt}
          onChange={onChange}
          containerId={containerId}
          formData={formData}
          errors={errors?.[containerId]}
        />
      ),
    };
  };

  const toCheckOption = (system) => {
    if (system === SYSTEM_CHECKS.OTHER) {
      return toAdditionalChecks();
    }

    return toTrace(system);
  };

  const toCheckOptions = () => {
    const checks = CredibilityChecksUtil.getChecksWithoutPnc(passenger);
    const dedupedSystems = [...new Set(checks.map(({ system }) => system))];

    return dedupedSystems.map(toCheckOption).filter((option) => !!option);
  };

  const setupWarningModal = () => {
    setReturnToElementId(otherChecksRef.current.id);
    setModalVisible(true);
  };

  const isAdditionalChecksUnselectedAndContainsMoreThanOneEntry = (checks) => {
    // Checks if additional checks has been selected
    if (checks?.includes(STRINGS.FORM.OTHER) && !selectedChecks.current.includes(STRINGS.FORM.OTHER)) {
      // Add and then do nothing
      selectedChecks.current = [
        ...selectedChecks.current,
        ...checks,
      ];
      return false;
    }

    // Checks if additional checks has been unselected
    if (!checks?.includes(STRINGS.FORM.OTHER) && selectedChecks.current.includes(STRINGS.FORM.OTHER)) {
      selectedChecks.current = [
        ...selectedChecks.current.filter((check) => check !== STRINGS.FORM.OTHER),
      ];

      const otherSystemsEntries = formData?.[containerId]?.otherChecks?.[SYSTEM_CHECKS.OTHER];
      return otherSystemsEntries?.length > 1;
    }

    return false;
  };

  const hasChecksAndChecksNotRequired = (checks) => {
    return checks?.length && checks?.length > 1 && checks?.includes(STRINGS.FORM.NOT_REQUIRED);
  };

  const hasPncOutcomeAndChecksNotRequired = (checks, pncOutcome) => {
    return pncOutcome && checks?.length && checks?.includes(STRINGS.FORM.NOT_REQUIRED);
  };

  const handleOnChecksChanged = () => {
    const checks = formData?.[containerId]?.otherChecks?.checks || [];
    const pncOutcome = formData?.[containerId]?.pnc?.pncOutcome;

    // See logic in AddPncCheckResult which unselects the checks not necessary checkbox
    if (hasPncOutcomeAndChecksNotRequired(checks, pncOutcome)) {
      setupWarningModal();
      return;
    }

    if (hasChecksAndChecksNotRequired(checks)) {
      // Handle when first selection is the option that discards others.
      if (checks[0] === STRINGS.FORM.NOT_REQUIRED) {
        const formDataCopy = { ...formData };
        formDataCopy[containerId].otherChecks.checks = checks.filter((check) => check !== STRINGS.FORM.NOT_REQUIRED);
        setFormData((prev) => ({ ...prev, ...formDataCopy }));
      } else {
        setupWarningModal();
        return;
      }
    }

    const isAdditionalChecksUnselectedWithMoreThanOneEntry = isAdditionalChecksUnselectedAndContainsMoreThanOneEntry(checks);
    if (isAdditionalChecksUnselectedWithMoreThanOneEntry && !checks?.includes(STRINGS.FORM.NOT_REQUIRED)) {
      setReturnToElementId(otherChecksRef.current.id);
      setAdditionalModalVisible(true);
    }
  };

  useEffect(() => {
    handleOnChecksChanged();
  }, [formData]);

  return (
    <div
      ref={otherChecksRef}
      id={`passenger-${passengerPositionedAt}-other-checks`}
    >
      <Heading size="s">Other Checks</Heading>
      <FormGroup
        id={`otherChecks/${passengerPositionedAt}`}
        label={<></>}
      >
        <Checkboxes
          id={`${COMPONENT_IDS.CHECKS}/${passengerPositionedAt}`}
          fieldId={`${COMPONENT_IDS.CHECKS}/${passengerPositionedAt}`}
          options={[
            ...toCheckOptions(),
            'or',
            toNotRequired(),
          ].filter((item) => item)}
          onChange={onChange}
          value={formData?.[containerId]?.otherChecks?.[COMPONENT_IDS.CHECKS]}
        />
      </FormGroup>
    </div>
  );
};

AddOtherChecks.propTypes = {
  passenger: PropTypes.shape({}).isRequired,
  passengerPositionedAt: PropTypes.number.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  setAdditionalModalVisible: PropTypes.func.isRequired,
  containerId: PropTypes.string.isRequired,
};

export default AddOtherChecks;
