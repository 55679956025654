/**
 * Adds previously selected option to the target component id based on the previous selected value.
 *
 * @param {Object} form - The form JSON to manipulate.
 * @param {Array} haystack - a collection of options.
 * @param {string} targetComponentId - The component to alter.
 * @param {Array} componentOptions - The component options.
 * @param {string | null} previouslySelectedRecipient - The name value of the previously selected option.
 * @returns {Object} - A modified form JSON.
 */
const addPreviousSelection = (form, haystack, targetComponentId, componentOptions, previouslySelectedRecipient = null, key = 'customName') => {
  const previousSelection = haystack.find((recipient) => recipient[key] === previouslySelectedRecipient);

  const _form = {
    ...form,
  };
  _form.pages = _form.pages.map((page) => {
    return {
      ...page,
      components: page.components.map((component) => {
        if (component.id !== targetComponentId) {
          return component;
        }

        return {
          ...component,
          data: {
            options: [previousSelection, ...componentOptions].filter((item) => !!item),
          },
        };
      }),
    };
  });
  return _form;
};

export default addPreviousSelection;
