import React, { useEffect, useState } from 'react';

// Contexts
import { usePermission } from '../../../context/PermissionContext';
import { usePnrAccess } from '../../../context/PnrAccessContext';

// Components
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import Layout from '../../../components/Layout/Layout';

const TargetPnrAccess = ({ children }) => {
  const { hasFrontLinePnrAccess } = usePermission();
  const { userAccess, fetchUserAccess, requestPnrAccess } = usePnrAccess();
  const [loading, setLoading] = useState(hasFrontLinePnrAccess);

  useEffect(() => {
    fetchUserAccess().catch(() => setLoading(false));
  }, [fetchUserAccess]);

  useEffect(() => {
    if (!userAccess) {
      return () => {};
    }

    if (hasFrontLinePnrAccess && !userAccess.submitted) {
      requestPnrAccess({
        requested: true,
        secureLocation: true,
      });
    }

    if (hasFrontLinePnrAccess && userAccess.submitted) {
      setLoading(false);
    }
  }, [hasFrontLinePnrAccess, userAccess]);

  if (loading) {
    return (
      <Layout>
        <LoadingSpinner />
      </Layout>
    );
  }

  return children;
};

export default TargetPnrAccess;
