// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

import './OneItemListRow.scss';

const OneItemListRow = ({ extraText, id, label, link, singleLabel, type, value }) => {
  if (value < 1 || value === null) {
    return null;
  }

  return (
    <p
      className={classNames(
        'one-item-list__row',
        `one-item-list__row_${type}`,
      )}
      id={`${id}-list__row`}
      key={label}
    >
      <a
        aria-label={label}
        href={link}
        id={`${id}-value`}
      >
        {`${value} ${parseInt(value, 10) < 2 && singleLabel ? singleLabel.toLowerCase() : label.toLowerCase()} ${id === 'co-travellers' && extraText !== null ? extraText : ''}`}
      </a>
    </p>
  );
};

OneItemListRow.propTypes = {
  extraText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  singleLabel: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

OneItemListRow.defaultProps = {
  extraText: null,
  link: null,
  singleLabel: null,
  type: 'horizontal',
  value: null,
};

export default memo(OneItemListRow);
