import { useLayoutEffect, useState } from 'react';

/**
 * Determines the client size.
 *
 * @param {*} elRef The root element we want to measure.
*/
const useClientRect = (elRef) => {
  const [rect, setRect] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    const updateSize = () => {
      const { width, height } = elRef?.current.getBoundingClientRect();
      setRect({ width, height });
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return { rect };
};

export default useClientRect;
