// Global import(s)
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Config(s)
import { TARGETER_OUTCOME, TASK_STATUS } from '../../../../utils/constants';
import QUERY_KEYS from '../../../../utils/Hooks/constants';

// Context(s)
import { FormDataProvider } from '../../../../context/FormDataContext';
import { usePermission } from '../../../../context/PermissionContext';
import { useKeycloak } from '../../../../context/Keycloak';
import { useTabs } from '../../../../context/TabContext';
import { useTask } from '../../../../context/TaskContext';
import { useView } from '../../../../context/ViewContext';
import { ChecksValidationProvider } from '../../../../context/ChecksValidationContext';

// Component(s)
import InternalTaskDetailsPage from './InternalTaskDetailsPage';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

// Services
import AxiosRequests from '../../../../api/axiosRequests';

// Hook(s)
import useFetchBorderEvent from '../../../../utils/Hooks/useFetchBorderEvent';
import useFetchJustifications from '../../../../utils/Hooks/useFetchJustifications';
import useFetchTargetSheet from '../../../../utils/Hooks/useFetchTargetSheet';
import useFetchTask from '../../../../utils/Hooks/useFetchTask';

// Utils(s)
import CommonUtil from '../../../../utils/Common/commonUtil';
import JustificationUtil from '../../../../utils/Justification/JustificationUtil';

// Styling
import './TaskDetailsPage.scss';

const TaskDetailsPage = () => {
  const { taskId } = useParams();
  const { trackPageView } = useMatomo();
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const [justificationId, setJustificationId] = useState(null);
  const queryClient = useQueryClient();
  const userEmail = keycloak.tokenParsed.email;
  const { canReadTask, hasRole } = usePermission();
  const { isSettingView, view } = useView();
  const { DEFAULTS } = useTabs();
  const { task,
    target,
    setTaskId,
    setSubMode,
    setTaskState } = useTask();

  const { isLoading: isLoadingTask } = useFetchTask(taskId, justificationId);
  const { justifications, isLoading: isLoadingJustifications } = useFetchJustifications(task?.maskingEnabled);
  const allMovementIds = task?.movement?.allIds;
  const { targetSheet } = useFetchTargetSheet(
    task?.targetId,
    [
      [TASK_STATUS.ISSUED, TASK_STATUS.COMPLETE].includes(task?.status) && CommonUtil.requiresTargetSheet(task?.targeterAction),
      task?.status === TASK_STATUS.RECEIVED,
      task?.status === TASK_STATUS.COMPLETE && task?.withdrawn,
    ].some((condition) => !!condition),
    target,
  );
  const { borderEvent } = useFetchBorderEvent(task?.targetId, CommonUtil.eabRaised(task));
  let authorisedMode = view;

  const isWithdrawnByTargeter = () => {
    return task.status === TASK_STATUS.COMPLETE && task.withdrawn;
  };

  const isDismissedByTargeter = () => {
    return task.status === TASK_STATUS.COMPLETE
      && [TARGETER_OUTCOME.DISMISSED, TARGETER_OUTCOME.ASSESSMENT_COMPLETED].includes(task.targeterAction?.outcome);
  };

  const isCompletedByFrontline = () => {
    return task.status === TASK_STATUS.COMPLETE && !isDismissedByTargeter() && !isWithdrawnByTargeter();
  };

  const isTargetIssued = () => {
    return (task.status === TASK_STATUS.ISSUED || task.targeterAction?.outcome === TARGETER_OUTCOME.TARGET_ISSUED) && task.status !== TASK_STATUS.COMPLETE;
  };

  const isClaimedByUser = () => {
    return userEmail === task?.claimedBy?.email;
  };

  const goToJustificationUI = () => {
    window.location.replace(JustificationUtil.createJustificationURL(window.location.href));
  };

  const setupSubMode = () => {
    setSubMode(task?.mode);
  };

  useEffect(() => {
    const invalidateQueries = async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASK, justificationId] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.JUSTIFICATIONS] }),
      ]);
    };

    if (!justificationId) {
      return;
    }

    if (justificationId === 'CREATE_JUSTIFICATION') {
      goToJustificationUI();
      return;
    }

    invalidateQueries();
  }, [justificationId]);

  useEffect(() => {
    trackPageView();
    setTaskId(taskId);
    return () => AxiosRequests.cancel(source);
  }, []);

  useEffect(() => {
    if (task) {
      if (authorisedMode === undefined) {
        authorisedMode = task?.mode;
      }
      setupSubMode();
      setTaskState((prev) => ({
        ...prev,
        isClaimedByUser: isClaimedByUser(),
        isTargetIssued: isTargetIssued(),
        isWithdrawnByTargeter: isWithdrawnByTargeter(),
        isDismissedByTargeter: isDismissedByTargeter(),
        isCompletedByFrontline: isCompletedByFrontline(),
      }));
    }
  }, [task]);

  if (isLoadingTask || isLoadingJustifications || isSettingView || !authorisedMode) {
    return <LoadingSpinner />;
  }

  if (!canReadTask || !hasRole(DEFAULTS?.[authorisedMode]?.rbac?.readRole)) {
    return <p>You are not authorised to view this task.</p>;
  }

  return (
    <ChecksValidationProvider>
      <FormDataProvider>
        <InternalTaskDetailsPage
          targetSheet={targetSheet}
          hasBorderEvent={CommonUtil.eabRaised(task)}
          borderEvent={borderEvent}
          justifications={justifications}
          setJustificationId={setJustificationId}
          goToJustificationUI={goToJustificationUI}
          movementIds={allMovementIds}
        />
      </FormDataProvider>
    </ChecksValidationProvider>
  );
};

export default TaskDetailsPage;
