import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import axios from 'axios';

// Config
import config from '../../../../../../../utils/config';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../context/TaskContext';
import { useFullScreen } from '../../../../../../../context/FullScreenContext';

// Service(s)
import AxiosRequests from '../../../../../../../api/axiosRequests';

// Components
import OutcomeMessage from '../prompts/OutcomeMessage';
import UpliftedForm from '../forms/UpliftedForm';

// Utils
import { CommonUtil } from '../../../../../../../utils';
import { escapeString } from '../../../../../../../utils/String/stringUtil';
import toAdditionalContentAndCredibilityChecksPayload from '../../../helper/toAdditionalContentAndCredibilityChecksPayload';
import { getCredibilityChecksPayload } from '../../../helper/updateCredibilityChecks';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../../utils/Hooks/useSetTabTitle';

// Form
import form from '../../../../../../../forms/uplift/completeAssessment';

// Styling
import './CompleteAssessment.scss';

const CompleteAssessment = ({ person, onClose, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const source = axios.CancelToken.source();
  const [submission, setSubmission] = useState(null);
  const { taskId, setTask, additionalContent, setAdditionalContent, setEditedAdditionalContent, setDeletedAdditionalContent, credibilityChecks, setCredibilityChecks } = useTask();
  const { setFullScreen, closeFullScreen } = useFullScreen();

  useSetTabTitle(TAB_TITLES.ASSESSMENT_COMPLETE);

  // TODO: Implement based on actual form values
  const toOutcomeCaption = () => {
    switch (submission.completeAssessment) {
      case 'NO_TARGET_REQUIRED': {
        return (
          <>
            <strong>No further action</strong>
            <br />
            <strong>No target required</strong>
          </>
        );
      }
      case 'FALSE_MATCH': {
        return (
          <>
            <strong>No further action</strong>
            <br />
            <strong>False match</strong>
          </>
        );
      }
      case 'OTHER': {
        return (
          <>
            <strong>No further action</strong>
            <br />
            <strong>{submission?.otherNote}</strong>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  const toSubmissionPayload = (data, content, checks) => {
    return {
      update: toAdditionalContentAndCredibilityChecksPayload(content, checks),
      complete: {
        reason: data.completeAssessment,
        otherReasonDetail: data.completeAssessment === 'OTHER' ? data.completeAssessment : null,
        note: escapeString(data?.noTargetRequiredNote),
        taskId,
      },
    };
  };

  const onSubmit = async (data) => {
    await AxiosRequests.completeTask(apiClient, taskId, toSubmissionPayload(data, additionalContent, getCredibilityChecksPayload(credibilityChecks)))
      .then((updatedTask) => {
        setSubmission(data);
        setTask(updatedTask);
        setCredibilityChecks(null);
        setAdditionalContent(null);
        setEditedAdditionalContent(null);
        setDeletedAdditionalContent(null);
      });
  };

  useEffect(() => {
    if (submission) {
      setFullScreen(
        <OutcomeMessage
          title="Assessment complete"
          caption={toOutcomeCaption()}
          onBackToTask={() => onClose()}
          onBackToTaskList={() => navigate(CommonUtil.getListPath(location.pathname))}
          onCloseTabTitle={TAB_TITLES.TASK_DETAILS}
        />,
      );
    }

    return () => {
      closeFullScreen();
      AxiosRequests.cancel(source);
    };
  }, [submission]);

  return (
    <div {...props}>
      <UpliftedForm
        form={form}
        fullScreenOnly
        onSubmit={({ data }) => onSubmit(data)}
        onCancel={onClose}
      />
    </div>
  );
};

CompleteAssessment.propTypes = {
  person: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func,
};

CompleteAssessment.defaultProps = {
  onClose: () => {},
};

export default CompleteAssessment;
