import React from 'react';

import { useKeycloak } from '../../../../../context/Keycloak';

// Services
import AxiosRequests from '../../../../../api/axiosRequests';

// Config
import config from '../../../../../utils/config';

// Components
import RenderForm from '../../../../../components/RenderForm/RenderForm';

// Hook(s)
import { useAxiosInstance } from '../../../../../utils/Axios/axiosInstance';
import useSetTabTitle, { TAB_TITLES } from '../../../../../utils/Hooks/useSetTabTitle';

// Form
import dismissTask from '../../../../../forms/dismissTaskCerberus';

const DismissTask = ({ taskId, isSubmitted, onCancel, setSubmitted, children }) => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);

  useSetTabTitle(TAB_TITLES.DISMISS_TASK);

  if (isSubmitted) {
    return children;
  }

  return (
    <RenderForm
      form={dismissTask}
      preFillData={{ taskId }}
      onSubmit={async ({ data: {
        addANote,
        otherReasonToDismiss,
        reasonForDismissing,
      } }) => {
        await AxiosRequests.dismissTask(apiClient, taskId, {
          reason: reasonForDismissing,
          otherReasonDetail: otherReasonToDismiss,
          note: addANote,
        });
        setSubmitted();
      }}
      onCancel={onCancel}
    />
  );
};

export default DismissTask;
