// Global import(s)
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// Utils
import BookingUtil from '../../../../../../../../utils/Booking/bookingUtil';

// Styling
import './BVI.scss';

const BVI = ({ bookingVsIntent, className }) => {
  if (!bookingVsIntent || bookingVsIntent < 1) {
    return null;
  }

  const toBookingVsIntent = () => {
    return BookingUtil.timeDifferenceAsStringFromMins(bookingVsIntent);
  };

  const bookingClassNames = classNames(className);

  return (
    <div id="booking-verses-intent">
      <span {...bookingClassNames && { className: bookingClassNames }}>BVI:&nbsp;</span>
      <span {...bookingClassNames && { className: bookingClassNames }}>{toBookingVsIntent()}</span>
    </div>
  );
};

BVI.propTypes = {
  bookingVsIntent: PropTypes.number,
  className: PropTypes.string,
};

BVI.defaultProps = {
  bookingVsIntent: 0,
  className: null,
};

export default BVI;
