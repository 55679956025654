// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../../../utils/constants';

// Component(s)
import AirpaxTaskHeader from '../../airpax/TaskHeader';
import GeneralAviationTaskHeader from '../../general_aviation/TaskHeader';

// Util(s)
import MovementUtil from '../../../../../../../utils/Movement/movementUtil';

const ModeTaskHeader = ({ task, width }) => {
  const getHeader = () => {
    const mode = MovementUtil.movementMode(task);
    switch (mode) {
      case MOVEMENT_MODES.AIR_PASSENGER:
        return <AirpaxTaskHeader task={task} width={width} />;
      case MOVEMENT_MODES.GENERAL_AVIATION:
        return <GeneralAviationTaskHeader task={task} />;
      default:
        return null;
    }
  };

  return getHeader();
};

ModeTaskHeader.propTypes = {
  task: PropTypes.shape({}).isRequired,
  width: PropTypes.number.isRequired,
};

export default memo(ModeTaskHeader);
