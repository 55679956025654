import React from 'react';
import lookup from 'country-code-lookup';
import dayjs from 'dayjs';

import { Tag } from '@ukhomeoffice/cop-react-components';
import { DATE_FORMATS, DEPARTURE_STATUS, GENDERS, STRINGS, TARGET_SHEET_WIDTH } from '../constants';

import CommonUtil, { convertToIso3Code } from '../Common/commonUtil';
import { getFormattedDate } from '../Datetime/datetimeUtil';
import { shouldMaskString, shouldMaskDate, getMaskedValue } from '../Masking/maskingUtil';
import { shouldHideString, shouldHideDate, getHiddenValue, shouldHideStringArray, shouldHideNumber } from '../Hiding/hidingUtil';

const toFormattedName = (firstName) => {
  return (lastName, capitalize = false) => {
    const capitalizeFirstLetters = (name) => name
      ?.toLowerCase()
      .split(' ')
      .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
      .join(' ');

    const formattedFirstName = capitalizeFirstLetters(firstName);

    let formattedLastName;
    if (capitalize) {
      formattedLastName = lastName?.toUpperCase();
    } else {
      formattedLastName = capitalizeFirstLetters(lastName);
    }

    return [formattedFirstName, formattedLastName].filter((name) => !!name).join(' ');
  };
};

const toFormattedNameFromPerson = (person, capitalized) => {
  const { first, last } = person?.name || {};
  return toFormattedName(first)(last, capitalized);
};

const getPassengerStatus = (person) => {
  const NOT_AVAILABLE = 'NOT_AVAILABLE';
  if (!person) {
    return NOT_AVAILABLE;
  }
  return person.passengerStatus || NOT_AVAILABLE;
};

const formatGender = (gender) => {
  if (!gender) {
    return STRINGS.UNKNOWN;
  }
  if (shouldMaskString(gender)) {
    return getMaskedValue();
  }
  if (gender.toString().toUpperCase() === GENDERS.MALE.unit.toUpperCase()) {
    return GENDERS.MALE.value;
  }
  if (gender.toString().toUpperCase() === GENDERS.FEMALE.unit.toUpperCase()) {
    return GENDERS.FEMALE.value;
  }
  return STRINGS.UNKNOWN;
};

const getNationality = (person) => {
  if (!person?.nationality) {
    return STRINGS.UNKNOWN;
  }
  if (shouldMaskString(person.nationality)) {
    return getMaskedValue();
  }
  if (shouldHideString(person.nationality)) {
    return null;
  }
  return convertToIso3Code(person.nationality);
};

const getCountryName = (person) => {
  if (!person?.nationality) {
    return STRINGS.UNKNOWN;
  }
  if (shouldMaskString(person.nationality)) {
    return getMaskedValue();
  }
  if (shouldHideString(person.nationality)) {
    return getHiddenValue();
  }
  try {
    return lookup?.byIso(person.nationality)?.country || STRINGS.UNKNOWN;
  } catch {
    return STRINGS.UNKNOWN;
  }
};

const getDateOfBirth = (person, format = DATE_FORMATS.SHORT_ALT) => {
  if (!person?.dateOfBirth) {
    return STRINGS.UNKNOWN;
  }
  if (shouldMaskDate(person.dateOfBirth)) {
    return getMaskedValue();
  }
  if (shouldHideDate(person.dateOfBirth)) {
    return null;
  }
  return getFormattedDate(person.dateOfBirth, format);
};

const getAge = (person) => {
  if (!person?.dateOfBirth) {
    return STRINGS.UNKNOWN;
  }
  if (shouldMaskDate(person.dateOfBirth)) {
    return getMaskedValue();
  }
  if (shouldHideDate(person.dateOfBirth)) {
    return getHiddenValue();
  }
  return dayjs.utc().diff(dayjs(person.dateOfBirth), 'year');
};

const getTravelAge = (person, departureDate) => {
  const dateFormat = 'YYYY-MM-DD';
  const dateOfBirth = getDateOfBirth(person, dateFormat);

  if (!dateOfBirth || dateOfBirth === STRINGS.UNKNOWN) {
    return STRINGS.UNKNOWN;
  }

  if (shouldMaskDate(person.dateOfBirth)) {
    return getMaskedValue();
  }

  if (dateOfBirth === getHiddenValue()) {
    return getHiddenValue();
  }

  if (!departureDate || departureDate === STRINGS.UNKNOWN) {
    return STRINGS.UNKNOWN;
  }

  const formattedDob = dayjs(dayjs(dateOfBirth));
  const formattedDepartureDate = dayjs(dayjs(departureDate).format(dateFormat));
  return formattedDepartureDate.diff(formattedDob, 'year');
};

const getGender = (person) => {
  if (!person) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideString(person.gender)) {
    return null;
  }
  return formatGender(person.gender);
};

const getLastName = (person, capitalize = true) => {
  if (!person?.name?.last) {
    return capitalize ? STRINGS.UNKNOWN.toUpperCase() : STRINGS.UNKNOWN;
  }

  if (shouldHideString(person.name.last)) {
    return null;
  }

  return capitalize ? person.name.last.toUpperCase() : person.name.last;
};

const getFullName = (person) => {
  if (!person?.name?.full) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideString(person.name.full)) {
    return null;
  }
  return person.name.full;
};

const getFirstName = (person) => {
  if (!person?.name?.first) {
    return STRINGS.UNKNOWN;
  }
  if (shouldHideString(person.name.first)) {
    return null;
  }
  return person.name.first;
};

const getFrequentFlyerNumber = (person) => {
  if (!person?.frequentFlyerNumber) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideString(person.frequentFlyerNumber)) {
    return null;
  }

  return person.frequentFlyerNumber;
};

const hasSSRCodes = (person) => {
  return !!person?.ssrCodes?.length;
};

const getRole = (person) => {
  if (shouldHideString(person?.role)) {
    return getHiddenValue();
  }

  return person.role;
};

const getSSRCodes = (person) => {
  if (!hasSSRCodes(person)) {
    return STRINGS.UNKNOWN;
  }

  if (shouldHideStringArray(person.ssrCodes)) {
    return getHiddenValue();
  }

  return person.ssrCodes.join(', ');
};

const toCoTravellers = (otherPersons, hidingEnabled) => {
  if (!otherPersons || !otherPersons?.length) {
    return !hidingEnabled ? <li className="govuk-!-font-weight-bold">{STRINGS.NONE}</li> : null;
  }

  const maxToDisplay = 4;
  const remaining = otherPersons.length > maxToDisplay ? otherPersons.length - maxToDisplay : 0;
  return otherPersons.map((person, index) => {
    if (index > maxToDisplay) {
      return null;
    }

    const formattedName = CommonUtil.commaSeparated(getLastName(person), getFirstName(person));
    if (!formattedName) {
      return null;
    }

    return (
      <li
        key={person?.entityId?.poleV1Id || person?.entityId?.poleV2Id}
        className="govuk-!-font-weight-bold"
      >
        {formattedName}{(index !== maxToDisplay - 1)
      && (index !== otherPersons.length - 1) ? ',' : ''} {(remaining > 0 && index + 1 === maxToDisplay)
        ? ` plus ${remaining} more` : ''}
      </li>
    );
  }).filter((item) => !!item);
};

/**
 *
 * @param person person from target sheet
 * @param pageWidth get from useWindowSize hook
 * @param shortcodeOverride for when developer knows they want the shortcode version
 * @returns {React.JSX.Element|null}
 */
const getMovementStatusTag = (person, pageWidth = TARGET_SHEET_WIDTH, shortcodeOverride = false) => {
  if (!person?.passengerStatus) {
    return null;
  }

  const textType = () => {
    const status = DEPARTURE_STATUS[person?.passengerStatus];
    if ((!!pageWidth && pageWidth < TARGET_SHEET_WIDTH) || shortcodeOverride) {
      return status?.code;
    }
    return status?.description;
  };

  return (
    <Tag
      id="summary-movement-description"
      className="tag tag--blue"
      text={textType()}
    />
  );
};

const getTotalNumberOfPersons = (targetTask) => {
  if (shouldHideNumber(targetTask?.movement?.groupSize)) {
    return getHiddenValue();
  }

  return targetTask?.movement?.groupSize || 0;
};

const getTotalNumberOfOtherPersons = (targetTask) => {
  return targetTask?.movement?.otherPersons?.length || 0;
};

const hasOtherPersons = (targetTask) => {
  const o = targetTask?.movement?.otherPersons;
  return typeof o === 'object' && o !== null;
};

const getOtherPersons = (targetTask) => {
  if (hasOtherPersons(targetTask)) {
    return targetTask.movement.otherPersons;
  }
  return [];
};

const hasPerson = (targetTask) => {
  return !!targetTask?.movement?.person;
};

const getPerson = (targetTask) => {
  if (hasPerson(targetTask)) {
    return targetTask.movement.person;
  }
  return undefined;
};

const getAllPersons = (targetTask) => {
  const allPersons = [];
  const person = getPerson(targetTask);
  const otherPersons = getOtherPersons(targetTask);
  if (person) {
    allPersons.push(person);
  }
  if (otherPersons) {
    allPersons.push(...otherPersons);
  }
  return allPersons;
};

const PersonUtil = {
  age: getAge,
  allPersons: getAllPersons,
  countryName: getCountryName,
  dob: getDateOfBirth,
  firstname: getFirstName,
  formatGender,
  formattedName: toFormattedName,
  formattedNameFromPerson: toFormattedNameFromPerson,
  frequentFlyerNumber: getFrequentFlyerNumber,
  fullname: getFullName,
  gender: getGender,
  get: getPerson,
  getDateOfBirth,
  getTravelAge,
  getOthers: getOtherPersons,
  lastname: getLastName,
  nationality: getNationality,
  movementStatusTag: getMovementStatusTag,
  othersCount: getTotalNumberOfOtherPersons,
  passengerStatus: getPassengerStatus,
  role: getRole,
  ssrCodes: getSSRCodes,
  toOthers: toCoTravellers,
  totalPersons: getTotalNumberOfPersons,
  travelAge: getTravelAge,
};

export default PersonUtil;

export {
  formatGender,
  getAge,
  getCountryName,
  getDateOfBirth,
  getFirstName,
  getFrequentFlyerNumber,
  getFullName,
  getGender,
  getLastName,
  getNationality,
  getMovementStatusTag,
  getOtherPersons,
  getPassengerStatus,
  getPerson,
  getRole,
  getSSRCodes,
  getTotalNumberOfOtherPersons,
  getTotalNumberOfPersons,
  getTravelAge,
  toCoTravellers,
  toFormattedName,
};
