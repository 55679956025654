// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import classNames from 'classnames';
import React from 'react';

// Config(s)
import { DATE_FORMATS } from '../../../../../utils/constants';
import FIELDS from '../../helper/Fields';

// Component(s)
import ComponentWrapper from '../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import asUnknownIfRequired from '../../../../../utils/Transform/valueSanitizer';
import renderBlock, { DEFAULT_HIGHLIGHT_CLASS, renderRow, toField, toPreviousTaskLinkParams, toPreviousTaskLink } from '../../helper/common';
import { PersonUtil, DateTimeUtil, JourneyUtil, TaskVersionUtil, CommonUtil } from '../../../../../utils';
import BookingUtil from '../../../../../utils/Booking/bookingUtil';

const Booking = ({ version, versionDiff }) => {
  const booking = BookingUtil.get(version);
  const bookingDiff = BookingUtil.get(versionDiff);
  const tickets = BookingUtil.tickets(booking);
  const patchedTickets = TaskVersionUtil.patch(tickets, BookingUtil.tickets(bookingDiff, true));
  const journey = JourneyUtil.get(version);
  const journeyDiff = JourneyUtil.get(versionDiff);
  const departureTime = JourneyUtil.departureTime(journey);
  const agent = BookingUtil.agent(booking);
  const patchedVersion = TaskVersionUtil.patch(version, versionDiff);
  const bookingJourneyDiff = { ...bookingDiff, ...journeyDiff?.departure };
  const bookingReference = BookingUtil.bookingRef(booking);
  const bookedAt = BookingUtil.bookedAt(booking);
  const formattedBookedAt = DateTimeUtil.format(bookedAt, DATE_FORMATS.LONG);
  const bookingTimeDifference = BookingUtil.format.bookingTimeDifference(bookedAt, departureTime);
  const checkInAt = BookingUtil.checkInAt(booking);
  const formattedCheckInDate = DateTimeUtil.format(checkInAt, DATE_FORMATS.LONG);
  const checkInTimeDifference = BookingUtil.format.bookingTimeDifference(checkInAt, departureTime);
  const bookingCountryIso2Code = BookingUtil.bookingCountry(booking);
  const bookingCountryIso3Code = CommonUtil.iso3Code(bookingCountryIso2Code);
  const formattedBookingCountryName = BookingUtil.format.countryName(bookingCountryIso2Code);
  const bookingType = BookingUtil.bookingType(booking);
  const formattedTicketNumbers = BookingUtil.format.ticketNumbers(tickets);
  const formattedTicketTypes = BookingUtil.format.ticketTypes(tickets);
  const payments = CommonUtil.uniqueId(BookingUtil.payments(booking));
  const paymentsDiff = BookingUtil.payments(bookingDiff, true);
  const patchedPayments = CommonUtil.uniqueId(TaskVersionUtil.patch(payments, paymentsDiff));
  const agentIata = BookingUtil.agentIataCode(booking);
  const agentLocation = BookingUtil.agentLocation(agent);
  const formattedAgentLocation = BookingUtil.format.agentLocation(agentLocation);
  const contactText = BookingUtil.contactText(booking);

  const toPaymentText = (payment) => {
    const card = BookingUtil.paymentCard(payment);
    const lastFourDigits = asUnknownIfRequired(BookingUtil.cardLastFourDigits(card));
    const expiry = BookingUtil.cardExpiry(card);
    const formattedCardExpiry = asUnknownIfRequired(BookingUtil.format.cardExpiry(expiry));
    if (payment.type !== 'CC') {
      // Default to the payment type
      return payment.type;
    }

    return `Credit card ending ${lastFourDigits}, expiry ${formattedCardExpiry}`;
  };

  const toPaymentsBlock = () => {
    const containsAnyChange = [];

    return (
      <div className="govuk-!-margin-bottom-3">
        <div className="bottom-border-thin">
          <span
            className={classNames('font__light', containsAnyChange.some((v) => v) && DEFAULT_HIGHLIGHT_CLASS)}
          >
            Payments
          </span>
        </div>
        {payments.map((payment, index) => {
          const paymentAmount = asUnknownIfRequired(BookingUtil.paymentAmount(payment));
          containsAnyChange.push(!TaskVersionUtil.isSame(payment, patchedPayments[index]));
          const classname = classNames(
            'bottom-border-thin',
            'govuk-!-margin-top-1',
            'govuk-!-padding-bottom-1',
            !TaskVersionUtil.isSame(payment, patchedPayments[index]) && DEFAULT_HIGHLIGHT_CLASS,
          );
          return (
            <div key={payment.id} className={classname}>
              <div className="font__bold">{paymentAmount}</div>
              <div id={`payment-${payment.id}`} className="font__light">
                {toPaymentText(payment)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="task-details-container">
      <Heading className="govuk-!-margin-top-0" size="m">Booking</Heading>
      <div className="govuk-task-details-grid-column">
        {renderBlock(
          'Reference',
          {
            content: bookingReference,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.reference]),
        )}
        {renderBlock(
          'Number of travellers',
          {
            content: PersonUtil.totalPersons(version),
          },
          !TaskVersionUtil.isSame(PersonUtil.totalPersons(version), PersonUtil.totalPersons(patchedVersion)),
        )}
        {renderBlock(
          toField('Booking date',
            TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.bookedAt, FIELDS.time])),
          [
            toField(formattedBookedAt,
              TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.bookedAt])),
            toField(bookingTimeDifference,
              TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.bookedAt, FIELDS.time])),
          ],
        )}
        {renderBlock(
          toField('Check-in date',
            TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.checkInAt, FIELDS.time])),
          [
            toField(formattedCheckInDate,
              TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.checkInAt])),
            toField(checkInTimeDifference,
              TaskVersionUtil.hasAny(bookingJourneyDiff, [FIELDS.checkInAt, FIELDS.time])),
          ],
        )}
        {renderBlock(
          'Booking country',
          {
            content: `${formattedBookingCountryName} (${bookingCountryIso3Code})`,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.country]),
        )}
        {renderBlock(
          'Booking type',
          {
            content: bookingType,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.type]),
        )}
        {renderBlock(
          'Ticket number',
          {
            content: formattedTicketNumbers,
          },
          !TaskVersionUtil.isSame(BookingUtil.format.ticketNumbers(tickets), BookingUtil.format.ticketNumbers(patchedTickets)),
        )}
        {renderBlock(
          'Ticket type',
          {
            content: formattedTicketTypes,
          },
          !TaskVersionUtil.isSame(BookingUtil.format.ticketTypes(tickets), BookingUtil.format.ticketTypes(patchedTickets)),
        )}
      </div>
      <ComponentWrapper show={payments?.length}>
        {toPaymentsBlock()}
      </ComponentWrapper>
      <ComponentWrapper show={!payments?.length}>
        <div className="bottom-border-thin" />
      </ComponentWrapper>
      <div className="govuk-task-details-grid-column bottom-border-thin">
        {renderBlock(
          'Agent IATA',
          {
            content: agentIata,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.iata]),
          toPreviousTaskLink(toPreviousTaskLinkParams(version, agent)),
        )}
        {renderBlock(
          'Agent location',
          {
            content: formattedAgentLocation,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.location]),
        )}
      </div>
      <div className="govuk-!-padding-top-3">
        {renderRow(
          'Contact text',
          {
            content: contactText,
          },
          TaskVersionUtil.hasAny(bookingDiff, [FIELDS.contactText]),
        )}
      </div>
    </div>
  );
};

export default Booking;
