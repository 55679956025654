// Config(s)
import { COMPONENT_IDS, FRONTLINE_CHECK_OPTION_VALUES } from '../constants';
import {
  DATE_FORMATS,
  STRINGS,
  SUB_MODES,
  TASK_DETAIL_PAGE_UPDATING,
  TASK_DETAIL_PAGE_PHOTO_LAST_ACTION,
} from '../../../../../../../../utils/constants';

// Util
import { DateTimeUtil } from '../../../../../../../../utils';
import { isGaSubMode } from '../../../../../../../../utils/Task/taskUtil';

const toPublicInterestImmunity = (formData) => {
  const value = formData?.[COMPONENT_IDS.PUBLIC_INTEREST_IMMUNITY];

  if (value === undefined || (Array.isArray(value) && !value?.length)) {
    return null;
  }

  return !!value;
};

const toSuspectedAbuseTypes = (formData, subMode) => {
  const abuseTypes = formData[COMPONENT_IDS.ABUSE_TYPES];

  if (!abuseTypes?.length || isGaSubMode(subMode)) {
    return null;
  }

  return abuseTypes.map((abuseType) => {
    if (abuseType === STRINGS.FORM.OTHER) {
      return `${abuseType} - ${formData[COMPONENT_IDS.OTHER_TYPES]}`;
    }
    return abuseType;
  });
};

const toWatchlist = (formData) => {
  const watchlist = formData[COMPONENT_IDS.WATCHLIST];

  if (watchlist === undefined) {
    return false;
  }

  return watchlist === 'true';
};

// TODO: Update the return for general aviation when multiple
// recipients is enabled;
const toRecipients = (formData, subMode) => {
  if (subMode === SUB_MODES.GENERAL_AVIATION) {
    return {
      recipients: {
        type: 'GENERAL_AVIATION',
        group: formData[COMPONENT_IDS.RECIPIENTS][0],
      },
    };
  }

  // #18228 Change this when the BE has the groups shape ready
  // for us;
  if (subMode === SUB_MODES.IDP) {
    return {
      recipients: {
        type: 'AIRPAX_IDP',
        groups: formData[COMPONENT_IDS.RECIPIENTS],
      },
    };
  }
  return {
    recipients: {
      type: 'AIRPAX_COMMODITIES',
      groups: formData[COMPONENT_IDS.RECIPIENTS],
      users: formData[COMPONENT_IDS.BORDER_OFFICERS],
    },
  };
};

const toFrontLineChecks = (formData, subMode) => {
  if (!isGaSubMode(subMode)) {
    return null;
  }

  const cleanOtherFrontLineCheck = (enabledComponent, enabledOption, key) => {
    const enabled = formData?.[enabledComponent]?.includes(enabledOption);
    const check = formData?.[key];

    return (enabled && check && [check]) || [];
  };

  const cleanFrontLineChecks = (enabledOption, key) => {
    const enabled = formData?.[COMPONENT_IDS.FRONTLINE_CHECKS]?.includes(enabledOption);
    const checks = formData?.[key];

    if (!enabled) {
      return { [key]: [] };
    }

    return {
      [key]: [
        ...checks?.filter((check) => check !== 'other') || [],
        ...cleanOtherFrontLineCheck(key, 'other', `${key}OtherDetail`),
      ],
    };
  };

  return {
    ...cleanFrontLineChecks(
      FRONTLINE_CHECK_OPTION_VALUES.IMMIGRATION_CHECKS,
      COMPONENT_IDS.IMMIGRATION,
    ),
    ...cleanFrontLineChecks(
      FRONTLINE_CHECK_OPTION_VALUES.CUSTOMS_CHECKS,
      COMPONENT_IDS.CUSTOMS,
    ),
    ...cleanFrontLineChecks(
      FRONTLINE_CHECK_OPTION_VALUES.INTELLIGENCE_GATHERING_CHECKS,
      COMPONENT_IDS.INTELLIGENCE,
    ),
    ...cleanFrontLineChecks(
      FRONTLINE_CHECK_OPTION_VALUES.CREW_CHECKS,
      COMPONENT_IDS.CREW,
    ),
    additionalChecks: cleanOtherFrontLineCheck(
      COMPONENT_IDS.FRONTLINE_CHECKS,
      FRONTLINE_CHECK_OPTION_VALUES.ADDITIONAL_CHECKS,
      COMPONENT_IDS.ADDITIONAL,
    ),
  };
};

const toPnrElements = (formData) => {
  return {
    selectedPnrElements: formData[COMPONENT_IDS.PNR_ELEMENTS] || null,
  };
};

const toSubmissionPayload = (
  taskId,
  formData,
  additionalContent,
  editedAdditionalContent,
  deletedAdditionalContent,
  credibilityChecks,
  subMode = 'AIRPAX_COMMODITIES',
) => {
  const getEditedAdditionalContent = (content) => {
    let normalizedContent;

    if (Array.isArray(content)) {
      normalizedContent = [...content];
    } else if (content) {
      normalizedContent = [content];
    } else {
      normalizedContent = null;
    }
    return normalizedContent;
  };

  const getDeletedAdditionalContent = (content) => {
    let normalizedContent;

    if (Array.isArray(content)) {
      normalizedContent = content.map((item) => item.id);
    } else if (content) {
      normalizedContent = [content.id];
    } else {
      normalizedContent = null;
    }
    return normalizedContent;
  };

  // #19821
  // Here we need to look at TASK_DETAIL_PAGE_ properties to be sure
  // which action should take precedence. We're going to use the
  // TASK_DETAIL_PAGE_PHOTO_LAST_ACTION as the decider, but also use
  // TASK_DETAIL_PAGE_UPDATING as the main guard.

  if (localStorage.getItem(TASK_DETAIL_PAGE_UPDATING)) {
    let acDelete = getDeletedAdditionalContent(deletedAdditionalContent);
    let acUpdate = getEditedAdditionalContent(editedAdditionalContent);

    if (localStorage.getItem(TASK_DETAIL_PAGE_PHOTO_LAST_ACTION) === 'deleted') {
      // #19821
      // This is because the delete action has taken precedence
      // (the prop) so we must remove edited content since it causes
      // an issue with the submission (you can't delete something
      // you've updated);
      acDelete?.forEach((del) => {
        if (editedAdditionalContent?.id === del) {
          editedAdditionalContent = null;
        }
      });
    }

    if (localStorage.getItem(TASK_DETAIL_PAGE_PHOTO_LAST_ACTION) === 'changed') {
      // #19821
      // This is here because the last action was changing a photo,
      // any deleted that matches ids needs to be removed;
      acUpdate?.forEach((up) => {
        if (deletedAdditionalContent?.id === up) {
          deletedAdditionalContent = null;
        }
      });
    }

    if (localStorage.getItem(TASK_DETAIL_PAGE_PHOTO_LAST_ACTION) === 'added') {
      // #19821
      // This is here because the last action was adding a photo,
      // any deleted that matches ids needs to be removed;
      acDelete?.forEach((del) => {
        if (deletedAdditionalContent?.id === del) {
          deletedAdditionalContent = null;
        }
      });
    }
  }

  return {
    taskId,
    target: {
      ...toRecipients(formData, subMode),
      ...toFrontLineChecks(formData, subMode),
      suspectedAbuseTypes: toSuspectedAbuseTypes(formData, subMode),
      referralReason: formData[COMPONENT_IDS.REFERRAL_REASON],
      category: formData[COMPONENT_IDS.TARGET_CATEGORY],
      operation: formData[COMPONENT_IDS.OPERATION] || null,
      publicInterestImmunity: toPublicInterestImmunity(formData),
      issuingHub: {
        name: '', // TODO: Update this once a new design has been provided for create target.
        telephone: '', // TODO: Update this once a new design has been provided for create target.
      },
      addedToWatchList: toWatchlist(formData),
      ...toPnrElements(formData),
    },
    task: {
      additionalContent: {
        update: getEditedAdditionalContent(editedAdditionalContent),
        delete: {
          additionIds: getDeletedAdditionalContent(deletedAdditionalContent),
          timestamp: DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC),
        },
        add: additionalContent ? [...additionalContent] : null,
      },
      credibilityChecks,
    },
  };
};

export default toSubmissionPayload;
