// Global import(s)
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';

// Component(s)
import SummaryExtraSmall from './SummaryExtraSmall';
import SummaryLarge from './SummaryLarge';
import SummaryMedium from './SummaryMedium';
import SummarySmall from './SummarySmall';

// Util(s)
import FlightUtil from '../../../../../../../../utils/Flight/Uplift/flightUtil';
import JourneyUtil from '../../../../../../../../utils/Journey/Uplift/journeyUtil';
import MovementUtil from '../../../../../../../../utils/Movement/Uplift/movementUtil';
import { BookingUtil } from '../../../../../../../../utils';

// Styling
import './Summary.scss';

const Summary = ({ task }) => {
  const [summary, setSummary] = useState(null);
  const ref = useRef(null);
  const journey = JourneyUtil.get(task);
  const bookingVsIntent = MovementUtil.bookingVsIntent(task);
  const flight = FlightUtil.get(task);
  const booking = BookingUtil.get(task);
  const pnrLocator = BookingUtil.bookingRef(booking);

  const toSummaryLinesComponent = (width) => {
    if (width > 960) {
      return (
        <SummaryLarge
          task={task}
          journey={journey}
          bookingVsIntent={bookingVsIntent}
          flight={flight}
          pnrLocator={pnrLocator}
        />
      );
    }
    if (width > 580) {
      return (
        <SummaryMedium
          task={task}
          journey={journey}
          bookingVsIntent={bookingVsIntent}
          flight={flight}
          pnrLocator={pnrLocator}
        />
      );
    }
    if (width > 460) {
      return (
        <SummarySmall
          task={task}
          journey={journey}
          bookingVsIntent={bookingVsIntent}
          flight={flight}
          pnrLocator={pnrLocator}
        />
      );
    }
    return (
      <SummaryExtraSmall
        task={task}
        journey={journey}
        flight={flight}
        pnrLocator={pnrLocator}
      />
    );
  };

  useLayoutEffect(() => {
    const updateSummary = () => {
      if (ref.current) {
        setTimeout(() => {
          const width = ref.current.offsetWidth;
          setSummary(toSummaryLinesComponent(width));
        }, 0);
      }
    };

    updateSummary();

    window.addEventListener('resize', updateSummary);

    return () => window.removeEventListener('resize', updateSummary);
  }, []);

  return (
    <div id="airpax-task-summary-container" ref={ref}>
      <div id="airpax-task-summary">
        {summary}
      </div>
    </div>
  );
};

Summary.propTypes = {
  task: PropTypes.shape({}),
};

Summary.defaultProps = {
  task: null,
};

export default Summary;
