import { FORM_ACTIONS } from '../../utils/constants';

export const CHANGE_BORDER_OFFICER_ID = 'BORDER_OFFICER';

export default {
  id: 'changeBorderOfficer',
  version: '1.0.0',
  name: 'changeBorderOfficer',
  title: 'Target recipient',
  type: 'form',
  components: [],
  pages: [{
    id: 'changeBorderOfficer',
    name: 'changeBorderOfficer',
    components: [
      {
        type: 'heading',
        size: 'm',
        content: 'Select an alternative border officer',
      },
      {
        id: CHANGE_BORDER_OFFICER_ID,
        fieldId: CHANGE_BORDER_OFFICER_ID,
        label: '',
        'aria-label': 'Select an alternative border officer',
        type: 'autocomplete',
        required: true,
        clearable: true,
        openOnClick: true,
        placeholder: 'Select an alternative border officer',
        data: {
          url: '/cop-targeting-api/v2/users/front-line-officers',
        },
        item: {
          value: 'email',
          label: 'email',
        },
        custom_errors: [
          {
            type: 'required',
            message: 'Select a UK border force officer to send the target to',
          },
        ],
      },
    ],
    actions: [
      {
        type: FORM_ACTIONS.SUBMIT,
        validate: true,
        label: 'Save and close',
      },
      {
        type: FORM_ACTIONS.CANCEL,
        label: 'Cancel',
        classModifiers: 'secondary',
      },
    ],
  }],
};
