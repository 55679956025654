const addDataToComponent = (dataToSearch, fieldIdToLookFor, dataToAppend) => {
  if (dataToSearch.fieldId && dataToSearch.fieldId === fieldIdToLookFor) {
    return { ...dataToSearch,
      data: {
        options: dataToAppend,
      } };
  }

  for (let propertyName in dataToSearch) {
    if (Object.prototype.hasOwnProperty.call(dataToSearch, propertyName) && dataToSearch[propertyName] !== null && typeof dataToSearch[propertyName] === 'object') {
      const newData = addDataToComponent(dataToSearch[propertyName], fieldIdToLookFor, dataToAppend);
      dataToSearch[propertyName] = newData;
    }
  }

  return dataToSearch;
};

export default addDataToComponent;
