// Config(s)
import { SUB_MODES } from '../constants';

const isRoRoSubMode = (subMode) => {
  return subMode === SUB_MODES.RORO;
};

const isCommoditiesSubMode = (subMode) => {
  return subMode === SUB_MODES.COMMODITIES;
};

const isIdpSubMode = (subMode) => {
  return subMode === SUB_MODES.IDP;
};

const isGaSubMode = (subMode) => {
  return subMode === SUB_MODES.GENERAL_AVIATION;
};

const isCtbpSubMode = (subMode) => {
  return subMode === SUB_MODES.CTBP;
};

const getTaskId = (task) => {
  return task?.id || null;
};

const getClaimedByFullName = (task) => {
  const { firstName, lastName } = task?.claimedBy || {};
  return [firstName, lastName]
    .filter((name) => !!name)
    .flatMap((name) => name.split(' '))
    .map((n) => n.charAt(0).toUpperCase() + n.slice(1))
    .join(' ');
};

const TaskUtil = {
  taskId: getTaskId,
  claimedBy: getClaimedByFullName,
};

export default TaskUtil;

export {
  getTaskId,
  isCommoditiesSubMode,
  isCtbpSubMode,
  isGaSubMode,
  isIdpSubMode,
  isRoRoSubMode,
  getClaimedByFullName,
};
