import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { TIME_FORMATS } from '../../../../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Contents from '../../../content/Contents';
import Details from '../../../generics/Details';
import HeaderModule from '../../../generics/HeaderModule';
import LabelValuePair from '../../../generics/LabelValuePair';
import TimeCountDown from '../../../../../../../../../components/Uplift/Countdown/TimeCountDown';
import Itinerary from './Itinerary';
import ValueField from '../../../generics/ValueField';

// Util(s)
import JourneyUtil from '../../../../../../../../../utils/Journey/Uplift/journeyUtil';
import JourneyS4Util from '../../../../../../../../../utils/Journey/journeyS4Util';
import FlightUtil from '../../../../../../../../../utils/Flight/Uplift/flightUtil';
import toEventDate, { toEventTime } from '../../../../../../TaskList/helper/toEventDate';
import { toDepartureArrivalLabel } from '../helper/common';

// Styling
import './Voyage.scss';

const Voyage = ({ flight, journey, s4Journey }) => {
  const direction = JourneyUtil.direction(journey);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const departureTime = JourneyUtil.departureTime(journey);
  const arrivalLocation = JourneyUtil.arrivalLoc(journey);
  const arrivalTime = JourneyUtil.arrivalTime(journey);
  const flightNumber = FlightUtil.flightNumber(flight);
  const itinerary = JourneyUtil.itinerary(journey);
  const additionalContent = JourneyUtil.additionalContent(journey);
  const manifestType = JourneyS4Util.manifestType(s4Journey);
  const manifestArrivalDateTime = JourneyS4Util.manifestArrivalDateTime(s4Journey);
  const operatorName = FlightUtil.operatorName(flight);

  return (
    <div id="voyage-module" className="govuk-grid-column-full">
      <HeaderModule
        id="voyage-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id="voyage-header" size="m">Voyage</Heading>
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper show={departureLocation && arrivalLocation}>
                <div key="2" className="grid-item">
                  <ValueField
                    classNames="govuk-heading-m"
                    value={(
                      <>
                        <span id="departure-location">{departureLocation}</span>&nbsp;&nbsp;
                        <span className="right-arrow">&#8594;</span>&nbsp;&nbsp;
                        <span id="arrival-location">{arrivalLocation}</span>&nbsp;
                      </>
                  )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper show={departureTime && arrivalTime && departureLocation && arrivalLocation}>
                <div key="3" className="grid-item grid-item-span-3">
                  <ValueField value={(
                    <>
                      <span className="govuk-!-font-weight-bold">
                        <TimeCountDown dateTime={toEventDate(journey)} />
                      </span>
                    &nbsp;
                      <span>
                        {toDepartureArrivalLabel(direction)}
                      </span>
                    </>
                )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <ComponentWrapper key="1" show={departureTime && departureLocation}>
                <div className="grid-item">
                  <LabelValuePair
                    id="voyage-departure"
                    label="Departs"
                    value={(
                      <>
                        <span>{toEventTime(departureTime, departureLocation, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                        <span>{toEventTime(departureTime, departureLocation, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                      </>
                  )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={arrivalTime && arrivalLocation}>
                <div className="grid-item">
                  <LabelValuePair
                    id="voyage-arrival"
                    label="Arrives"
                    value={(
                      <>
                        <span>{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                        <span>{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                      </>
                  )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={flightNumber}>
                <div className="grid-item">
                  <LabelValuePair id="voyage-flight-number" label="Flight no." value={flightNumber} />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="4" show={operatorName}>
                <div className="grid-item">
                  <LabelValuePair
                    id="voyage-operator"
                    label="Operator"
                    value={operatorName}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="5" show={manifestType}>
                <div className="grid-item">
                  <LabelValuePair
                    id="voyage-manifest-type"
                    label="Manifest Type"
                    value={manifestType}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="6" show={manifestArrivalDateTime}>
                <div className="grid-item">
                  <LabelValuePair
                    id="voyage-manifest-arrival-date-time"
                    label="Manifest Arrival Date Time"
                    value={(
                      <>
                        <span>{toEventTime(manifestArrivalDateTime, arrivalLocation, TIME_FORMATS.VOYAGE.DAY_SHORT)}</span>
                        <span>{toEventTime(manifestArrivalDateTime, arrivalLocation, TIME_FORMATS.VOYAGE.TIME_SHORT)}</span>
                      </>
                  )}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Contents id="voyage-contents" contents={additionalContent} />
      <Itinerary itinerary={itinerary} />
    </div>
  );
};

Voyage.propTypes = {
  flight: PropTypes.shape({}).isRequired,
  journey: PropTypes.shape({}).isRequired,
  s4Journey: PropTypes.shape({}),
};

Voyage.defaultProps = {
  s4Journey: null,
};

export default Voyage;
