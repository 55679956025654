// Config(s)
import { CONTENT_TYPE, GROUP_MEMBER, TARGET_PAGE_ACCESS_CONTROL, VIEW } from '../../constants';

// Util(s)
import { toUniqueById } from '../commonUtil';
import EntityUtil from '../../Entity/entityUtil';

const getAdditionalPhotoContent = (entity) => {
  const additions = [...new Set(entity?.additionalContent)];
  return additions.find((addition) => addition.type === CONTENT_TYPE.PHOTO) || null;
};

const getAdditionalTextContent = (entity) => {
  const contents = entity?.additionalContent?.filter((addition) => addition.type === CONTENT_TYPE.TEXT) || [];
  return toUniqueById(contents);
};

const getAdditionalPhotoContents = (entity) => {
  const additions = [...new Set(entity?.additionalContents)];
  return additions.find((addition) => addition.type === CONTENT_TYPE.PHOTO) || null;
};

const getAdditionalTextContents = (entity) => {
  const contents = entity?.additionalContents?.filter((addition) => addition.type === CONTENT_TYPE.TEXT) || [];
  return toUniqueById(contents);
};

const getAdditionalTextContentsFromContext = (entities) => {
  return entities?.filter((entity) => entity.content.type === CONTENT_TYPE.TEXT)
    .map((item) => ({
      ...item.content,
      timestamp: item.timestamp,
    }));
};

const getAdditionalContents = (entity, additionalContent, type, matchEntityId = false) => {
  if (matchEntityId) {
    return [
      ...(getAdditionalTextContents(entity) || []),
      ...(additionalContent?.some((item) => item.type === type)
        ? getAdditionalTextContentsFromContext(additionalContent.filter((item) => item.type === type && EntityUtil.isMatchingEntity(entity.entityId, item.entityId)))
        : [])];
  }
  return [
    ...(getAdditionalTextContents(entity) || []),
    ...(additionalContent?.some((item) => item.type === type)
      ? getAdditionalTextContentsFromContext(additionalContent.filter((item) => item.type === type))
      : [])];
};

const isRCCUAirpaxView = (view) => {
  return view === VIEW.AIRPAX_RCCU;
};

const isHigherOfficerAirpaxView = (view) => {
  return view === VIEW.AIRPAX_HO;
};

const isInterceptingOfficerAirpaxView = (view) => {
  return view === VIEW.AIRPAX_IO;
};

const isViewTargetAirpax = (view) => {
  return [VIEW.AIRPAX_IO, VIEW.AIRPAX_HO, VIEW.AIRPAX_RCCU].includes(view);
};

const isViewTargetRoro = (view) => {
  return [VIEW.RORO_IO].includes(view);
};

const isSeniorOfficersAirpaxView = (view) => {
  return isRCCUAirpaxView(view) || isHigherOfficerAirpaxView(view);
};

const isAllowedToViewTargetPage = (groupMember, path, isInterceptingOfficer = false) => {
  return !!TARGET_PAGE_ACCESS_CONTROL[groupMember]?.includes(path) || isInterceptingOfficer;
};

const getTargetListAuthorisationCheck = (canRead, userGroupMember, location, userRole, isInterceptingOfficer) => {
  return !canRead
    || userGroupMember !== GROUP_MEMBER.NONE
    || isAllowedToViewTargetPage(userGroupMember, location?.pathname)
    || !userRole
    || isInterceptingOfficer;
};

const TargetCommonUtil = {
  additionalContents: getAdditionalContents,
  additionalPhotoContent: getAdditionalPhotoContent,
  additionalTextContent: getAdditionalTextContent,
  additionalPhotoContents: getAdditionalPhotoContents,
  additionalTextContents: getAdditionalTextContents,
  additionalTextContentsFromContext: getAdditionalTextContentsFromContext,
  canViewTargetPage: isAllowedToViewTargetPage,
  isViewTargetAirpax,
  isViewTargetRoro,
  isRCCUAirpaxView,
  isSeniorOfficersAirpaxView,
  isHigherOfficerAirpaxView,
  isInterceptingOfficerAirpaxView,
  targetListAuthorisationCheck: getTargetListAuthorisationCheck,
};

export default TargetCommonUtil;

export {
  isAllowedToViewTargetPage,
  getAdditionalContents,
  getAdditionalPhotoContent,
  getAdditionalTextContent,
  getAdditionalTextContentsFromContext,
  TARGET_PAGE_ACCESS_CONTROL,
};
