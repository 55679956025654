import { MAX_MOBILE_PHONE_WIDTH } from '../constants';

const isMobilePortrait = (width) => {
  return width < MAX_MOBILE_PHONE_WIDTH;
};

const TargetSheetUtil = {
  isMobilePortrait,
  isPortrait: isMobilePortrait,
};

export default TargetSheetUtil;

export {
  isMobilePortrait,
};
