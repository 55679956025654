import React, { useMemo, useState, createContext, useContext } from 'react';

// Contexts
import { useNotification } from './NotificationContext';

const TasksContext = createContext({});

const TasksProvider = ({ children }) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const [returnToElementId, setReturnToElementId] = useState(null);
  const [returnToElement, setReturnToElement] = useState(false);
  const [selectedForDismissal, setSelectedForDismissal] = useState([]);
  const [selectedForDelete, setSelectedForDelete] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [rules, setRules] = useState(null);
  const [taskIds, setTaskIds] = useState([]);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const [taskIdsToRemove, setTaskIdsToRemove] = useState([]);
  const [fetchSingleTask, setFetchSingleTask] = useState(false);
  const { setNotification } = useNotification();
  const [taskFilterCounts, setTaskFilterCounts] = useState([]);

  const onSelection = ({ target }) => {
    const { id } = target;

    // Remove from array
    if (selectedForDismissal.includes(id)) {
      setSelectedForDismissal((prev) => [...prev.filter((item) => item !== id)]);
      return;
    }

    // Add to array
    setSelectedForDismissal((prev) => [...prev, id]);
  };

  const onDeleteSelection = ({ target }) => {
    const { id } = target;

    // Remove from array
    if (selectedForDelete.includes(id)) {
      setSelectedForDelete((prev) => [...prev.filter((item) => item !== id)]);
      return;
    }

    // Add to array
    setSelectedForDelete((prev) => [...prev, id]);
  };

  const removeSelection = (taskId) => {
    setSelectedForDismissal((prev) => [...prev.filter((item) => item !== taskId)]);
  };

  const removeDeleteSelection = (taskId) => {
    setSelectedForDelete((prev) => [...prev.filter((item) => item !== taskId)]);
  };

  /**
   * Helper function to filter out unwanted tasks from a collection of tasks.
   *
   * @param {string | array} _taskIds A id or an array of ids to remove
   */
  const removeTasksByIds = (_taskIds) => {
    const ids = Array.isArray(_taskIds) ? _taskIds : [_taskIds];
    setTaskIdsToRemove((prev) => [...prev, ...ids]);
    setTimeout(() => {
      setTasks((prev) => prev.filter((task) => !ids?.includes(task.id)));
    }, 500);
    setTimeout(() => {
      setTaskIdsToRemove([]);
      setNotification(null);
    }, 1500);
    setTimeout(() => {
      setFetchSingleTask(!fetchSingleTask);
    }, 2000);
  };

  const value = useMemo(() => ({
    removeTasksByIds,
    selectedForDismissal,
    setSelectedForDismissal,
    selectedForDelete,
    setSelectedForDelete,
    onSelection,
    onDeleteSelection,
    removeSelection,
    removeDeleteSelection,
    isSelecting,
    setIsSelecting,
    returnToElementId,
    setReturnToElementId,
    returnToElement,
    setReturnToElement,
    tasks,
    setTasks,
    taskIds,
    setTaskIds,
    taskIdsToRemove,
    rules,
    setRules,
    fetchSingleTask,
    setFetchSingleTask,
    isLoadingTasks,
    setIsLoadingTasks,
    taskFilterCounts,
    setTaskFilterCounts,
  }), [
    isLoadingTasks,
    setIsLoadingTasks,
    fetchSingleTask,
    taskIdsToRemove,
    tasks,
    taskIds,
    rules,
    selectedForDismissal,
    selectedForDelete,
    isSelecting,
    returnToElementId,
    returnToElement,
    taskFilterCounts,
    setTaskFilterCounts,
  ]);

  return <TasksContext.Provider value={value}>{children}</TasksContext.Provider>;
};

const useTasks = () => useContext(TasksContext);

export { TasksContext, TasksProvider, useTasks };
