import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import AxiosRequests from '../../api/axiosRequests';

// Contexts
import { useKeycloak } from '../../context/Keycloak';

// Configs
import config from '../config';
import { GROUP_MEMBER, ROLE } from '../constants';
import QUERY_KEYS from './constants';

// Utils
import { useAxiosInstance } from '../Axios/axiosInstance';

const setupUserGroupMember = (data) => {
  if ((data?.airPaxFrontLineGroups?.length || data?.rccuGroups?.length) && data?.roRoFrontLineGroups?.length) {
    return GROUP_MEMBER.BOTH;
  }
  if (data?.airPaxFrontLineGroups?.length || data?.rccuGroups?.length) {
    return GROUP_MEMBER.AIRPAX;
  }
  if (data?.roRoFrontLineGroups?.length) {
    return GROUP_MEMBER.RORO;
  }
  return GROUP_MEMBER.NONE;
};

const setupUserRole = (data) => {
  if (data?.rccuOfficer) {
    return ROLE.RCCU;
  }

  if (data?.higherOfficer) {
    return ROLE.HIGHER_OFFICER;
  }

  return ROLE.INTERCEPTING_OFFICER;
};

const useFetchUser = () => {
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const source = axios.CancelToken.source();

  useEffect(() => {
    return () => AxiosRequests.cancel(source);
  }, []);

  const getUserDetails = async () => {
    return AxiosRequests.userDetails(apiClient);
  };

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: () => getUserDetails(),
    initialData: null,
  });

  const userGroups = {
    airPaxFrontLineGroups: data?.airPaxFrontLineGroups || [],
    roRoFrontLineGroups: data?.roRoFrontLineGroups || [],
    rccuGroups: data?.rccuGroups || [],
  };
  const userGroupMember = setupUserGroupMember(data);
  const userRole = setupUserRole(data);
  const isRCCUOfficer = !!data?.rccuOfficer;
  const isHigherOfficer = !!data?.higherOfficer;
  const isInterceptingOfficer = !!data?.interceptingOfficer;

  return {
    isLoading,
    userGroupMember,
    userRole,
    userGroups,
    isRCCUOfficer,
    isHigherOfficer,
    isInterceptingOfficer,
  };
};

export default useFetchUser;
