// Global import(s)
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// Config(s)
import { VIEW } from '../../utils/constants';
import { FILTER_TYPES } from '../../routes/Task/TaskList/constants';

// Context(s)
import { useTabs } from '../../context/TabContext';
import { useView } from '../../context/ViewContext';

// Component(s)
import InternalFilter from './InternalFilter';

// Hook(s)
import useFilters from './hooks/Filter.hooks';

// Util(s)
import FormUtils from '../../utils/Form/ReactForm';

// JSON
import copFilter from '../../forms/copFilter';
import tasksFilter from '../../forms/tasksFilter';

// Styling
import './Filter.scss';

const Filter = forwardRef((props, ref) => {
  const { currentUser, data, filterCounts, groupCounts, customOptions, groups, onApply, onClear } = props;
  const { selectedTab } = useTabs();
  const { view } = useView();
  const { filtersData } = useFilters(data, filterCounts);

  if (!view) {
    return null;
  }

  if ([VIEW.AIRPAX_RCCU, VIEW.AIRPAX_HO, VIEW.AIRPAX_IO, VIEW.RORO_IO].includes(view)) {
    return (
      <InternalFilter
        form={copFilter(groups, view)}
        data={data}
        groupCounts={groupCounts}
        customOptions={customOptions}
        ref={ref}
        onApply={onApply}
        onClear={onClear}
      />
    );
  }

  if ([VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.IDP, VIEW.CTBP, VIEW.COMMODITIES, VIEW.OSDT].includes(view)) {
    return (
      <InternalFilter
        form={tasksFilter(currentUser, FormUtils.showAssigneeComponent(selectedTab), view)}
        data={filtersData}
        filterCounts={{
          selectorCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.SELECTORS),
          directionCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.JOURNEY_DIRECTIONS),
          passengerStatusCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.PASSENGER_STATUSES),
          targetingTeamCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.TARGETING_TEAMS),
          ruleCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.RULES),
        }}
        customOptions={{
          rules: customOptions?.rules,
          targetingTeams: customOptions?.targetingTeams,
        }}
        ref={ref}
        onApply={onApply}
        onClear={onClear}
      />
    );
  }

  if ([VIEW.GENERAL_AVIATION].includes(view)) {
    return (
      <InternalFilter
        form={tasksFilter(currentUser, FormUtils.showAssigneeComponent(selectedTab), VIEW.GENERAL_AVIATION)}
        data={filtersData}
        filterCounts={{
          directionCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.JOURNEY_DIRECTIONS),
        }}
        customOptions={{
          rules: customOptions?.rules,
          targetingTeams: customOptions?.targetingTeams,
        }}
        ref={ref}
        onApply={onApply}
        onClear={onClear}
      />
    );
  }

  return (
    <InternalFilter
      form={tasksFilter(currentUser, FormUtils.showAssigneeComponent(selectedTab), VIEW.RORO)}
      data={filtersData}
      filterCounts={{
        movementModeCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.MODE),
        selectorCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.SELECTORS),
        directionCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.JOURNEY_DIRECTIONS),
        ruleCounts: filterCounts?.filter((item) => item.counterType === FILTER_TYPES.RULES),
      }}
      customOptions={{
        rules: customOptions?.rules,
      }}
      ref={ref}
      onApply={onApply}
      onClear={onClear}
    />
  );
});

Filter.propTypes = {
  currentUser: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  filterCounts: PropTypes.arrayOf(PropTypes.shape({})),
  groupCounts: PropTypes.arrayOf(PropTypes.shape({})),
  groups: PropTypes.shape({}),
  customOptions: PropTypes.shape({}),
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  data: PropTypes.shape({}),
  filterCounts: [],
  groupCounts: [],
  customOptions: PropTypes.shape({}),
  groups: [],
};

export default Filter;
