// Global import(s)
import { withCount } from 'pluralise';

// Config(s)
import { STRINGS } from '../constants';

// Util(s)
import { capitalizeFirstLetter } from '../String/stringUtil';

const getIndicatorsTotalScore = (entity) => {
  if (!entity) {
    return 0;
  }
  return entity?.score || 0;
};

const getIndicatorsTotalCount = (targetingIndicators) => {
  if (!targetingIndicators) {
    return 0;
  }
  return targetingIndicators?.count || 0;
};

const getIndicatorDescription = (targetingIndicator) => {
  if (!targetingIndicator) {
    return STRINGS.UNKNOWN;
  }
  return targetingIndicator?.description || STRINGS.UNKNOWN;
};

const getTargetingIndicators = (risks) => {
  return risks?.targetingIndicators || null;
};

const getRisk = (targetTask) => {
  return targetTask?.risks || null;
};

const getSelectorWarning = (selector) => {
  let warning;
  let warningDetails;
  const warningStatus = selector.warning.status;
  if (warningStatus?.toLowerCase() === STRINGS.NO.toLowerCase()) {
    warning = 'No warnings';
  }
  if (warningStatus?.toLowerCase() === STRINGS.CURRENTLY_UNAVAILABLE_TEXT.toLowerCase()) {
    warning = 'Warnings currently unavailable';
  }
  if (warningStatus?.toLowerCase() === STRINGS.YES.toLowerCase()) {
    const warningTypes = selector.warning.types;
    if (warningTypes.length > 0) {
      warning = warningTypes.map((w) => (w === 'O'
        ? warningDetails?.substring(0, 500) : capitalizeFirstLetter(w.toLowerCase().replace(/_/g, ' '))))
        .join(', ');
    }
  }
  return warning;
};

const getSelectorGroups = (version) => {
  return version?.risks?.matchedSelectorGroups || null;
};

const getMatchedRules = (version) => {
  return version?.risks?.matchedRules || null;
};

const getHighestThreatLevel = (risks) => {
  return risks?.highestThreatLevel || null;
};

const getRiskMatchedSelectorGroups = (risks) => {
  return risks?.matchedSelectorGroups || null;
};

const getRiskMatchedRules = (risks) => {
  return risks?.matchedRules || [];
};

const extractRiskType = (risks, highestRisk) => {
  let riskType;
  if (highestRisk?.type?.toLowerCase() === 'selector') {
    const selectorGroups = getRiskMatchedSelectorGroups(risks).groups;
    selectorGroups?.forEach(({ category, selectors }) => {
      if (category?.toLowerCase() === highestRisk?.value?.toLowerCase()) {
        riskType = selectors[0].localReference;
      }
    });
  } else {
    const rules = getRiskMatchedRules(risks);
    rules?.forEach(({ priority, name }) => {
      if (priority?.toLowerCase() === highestRisk?.value?.toLowerCase()) {
        riskType = name;
      }
    });
  }
  return riskType || STRINGS.UNKNOWN;
};

const toFormattedTargetRisk = (risks, highestThreatLevel) => {
  const risksRules = getRiskMatchedRules(risks)?.length + getRiskMatchedSelectorGroups(risks)?.totalNumberOfSelectors;
  if (highestThreatLevel) {
    const topRisk = extractRiskType(risks, highestThreatLevel);
    const count = risksRules > 0 && risksRules - 1;
    return `${topRisk} and ${withCount(count, '% other rule', '% other rules')}`;
  }
  return null;
};

const formatHighestThreatLevel = (targetTask) => {
  const risks = getRisk(targetTask);
  const highestThreatLevel = getHighestThreatLevel(risks);
  const threatLevel = highestThreatLevel?.type?.toLowerCase() === 'rule' ? '' : highestThreatLevel?.type;
  const tagValue = highestThreatLevel?.value;
  const formattedRisks = toFormattedTargetRisk(risks, highestThreatLevel);

  return {
    highestThreatLevel: threatLevel,
    tagValue,
    formattedRisks,
  };
};

const getSelector = (informationSheet) => {
  return informationSheet?.risks?.selector || null;
};

const getSelectors = (informationSheet) => {
  if (!informationSheet?.risks?.selectors || !informationSheet?.risks?.selectors?.length) {
    return [];
  }
  return informationSheet.risks.selectors;
};

const getIndicatorMatches = (selector) => {
  return selector?.indicatorMatches || null;
};

const getIndicatorMatchIndicators = (selector) => {
  if (!selector?.indicators || !selector?.indicators?.length) {
    return [];
  }

  return selector.indicators;
};

const RisksUtil = {
  extractHighestRisk: extractRiskType,
  formatHighestThreat: formatHighestThreatLevel,
  formatTargetRisk: toFormattedTargetRisk,
  getGroups: getSelectorGroups,
  getHighestThreat: getHighestThreatLevel,
  matchedRules: getRiskMatchedRules,
  getMatchedSelectorGroups: getRiskMatchedSelectorGroups,
  getMatches: getIndicatorMatches,
  getRisks: getRisk,
  getRules: getMatchedRules,
  getWarning: getSelectorWarning,
  indicatorCount: getIndicatorsTotalCount,
  indicatorDescription: getIndicatorDescription,
  indicatorMatches: getIndicatorMatchIndicators,
  indicatorScore: getIndicatorsTotalScore,
  indicators: getIndicatorMatchIndicators,
  selector: getSelector,
  selectors: getSelectors,
  targetingIndicators: getTargetingIndicators,
};

export default RisksUtil;

export {
  extractRiskType,
  formatHighestThreatLevel,
  toFormattedTargetRisk,
  getHighestThreatLevel,
  getIndicatorDescription,
  getIndicatorMatchIndicators,
  getIndicatorsTotalCount,
  getIndicatorsTotalScore,
  getMatchedRules,
  getRisk,
  getRiskMatchedRules,
  getRiskMatchedSelectorGroups,
  getSelector,
  getSelectors,
  getSelectorGroups,
  getSelectorWarning,
  getTargetingIndicators,
};
