// Global import(s)
import { Heading } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useWindowSize } from 'react-use';

// Component(s)
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import SummaryListRow from '../generics/SummaryListRow';

// Context
import { useTask } from '../../../../../../context/TaskContext';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../../utils/constants';

// Util(s)
import CredibilityChecksUtil from '../../../../../../utils/Person/credibilityChecksUtil';
import { toFormattedOutcome, toFormattedPncType, toFormattedValue, toRecordsFound } from '../../../../../Task/TaskDetails/components/shared/helper/adaptPncContent';
import { isMobilePortrait } from '../../../../../../utils/TargetSheet/targetSheetUtil';

const PncCheck = ({ person, personPosition, mode, preview }) => {
  const pnc = CredibilityChecksUtil.getPnc(person);
  const outcome = CredibilityChecksUtil.outcome(pnc);
  const { credibilityChecks } = useTask();
  const { width } = useWindowSize();
  const pncc = person?.policeNationalComputerCheck;

  if (!outcome && !preview) {
    return null;
  }

  const staffSafetyLabel = () => {
    if (mode === MOVEMENT_MODES.GENERAL_AVIATION) {
      return 'Some concerns for the safety of frontline staff';
    }
    return 'Concerns for safety of Frontline staff?';
  };

  const toWarningTypes = (obj) => {
    const EXCLUDE = ['STAFF_CONCERN', 'POI_CONCERN'];
    const ourCCDetails = obj ? obj?.pncDetails : pncc?.details;
    return ourCCDetails?.types?.filter((item) => !EXCLUDE.includes(item) && !!item) || [];
  };

  const toContent = () => {
    if (preview) {
      let idx = personPosition === 0 ? 0 : personPosition;
      let cc = credibilityChecks[`passenger${idx + 1}`];
      return (
        <div className="govuk-summary-list">
          <ComponentWrapper show={mode !== MOVEMENT_MODES.GENERAL_AVIATION}>
            <SummaryListRow
              id={`person-${personPosition}-pnc-id`}
              label="PNC ID"
              value={cc?.pnc?.pncFound?.pncId}
            />
          </ComponentWrapper>
          <SummaryListRow
            id={`person-${personPosition}-safety-warnings`}
            label="Safety Warnings:"
            value={toWarningTypes(cc?.pnc).map((el) => toFormattedPncType(el)).join(', ') || 'None'}
          />
          <SummaryListRow
            id={`person-${personPosition}-staff-safety`}
            label={staffSafetyLabel()}
            value={cc?.pnc?.pncDetails?.staffConcerns}
          />
          <SummaryListRow
            id={`person-${personPosition}-poi-safety`}
            label="Potentially involved in:"
            value={cc?.pnc?.pncDetails?.poiConcerns}
          />
        </div>
      );
    }

    if (!CredibilityChecksUtil.isPotentialPnc(pnc)) {
      return (
        <p id="pnc-outcome" className="govuk-body govuk-!-margin-bottom-0">{toFormattedOutcome(outcome)}</p>
      );
    }

    return (
      <div className="govuk-summary-list">
        <ComponentWrapper show={mode !== MOVEMENT_MODES.GENERAL_AVIATION}>
          <SummaryListRow
            id={`person-${personPosition}-pnc-id`}
            label="PNC ID"
            mobile={isMobilePortrait(width)}
            type="horizontal"
            value={toFormattedValue(CredibilityChecksUtil.pncId(pnc))}
          />
        </ComponentWrapper>
        <SummaryListRow
          id={`person-${personPosition}-safety-warnings`}
          label="Safety Warnings:"
          mobile={isMobilePortrait(width)}
          type="vertical-small"
          value={toWarningTypes().map((el) => toFormattedPncType(el)).join(', ') || 'None'}
        />
        <SummaryListRow
          id={`person-${personPosition}-staff-safety`}
          label={staffSafetyLabel()}
          mobile={isMobilePortrait(width)}
          type="vertical-small"
          value={toFormattedValue(CredibilityChecksUtil.staffConcerns(pnc))}
        />
        <SummaryListRow
          id={`person-${personPosition}-poi-safety`}
          label="Potentially involved in:"
          mobile={isMobilePortrait(width)}
          type="vertical-small"
          value={toRecordsFound(pnc?.result?.categories)}
        />
      </div>
    );
  };

  return (
    <div className="pnc-check" id={`person-${personPosition}-pnc-check`}>
      <Heading className="govuk-!-padding-top-3" size="s">
        PNC check
      </Heading>
      {toContent()}
    </div>
  );
};

PncCheck.propTypes = {
  person: PropTypes.shape({}).isRequired,
  personPosition: PropTypes.number.isRequired,
  preview: PropTypes.bool,
};

PncCheck.defaultProps = {
  preview: false,
};

export default memo(PncCheck);
