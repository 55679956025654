// Global import(s)
import { Heading, Link } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config
import { TIME_FORMATS } from '../../../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Contents from '../../content/Contents';
import Details from '../../generics/Details';
import EntitySearchModal from '../../prompts/EntitySearchModal';
import LabelValuePair from '../../generics/LabelValuePair';
import SubHeaderModule from '../../generics/SubHeaderModule';
import ValueField from '../../generics/ValueField';

// Util(s)
import CommonUtil from '../../../../../../../../utils/Common/Uplift/commonUtil';
import DocumentUtil from '../../../../../../../../utils/Document/Uplift/documentUtil';
import DateTimeUtil from '../../../../../../../../utils/Datetime/Uplift/datetimeUtil';
import JourneyUtil from '../../../../../../../../utils/Journey/Uplift/journeyUtil';
import { getMaskedValue } from '../../../../../../../../utils/Masking/maskingUtil';
import { isDocumentNumberMasked, toValidTo, toValidToDifference } from './helper/common';

const Document = ({ document, journey, movementId, movementIds, positionedAt }) => {
  const docNumber = DocumentUtil.number(document);
  const docType = DocumentUtil.type(document);
  const docTypeDetail = DocumentUtil.otherTypeDetail(document);
  const formattedDocType = DocumentUtil.format.type(docType);
  const { otherMovementsUrl, movementCount } = CommonUtil.otherMovementsLinkParams(document);
  const otherMovementsLabel = CommonUtil.otherMovementsLabel(movementCount);
  const otherTasksListURL = CommonUtil.otherTasksListURL(CommonUtil.otherTasksLinkParams(movementId, document, movementIds));
  const otherTasksURLLabel = CommonUtil.otherTasksListLabel(document);
  const iso2CountryOfIssueCode = DocumentUtil.countryOfIssue(document);
  const formattedCountryOfIssue = DocumentUtil.format.countryOfIssue(iso2CountryOfIssueCode);
  const iso3CountryOfIssueCode = CommonUtil.iso3Code(iso2CountryOfIssueCode);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const departureTime = JourneyUtil.departureTime(journey);
  const dateOfExpiry = DocumentUtil.expiry(document);
  const additionalContent = DocumentUtil.additionalContent(document);
  const sipURL = CommonUtil.sipEntitySearchURL(document);
  const centaurURL = CommonUtil.centaurEntitySearchURL(document);

  const toIssuedDate = () => {
    if (!document?.dateOfIssue) {
      return null;
    }

    if (document?.dateOfIssue === getMaskedValue()) {
      return document?.dateOfIssue;
    }

    return DateTimeUtil.format.asUTC(document?.dateOfIssue, TIME_FORMATS.UTC, TIME_FORMATS.DOCUMENT);
  };

  if (!document) {
    return null;
  }

  return (
    <div id={`document-${positionedAt}-module`}>
      <SubHeaderModule
        id={`document-${positionedAt}-details`}
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id={`document-${positionedAt}-header`} size="s">Document ID</Heading>
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={docNumber}>
                <div className="grid-item">
                  <ComponentWrapper show={!sipURL && !centaurURL}>
                    <ValueField id={`document-${positionedAt}-number`} value={docNumber} />
                  </ComponentWrapper>
                  <ComponentWrapper show={sipURL || centaurURL}>
                    {!isDocumentNumberMasked(docNumber) ? (
                      <EntitySearchModal
                        id={`document-${positionedAt}-number`}
                        modalHeading={`Search document ID ${docNumber} on:`}
                        label={docNumber}
                        sipURL={sipURL}
                        centaurURL={centaurURL}
                      />
                    ) : <ValueField id={`document-${positionedAt}-number`} value={docNumber} />}
                  </ComponentWrapper>
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={docType && formattedDocType}>
                <div className="grid-item">
                  <ValueField id={`document-${positionedAt}-type`} value={formattedDocType} />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="4" show={otherMovementsUrl && otherMovementsLabel}>
                <div className="grid-item">
                  <Link id={`document-${positionedAt}-movement-link`} href={otherMovementsUrl} target="_blank" rel="noreferrer noopener">
                    {otherMovementsLabel}
                  </Link>
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="5" show={otherTasksListURL && otherTasksURLLabel}>
                <div className="grid-item">
                  <Link id={`document-${positionedAt}-other-tasks-link`} href={otherTasksListURL} target="_blank" rel="noreferrer noopener">
                    {otherTasksURLLabel}
                  </Link>
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <ComponentWrapper key="1" show={iso2CountryOfIssueCode && formattedCountryOfIssue}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`document-${positionedAt}-country-of-issue`}
                    label="Issued by"
                    value={`${formattedCountryOfIssue} - ${iso3CountryOfIssueCode}`}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={document?.dateOfIssue}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`document-${positionedAt}-date-of-issue`}
                    label="Issue date"
                    value={toIssuedDate()}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={dateOfExpiry}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`document-${positionedAt}-validity`}
                    label="Valid to"
                    value={toValidTo(dateOfExpiry)}
                    secondaryValue={toValidToDifference(dateOfExpiry, departureLocation, departureTime)}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="4" show={docType === 'OTHER' && docTypeDetail}>
                <div className="grid-item">
                  <LabelValuePair id={`document-${positionedAt}-type-nature`} label="Nature of document type" value={docTypeDetail} />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Contents id="person-document-contents" contents={additionalContent} />
    </div>
  );
};

Document.propTypes = {
  movementId: PropTypes.string.isRequired,
  movementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  document: PropTypes.shape({}),
  journey: PropTypes.shape({}).isRequired,
  positionedAt: PropTypes.number.isRequired,
};

Document.defaultProps = {
  document: null,
};

export default Document;
