/* eslint-disable no-template-curly-in-string */
export default {
  note: 'IMPORTANT: This form has been composed. Make changes to the relevant components, not this file, or your changes may get lost.',
  id: 'cerberus-airpax-target-information-sheet',
  version: '1.8.0',
  name: 'cerberus-airpax-target-information-sheet',
  title: 'Target Information Sheet (AirPax)',
  type: 'hub-and-spoke',
  components: [
    {
      id: 'category',
      fieldId: 'category',
      label: 'Target category',
      'aria-label': 'target category',
      type: 'autocomplete',
      required: true,
      clearable: true,
      openOnClick: true,
      className: 'width-one-third hods-multi-select-autocomplete',
      placeholder: 'Select the target category',
      data: {
        options: [
          {
            value: 'A',
            label: 'A',
          },
          {
            value: 'B',
            label: 'B',
          },
          {
            value: 'C',
            label: 'C',
          },
          {
            value: 'U',
            label: 'U',
          },
        ],
      },
      custom_errors: [
        {
          type: 'required',
          message: 'You must select a Target category',
        },
      ],
    },
    {
      id: 'controlStrategies',
      fieldId: 'controlStrategies',
      label: 'Threat type/ National Control Strategy',
      'aria-label': 'threat type/ national control strategy',
      role: 'complementary',
      type: 'autocomplete',
      placeholder: '',
      required: true,
      openOnClick: true,
      closeOnSelect: false,
      multi: true,
      item: {
        value: 'id',
        label: 'strategy',
        priority: 'priority',
        format: '${label} / Control Strategy ${priority}',
      },
      data: {
        url: '${environmentContext.referenceDataUrl}/v2/entities/controlstrategy?sort=strategy.asc&mode=dataOnly&validDateTime=true',
      },
      custom_errors: [
        {
          type: 'required',
          message: 'You must select a Threat Type/ National Control Strategy',
        },
      ],
    },
    {
      id: 'nominalChecks',
      fieldId: 'nominalChecks',
      label: 'Checks completed on nominals',
      type: 'collection',
      labels: {
        item: 'Nominal ${index} details',
        add: 'Add another nominal',
      },
      item: [
        {
          id: 'nominalType',
          fieldId: 'nominalType',
          label: 'Nominal type',
          'aria-label': 'nominal type',
          type: 'autocomplete',
          required: true,
          clearable: true,
          openOnClick: true,
          placeholder: '',
          item: {
            value: 'value',
            label: 'label',
          },
          data: {
            options: [
              {
                label: 'Account',
                value: 'account',
              },
              {
                label: 'Aircraft',
                value: 'aircraft',
              },
              {
                label: 'Air Waybill',
                value: 'airWaybill',
              },
              {
                label: 'Bank Account',
                value: 'bank',
              },
              {
                label: 'Carriage',
                value: 'carriage',
              },
              {
                label: 'Container',
                value: 'container',
              },
              {
                label: 'Flight',
                value: 'flight',
              },
              {
                label: 'Identity Document',
                value: 'identity',
              },
              {
                label: 'Organisation',
                value: 'organisation',
              },
              {
                label: 'Person',
                value: 'person',
              },
              {
                label: 'Postal Address',
                value: 'postal',
              },
              {
                label: 'Telephone',
                value: 'telephone',
              },
              {
                label: 'Trailer',
                value: 'trailer',
              },
              {
                label: 'Vehicle',
                value: 'vehicle',
              },
              {
                label: 'Vessel',
                value: 'vessel',
              },
            ],
          },
        },
        {
          id: 'systemsCheck',
          fieldId: 'systemsCheck',
          label: 'System checks completed',
          'aria-label': 'system checks completed',
          hint: 'List all the systems you have checked this nominal against',
          type: 'autocomplete',
          required: true,
          multi: true,
          openOnClick: true,
          closeOnSelect: false,
          item: {
            value: 'id',
            label: 'name',
          },
          data: {
            url: '${environmentContext.referenceDataUrl}/v2/entities/partnersystems?sort=name.asc&mode=dataOnly&validDateTime=true',
          },
        },
        {
          id: 'comments',
          fieldId: 'comments',
          label: 'Comments',
          hint: 'Provide as much useful information as possible. This target will be sent to a frontline team for interdiction.',
          type: 'textarea',
        },
      ],
    },
    {
      id: 'issuingHub',
      fieldId: 'issuingHub',
      label: 'Issuing hub',
      'aria-label': 'issuing hub',
      type: 'autocomplete',
      clearable: true,
      openOnClick: true,
      placeholder: '',
      required: true,
      item: {
        value: 'name',
        label: 'name',
      },
      data: {
        url: '${environmentContext.referenceDataUrl}/v2/entities/issuinghubcontact?sort=name.asc&mode=dataOnly&validDateTime=true',
      },
    },
    {
      id: 'mode',
      fieldId: 'mode',
      label: 'Mode',
      type: 'autocomplete',
      clearable: true,
      openOnClick: true,
      placeholder: '',
      readonly: true,
      item: {
        value: 'id',
        label: 'name',
      },
      data: {
        url: '${environmentContext.referenceDataUrl}/v2/entities/targetmode?sort=name.asc&filter=ca=eq.t&mode=dataOnly&validDateTime=true',
      },
      description: 'Should this be readonly or editable?',
    },
    {
      id: 'movement',
      fieldId: 'movement',
      type: 'container',
      components: [
        {
          id: 'flightNumber',
          fieldId: 'flightNumber',
          label: 'Flight number',
          type: 'text',
          pattern: '^[0-9A-Za-z]+$',
          required: true,
        },
        {
          id: 'route',
          fieldId: 'route',
          label: 'Route',
          type: 'text',
          required: true,
        },
        {
          id: 'direction',
          fieldId: 'direction',
          label: 'Inbound or outbound',
          type: 'radios',
          required: true,
          data: {
            options: [
              {
                value: 'INBOUND',
                label: 'Inbound',
              },
              {
                value: 'OUTBOUND',
                label: 'Outbound',
              },
            ],
          },
        },
        {
          id: 'arrivalPort',
          fieldId: 'arrivalPort',
          label: 'Port',
          'aria-label': 'port',
          hint: 'The port that the target is scheduled to arrive at',
          type: 'autocomplete',
          item: {
            value: 'id',
            label: 'name',
          },
          required: true,
          clearable: true,
          openOnClick: true,
          placeholder: '',
          data: {
            url: '${environmentContext.referenceDataUrl}/v2/entities/port?skip=0&sort=name.asc&select=id,name,municipality&filter=countryid=eq.234&filter=air=eq.true&filter=nonoperational=eq.false&mode=dataOnly&validDateTime=true',
          },
          show_when: {
            field: './direction',
            op: 'eq',
            value: 'INBOUND',
          },
        },
        {
          id: 'departurePort',
          fieldId: 'departurePort',
          label: 'Port',
          'aria-label': 'port',
          hint: 'The port that the target is scheduled to depart from',
          type: 'autocomplete',
          item: {
            value: 'id',
            label: 'name',
          },
          required: true,
          clearable: true,
          openOnClick: true,
          placeholder: '',
          data: {
            url: '${environmentContext.referenceDataUrl}/v2/entities/port?skip=0&sort=name.asc&select=id,name,municipality&filter=countryid=eq.234&filter=air=eq.true&filter=nonoperational=eq.false&mode=dataOnly&validDateTime=true',
          },
          show_when: {
            field: './direction',
            op: 'eq',
            value: 'OUTBOUND',
          },
        },
        {
          id: 'arrival',
          fieldId: 'arrival',
          type: 'container',
          components: [
            {
              id: 'date',
              fieldId: 'date',
              label: 'Estimated date of arrival',
              type: 'date',
              required: true,
            },
            {
              id: 'time',
              fieldId: 'time',
              label: 'Estimated time of arrival',
              type: 'time',
              required: true,
            },
          ],
          required: true,
          show_when: {
            field: '../movement/direction',
            op: 'eq',
            value: 'INBOUND',
          },
        },
        {
          id: 'departure',
          fieldId: 'departure',
          type: 'container',
          components: [
            {
              id: 'date',
              fieldId: 'date',
              label: 'Estimated date of departure',
              type: 'date',
              required: true,
            },
            {
              id: 'time',
              fieldId: 'time',
              label: 'Estimated time of departure',
              type: 'time',
              required: true,
            },
          ],
          required: true,
          show_when: {
            field: '../movement/direction',
            op: 'eq',
            value: 'OUTBOUND',
          },
        },
      ],
    },
    {
      id: 'otherPersons',
      fieldId: 'otherPersons',
      type: 'collection',
      cya_label: 'Other passengers',
      labels: {
        item: 'Passenger ${index} details',
        add: 'Add another passenger',
      },
      countOffset: 1,
      item: [
        {
          id: 'name',
          fieldId: 'name',
          type: 'container',
          components: [
            {
              id: 'first',
              fieldId: 'first',
              label: 'Given name',
              type: 'text',
              required: true,
            },
            {
              id: 'last',
              fieldId: 'last',
              label: 'Family name',
              type: 'text',
              required: true,
            },
          ],
        },
        {
          id: 'dateOfBirth',
          fieldId: 'dateOfBirth',
          label: 'Date of birth',
          type: 'date',
          required: true,
        },
        {
          id: 'nationality',
          fieldId: 'nationality',
          label: 'Nationality',
          'aria-label': 'nationality',
          type: 'autocomplete',
          clearable: true,
          openOnClick: true,
          placeholder: '',
          item: {
            value: 'id',
            label: 'nationality',
          },
          data: {
            url: '${environmentContext.referenceDataUrl}/v2/entities/nationality?sort=nationality.asc&mode=dataOnly&validDateTime=true',
          },
        },
        {
          id: 'sex',
          fieldId: 'sex',
          label: 'Sex',
          'aria-label': 'sex',
          type: 'autocomplete',
          clearable: true,
          openOnClick: true,
          placeholder: '',
          item: {
            value: 'id',
            label: 'name',
          },
          data: {
            url: '${environmentContext.referenceDataUrl}/v2/entities/sex?sort=name.asc&mode=dataOnly&validDateTime=true',
          },
          description: 'This is shown as Gender in the UI, which is a different thing to sex.',
        },
        {
          id: 'document',
          fieldId: 'document',
          type: 'container',
          components: [
            {
              id: 'type',
              fieldId: 'type',
              label: 'Travel document type',
              'aria-label': 'travel document type',
              type: 'autocomplete',
              openOnClick: true,
              required: true,
              clearable: true,
              placeholder: '',
              item: {
                value: 'id',
                label: 'shortdescription',
              },
              data: {
                url: '${environmentContext.referenceDataUrl}/v2/entities/documenttype?sort=shortdescription.asc&mode=dataOnly&validDateTime=true',
              },
            },
            {
              id: 'documentNumber',
              fieldId: 'documentNumber',
              label: 'Travel document number',
              type: 'text',
              required: true,
              show_when: {
                field: './type.code',
                op: 'in',
                values: [
                  'CR',
                  'ID',
                  'IN',
                  'IR',
                  'P ',
                  'P',
                  'PT',
                  'VA',
                  'VB',
                  'VC',
                  'VD',
                  'VR',
                ],
              },
              description: "Note that one of the values in the condition is 'P ' with a space afterwards. That's what's in the reference data.",
            },
            {
              id: 'documentExpiry',
              fieldId: 'documentExpiry',
              label: 'Travel document expiry',
              type: 'date',
              required: true,
              show_when: {
                field: './type.code',
                op: 'in',
                values: [
                  'CR',
                  'ID',
                  'IN',
                  'IR',
                  'P ',
                  'P',
                  'PT',
                  'VA',
                  'VB',
                  'VC',
                  'VD',
                  'VR',
                ],
              },
              description: "Note that one of the values in the condition is 'P ' with a space afterwards. That's what's in the reference data.",
            },
          ],
        },
        {
          id: 'seatNumber',
          fieldId: 'seatNumber',
          label: 'Seat number',
          type: 'text',
          pattern: '^[0-9]{1,2}[A-Za-z]{1}$',
        },
        {
          id: 'baggage',
          fieldId: 'baggage',
          type: 'container',
          components: [
            {
              id: 'bagCount',
              fieldId: 'bagCount',
              label: 'Number of bags',
              type: 'text',
            },
            {
              id: 'weight',
              fieldId: 'weight',
              label: 'Baggage weight (kg)',
              type: 'text',
              show_when: {
                field: './bagCount',
                op: 'nin',
                values: [
                  '',
                  '0',
                  null,
                ],
              },
              description: 'Should this only be shown when the number of bags is a positive integer?',
            },
            {
              id: 'tags',
              fieldId: 'tags',
              label: 'Tag details',
              type: 'text',
              show_when: {
                field: './bagCount',
                op: 'nin',
                values: [
                  '',
                  '0',
                  null,
                ],
              },
              description: 'Should this only be shown when the number of bags is a positive integer?',
            },
          ],
        },
        {
          id: 'passengerStatus',
          fieldId: 'passengerStatus',
          label: 'Passenger status',
          hint: 'Set a status for this passenger',
          type: 'radios',
          required: true,
          data: {
            options: [
              {
                value: 'NOT_AVAILABLE',
                label: 'Status not available',
              },
              {
                value: 'CHECKED_IN',
                label: 'Checked in',
              },
              {
                value: 'DEPARTURE_CONFIRMED',
                label: 'Departure confirmed',
              },
            ],
          },
        },
        {
          id: 'photograph',
          fieldId: 'photograph',
          label: 'Photograph',
          type: 'file',
        },
        {
          id: 'photographDate',
          fieldId: 'photographDate',
          label: 'Approximate photograph date',
          type: 'date',
        },
        {
          id: 'watchList',
          fieldId: 'watchList',
          label: 'Has this person been added to Border Crossing?',
          type: 'radios',
          required: true,
          data: {
            options: [
              {
                value: 'YES',
                label: 'Yes',
                nested: [
                  {
                    id: 'individualReferenceNumber',
                    fieldId: 'individualReferenceNumber',
                    label: 'Input the Border Crossing (IRN) Individual Reference Number',
                    type: 'text',
                  },
                ],
              },
              {
                value: 'NO',
                label: 'No',
              },
            ],
          },
        },
        {
          id: 'pncOutcome',
          fieldId: 'pncOutcome',
          label: 'Police National Computer (PNC) check',
          type: 'radios',
          data: {
            options: [
              {
                value: 'NO_RECORDS_FOUND',
                label: 'No PNC records were found based on the identity information available',
              },
              {
                value: 'INSUFFICIENT_IDENTITY_INFORMATION',
                label: 'There was not enough identity information available to perform a PNC check',
              },
              {
                value: 'INSUFFICIENT_TIME',
                label: 'There was not enough time to complete a PNC search',
              },
              {
                value: 'CHECK_NOT_RELEVANT_FOR_PERSON',
                label: 'A PNC check is not relevant for this person',
              },
              {
                value: 'IRRELEVANT_INFORMATION_FOUND',
                label: 'A PNC record was found but it does not contain any relevant information',
              },
              {
                value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
                label: 'PNC information was found which is potentially relevant to the target',
              },
            ],
          },
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'You must select one option to reflect your actions to confirm a PNC check',
            },
          ],
        },
        {
          id: 'pncFound',
          fieldId: 'pncFound',
          type: 'container',
          className: 'indent',
          components: [
            {
              id: 'pncId',
              fieldId: 'pncId',
              label: 'Enter the PNC ID of the record you found',
              type: 'text',
              hint: 'For example, 12/34567890A, 12/34A, or 76/6518286013A',
              pattern: '^[0-9]{2}/[0-9]{1,}[A-Za-z]{1}$',
              required: true,
              custom_errors: [
                {
                  type: 'pattern',
                  message: 'Enter a PNC ID in the correct format',
                },
                {
                  type: 'required',
                  message: 'Enter a PNC ID in the correct format',
                },
              ],
            },
            {
              id: 'safetyInformation',
              fieldId: 'safetyInformation',
              className: 'margin-bottom-0 padding-top-1',
              label: 'Did you find any information which was relevant for officer or passenger safety?',
              type: 'radios',
              data: {
                options: [
                  {
                    value: 'No',
                    label: 'No',
                  },
                  {
                    value: 'Yes',
                    label: 'Yes',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must confirm if you found information relevant to officer or passenger safety',
                },
              ],
            },
          ],
          required: true,
          show_when: [
            {
              field: './pncOutcome',
              op: 'eq',
              value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
            },
          ],
        },
        {
          id: 'pncDetails',
          fieldId: 'pncDetails',
          className: 'no-label indent padding-top-2',
          type: 'container',
          components: [
            {
              id: 'types',
              fieldId: 'types',
              label: 'PNC information types relevant to officer or passenger safety',
              className: 'no-label types indent margin-bottom-0',
              type: 'checkboxes',
              data: {
                options: [
                  {
                    value: 'VIOLENCE',
                    label: 'Violence',
                  },
                  {
                    value: 'FIREARMS',
                    label: 'Firearms',
                  },
                  {
                    value: 'DRUG_USE',
                    label: 'Drug use',
                  },
                  {
                    value: 'SELF_HARM',
                    label: 'Self harm',
                  },
                  {
                    value: 'MISSING',
                    label: 'Missing',
                  },
                  {
                    value: 'WANTED',
                    label: 'Wanted',
                  },
                  {
                    value: 'MENTAL',
                    label: 'Mental health issues',
                  },
                  {
                    value: 'STAFF_CONCERN',
                    label: 'Other concerns for the safety of frontline staff',
                  },
                  {
                    value: 'POI_CONCERN',
                    label: 'Other concerns for the person of interest',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information relevant for officer or passenger safety',
                },
              ],
            },
            {
              id: 'staffConcerns',
              fieldId: 'staffConcerns',
              className: 'border-left error-spacing margin-bottom-0 padding-left-1 padding-top-2',
              label: 'Explain the concerns for safety of frontline staff',
              type: 'textarea',
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information relevant to officer safety',
                },
              ],
              showCharacterCount: true,
              show_when: {
                field: './types',
                op: 'contains',
                value: 'staff_concern',
              },
            },
            {
              id: 'poiConcerns',
              fieldId: 'poiConcerns',
              className: 'border-left no-bottom-spacing margin-bottom-0 padding-left-1 padding-top-2',
              label: 'Explain the concerns for person of interest',
              type: 'textarea',
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information relevant to passenger safety',
                },
              ],
              showCharacterCount: true,
              show_when: {
                field: './types',
                op: 'contains',
                value: 'poi_concern',
              },
            },
          ],
          show_when: [
            {
              field: './pncFound/safetyInformation',
              op: 'eq',
              value: 'Yes',
            },
            {
              field: './pncOutcome',
              op: 'eq',
              value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
            },
          ],
        },
        {
          id: 'pncCategories',
          fieldId: 'pncCategories',
          className: 'indent margin-bottom-2',
          type: 'container',
          components: [
            {
              type: 'html',
              tagName: 'p',
              content: 'Select all categories in which PNC information indicated potential involvement',
              className: 'margin-top-0 padding-top-3',
            },
            {
              id: 'drugsPossession',
              fieldId: 'drugsPossession',
              className: 'no-label margin-bottom-1',
              label: '',
              show_on_cya: false,
              type: 'checkboxes',
              data: {
                options: [
                  {
                    value: 'DRUGS',
                    label: 'Possession of drugs',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'possessionClass',
              fieldId: 'possessionClass',
              label: 'Possession of drugs',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'CLASS_A',
                    label: 'Class A',
                  },
                  {
                    value: 'CLASS_B',
                    label: 'Class B',
                  },
                  {
                    value: 'CLASS_C',
                    label: 'Class C',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with possession of drugs',
                },
              ],
              show_when: {
                field: './drugsPossession',
                op: 'contains',
                value: 'drugs',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'drugsSupply',
              fieldId: 'drugsSupply',
              className: 'no-label margin-bottom-1',
              label: 'Possession of drugs',
              show_on_cya: false,
              type: 'checkboxes',
              data: {
                options: [
                  {
                    value: 'SUPPLY',
                    label: 'Supply of drugs',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'supplyClass',
              fieldId: 'supplyClass',
              label: 'Supply of drugs',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'CLASS_A',
                    label: 'Class A',
                  },
                  {
                    value: 'CLASS_B',
                    label: 'Class B',
                  },
                  {
                    value: 'CLASS_C',
                    label: 'Class C',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with supply of drugs',
                },
              ],
              show_when: {
                field: './drugsSupply',
                op: 'contains',
                value: 'supply',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'drugsTrafficking',
              fieldId: 'drugsTrafficking',
              label: 'Trafficking of drugs',
              className: 'no-label margin-bottom-1',
              show_on_cya: false,
              type: 'checkboxes',
              data: {
                options: [
                  {
                    value: 'TRAFFICKING',
                    label: 'Trafficking of drugs',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'traffickingClass',
              fieldId: 'traffickingClass',
              label: 'Trafficking of drugs',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'CLASS_A',
                    label: 'Class A',
                  },
                  {
                    value: 'CLASS_B',
                    label: 'Class B',
                  },
                  {
                    value: 'CLASS_C',
                    label: 'Class C',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with trafficking of drugs',
                },
              ],
              show_when: {
                field: './drugsTrafficking',
                op: 'contains',
                value: 'trafficking',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'weaponsPossession',
              fieldId: 'weaponsPossession',
              label: 'Possession of firearms, weapons, or harmful materials',
              className: 'no-label margin-bottom-1',
              type: 'checkboxes',
              show_on_cya: false,
              data: {
                options: [
                  {
                    value: 'POSSESSION',
                    label: 'Possession of firearms, weapons, or harmful materials',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'weaponsPossessionOpts',
              fieldId: 'weaponsPossessionOpts',
              label: 'Possession of firearms, weapons, or harmful materials',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'FIREARMS',
                    label: 'Firearms',
                  },
                  {
                    value: 'OFFENSIVE_WEAPONS',
                    label: 'Offensive weapons',
                    hint: '(including non-lethal firearms)',
                  },
                  {
                    value: 'EXPLOSIVE_MATERIALS',
                    label: 'Explosive materials',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with possession of firearms, weapons, or harmful materials',
                },
              ],
              show_when: {
                field: './weaponsPossession',
                op: 'contains',
                value: 'possession',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'weaponsSupply',
              fieldId: 'weaponsSupply',
              label: 'Supply of firearms, weapons, or harmful materials',
              className: 'no-label margin-bottom-1',
              type: 'checkboxes',
              show_on_cya: false,
              data: {
                options: [
                  {
                    value: 'SUPPLY',
                    label: 'Supply of firearms, weapons, or harmful materials',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'weaponsSupplyOptions',
              fieldId: 'weaponsSupplyOptions',
              type: 'checkboxes',
              label: 'Supply of firearms, weapons, or harmful materials',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'FIREARMS',
                    label: 'Firearms',
                  },
                  {
                    value: 'OFFENSIVE_WEAPONS',
                    label: 'Offensive weapons',
                    hint: '(including non-lethal firearms)',
                  },
                  {
                    value: 'EXPLOSIVE_MATERIALS',
                    label: 'Explosive materials',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with supply of firearms, weapons, or harmful materials',
                },
              ],
              show_when: {
                field: './weaponsSupply',
                op: 'contains',
                value: 'supply',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'goodsSmuggling',
              fieldId: 'goodsSmuggling',
              label: 'Smuggling of goods',
              className: 'no-label margin-bottom-1',
              type: 'checkboxes',
              show_on_cya: false,
              data: {
                options: [
                  {
                    value: 'GOODS_SMUGGLING',
                    label: 'Smuggling of goods',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'goodsSmugglingOpts',
              fieldId: 'goodsSmugglingOpts',
              label: 'Smuggling of goods',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'EXCISE',
                    label: 'Actively relating to excise goods',
                  },
                  {
                    value: 'FALSE_IMPORT',
                    label: 'False import licences',
                  },
                  {
                    value: 'MONEY_LAUNDERING',
                    label: 'Money laundering',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information indicating potential involvement with smuggling of goods',
                },
              ],
              show_when: {
                field: './goodsSmuggling',
                op: 'contains',
                value: 'goods_smuggling',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'peopleSmuggling',
              fieldId: 'peopleSmuggling',
              label: 'Smuggling of people',
              className: 'no-label margin-bottom-1',
              type: 'checkboxes',
              show_on_cya: false,
              data: {
                options: [
                  {
                    value: 'PEOPLE_SMUGGLING',
                    label: 'Smuggling of people',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'peopleSmugglingOpts',
              fieldId: 'peopleSmugglingOpts',
              label: 'Smuggling of people',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'HUMAN_TRAFFICKING',
                    label: 'Human trafficking',
                  },
                  {
                    value: 'FALSE_DOCUMENTS',
                    label: 'False identity documents',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information indicating potential smuggling of people',
                },
              ],
              show_when: {
                field: './peopleSmuggling',
                op: 'contains',
                value: 'people_smuggling',
              },
              description: 'Label added for cya page',
            },
          ],
          show_when: [
            {
              field: './pncOutcome',
              op: 'eq',
              value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
            },
          ],
        },
      ],
    },
    {
      id: 'person',
      fieldId: 'person',
      type: 'container',
      components: [
        {
          id: 'name',
          fieldId: 'name',
          type: 'container',
          components: [
            {
              id: 'first',
              fieldId: 'first',
              label: 'Given name',
              type: 'text',
              required: true,
            },
            {
              id: 'last',
              fieldId: 'last',
              label: 'Family name',
              type: 'text',
              required: true,
            },
          ],
        },
        {
          id: 'dateOfBirth',
          fieldId: 'dateOfBirth',
          label: 'Date of birth',
          type: 'date',
          required: true,
        },
        {
          id: 'nationality',
          fieldId: 'nationality',
          label: 'Nationality',
          'aria-label': 'nationality',
          type: 'autocomplete',
          clearable: true,
          openOnClick: true,
          placeholder: '',
          item: {
            value: 'id',
            label: 'nationality',
          },
          data: {
            url: '${environmentContext.referenceDataUrl}/v2/entities/nationality?sort=nationality.asc&mode=dataOnly&validDateTime=true',
          },
        },
        {
          id: 'sex',
          fieldId: 'sex',
          label: 'Sex',
          'aria-label': 'sex',
          type: 'autocomplete',
          clearable: true,
          openOnClick: true,
          placeholder: '',
          item: {
            value: 'id',
            label: 'name',
          },
          data: {
            url: '${environmentContext.referenceDataUrl}/v2/entities/sex?sort=name.asc&mode=dataOnly&validDateTime=true',
          },
          description: 'This is shown as Gender in the UI, which is a different thing to sex.',
        },
        {
          id: 'document',
          fieldId: 'document',
          type: 'container',
          components: [
            {
              id: 'type',
              fieldId: 'type',
              label: 'Travel document type',
              'aria-label': 'travel document type',
              type: 'autocomplete',
              openOnClick: true,
              required: true,
              clearable: true,
              placeholder: '',
              item: {
                value: 'id',
                label: 'shortdescription',
              },
              data: {
                url: '${environmentContext.referenceDataUrl}/v2/entities/documenttype?sort=shortdescription.asc&mode=dataOnly&validDateTime=true',
              },
            },
            {
              id: 'documentNumber',
              fieldId: 'documentNumber',
              label: 'Travel document number',
              type: 'text',
              required: true,
              show_when: {
                field: './type.code',
                op: 'in',
                values: [
                  'CR',
                  'ID',
                  'IN',
                  'IR',
                  'P ',
                  'P',
                  'PT',
                  'VA',
                  'VB',
                  'VC',
                  'VD',
                  'VR',
                ],
              },
              description: "Note that one of the values in the condition is 'P ' with a space afterwards. That's what's in the reference data.",
            },
            {
              id: 'documentExpiry',
              fieldId: 'documentExpiry',
              label: 'Travel document expiry',
              type: 'date',
              required: true,
              show_when: {
                field: './type.code',
                op: 'in',
                values: [
                  'CR',
                  'ID',
                  'IN',
                  'IR',
                  'P ',
                  'P',
                  'PT',
                  'VA',
                  'VB',
                  'VC',
                  'VD',
                  'VR',
                ],
              },
              description: "Note that one of the values in the condition is 'P ' with a space afterwards. That's what's in the reference data.",
            },
          ],
        },
        {
          id: 'seatNumber',
          fieldId: 'seatNumber',
          label: 'Seat number',
          type: 'text',
          pattern: '^[0-9]{1,2}[A-Za-z]{1}$',
        },
        {
          id: 'baggage',
          fieldId: 'baggage',
          type: 'container',
          components: [
            {
              id: 'bagCount',
              fieldId: 'bagCount',
              label: 'Number of bags',
              type: 'text',
            },
            {
              id: 'weight',
              fieldId: 'weight',
              label: 'Baggage weight (kg)',
              type: 'text',
              show_when: {
                field: './bagCount',
                op: 'nin',
                values: [
                  '',
                  '0',
                  null,
                ],
              },
              description: 'Should this only be shown when the number of bags is a positive integer?',
            },
            {
              id: 'tags',
              fieldId: 'tags',
              label: 'Tag details',
              type: 'text',
              show_when: {
                field: './bagCount',
                op: 'nin',
                values: [
                  '',
                  '0',
                  null,
                ],
              },
              description: 'Should this only be shown when the number of bags is a positive integer?',
            },
          ],
        },
        {
          id: 'passengerStatus',
          fieldId: 'passengerStatus',
          label: 'Passenger status',
          hint: 'Set a status for this passenger',
          type: 'radios',
          required: true,
          data: {
            options: [
              {
                value: 'NOT_AVAILABLE',
                label: 'Status not available',
              },
              {
                value: 'CHECKED_IN',
                label: 'Checked in',
              },
              {
                value: 'DEPARTURE_CONFIRMED',
                label: 'Departure confirmed',
              },
            ],
          },
        },
        {
          id: 'photograph',
          fieldId: 'photograph',
          label: 'Photograph',
          type: 'file',
        },
        {
          id: 'photographDate',
          fieldId: 'photographDate',
          label: 'Approximate photograph date',
          type: 'date',
        },
        {
          id: 'watchList',
          fieldId: 'watchList',
          label: 'Has this person been added to Border Crossing?',
          type: 'radios',
          required: true,
          data: {
            options: [
              {
                value: 'YES',
                label: 'Yes',
                nested: [
                  {
                    id: 'individualReferenceNumber',
                    fieldId: 'individualReferenceNumber',
                    label: 'Input Border Crossing (IRN) Individual Reference Number',
                    type: 'text',
                  },
                ],
              },
              {
                value: 'NO',
                label: 'No',
              },
            ],
          },
        },
        {
          id: 'pncOutcome',
          fieldId: 'pncOutcome',
          label: 'Police National Computer (PNC) check',
          type: 'radios',
          data: {
            options: [
              {
                value: 'NO_RECORDS_FOUND',
                label: 'No PNC records were found based on the identity information available',
              },
              {
                value: 'INSUFFICIENT_IDENTITY_INFORMATION',
                label: 'There was not enough identity information available to perform a PNC check',
              },
              {
                value: 'INSUFFICIENT_TIME',
                label: 'There was not enough time to complete a PNC search',
              },
              {
                value: 'CHECK_NOT_RELEVANT_FOR_PERSON',
                label: 'A PNC check is not relevant for this person',
              },
              {
                value: 'IRRELEVANT_INFORMATION_FOUND',
                label: 'A PNC record was found but it does not contain any relevant information',
              },
              {
                value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
                label: 'PNC information was found which is potentially relevant to the target',
              },
            ],
          },
          required: true,
          custom_errors: [
            {
              type: 'required',
              message: 'You must select one option to reflect your actions to confirm a PNC check',
            },
          ],
        },
        {
          id: 'pncFound',
          fieldId: 'pncFound',
          type: 'container',
          className: 'indent',
          components: [
            {
              id: 'pncId',
              fieldId: 'pncId',
              label: 'Enter the PNC ID of the record you found',
              type: 'text',
              hint: 'For example, 12/34567890A, 12/34A, or 76/6518286013A',
              pattern: '^[0-9]{2}/[0-9]{1,}[A-Za-z]{1}$',
              required: true,
              custom_errors: [
                {
                  type: 'pattern',
                  message: 'Enter a PNC ID in the correct format',
                },
                {
                  type: 'required',
                  message: 'Enter a PNC ID in the correct format',
                },
              ],
            },
            {
              id: 'safetyInformation',
              fieldId: 'safetyInformation',
              className: 'margin-bottom-0 padding-top-1',
              label: 'Did you find any information which was relevant for officer or passenger safety?',
              type: 'radios',
              data: {
                options: [
                  {
                    value: 'No',
                    label: 'No',
                  },
                  {
                    value: 'Yes',
                    label: 'Yes',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must confirm if you found information relevant to officer or passenger safety',
                },
              ],
            },
          ],
          required: true,
          show_when: [
            {
              field: './pncOutcome',
              op: 'eq',
              value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
            },
          ],
        },
        {
          id: 'pncDetails',
          fieldId: 'pncDetails',
          className: 'no-label indent padding-top-2',
          type: 'container',
          components: [
            {
              id: 'types',
              fieldId: 'types',
              label: 'PNC information types relevant to officer or passenger safety',
              className: 'no-label types indent margin-bottom-0',
              type: 'checkboxes',
              data: {
                options: [
                  {
                    value: 'VIOLENCE',
                    label: 'Violence',
                  },
                  {
                    value: 'FIREARMS',
                    label: 'Firearms',
                  },
                  {
                    value: 'DRUG_USE',
                    label: 'Drug use',
                  },
                  {
                    value: 'SELF_HARM',
                    label: 'Self harm',
                  },
                  {
                    value: 'MISSING',
                    label: 'Missing',
                  },
                  {
                    value: 'WANTED',
                    label: 'Wanted',
                  },
                  {
                    value: 'MENTAL',
                    label: 'Mental health issues',
                  },
                  {
                    value: 'STAFF_CONCERN',
                    label: 'Other concerns for the safety of frontline staff',
                  },
                  {
                    value: 'POI_CONCERN',
                    label: 'Other concerns for the person of interest',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information relevant for officer or passenger safety',
                },
              ],
            },
            {
              id: 'staffConcerns',
              fieldId: 'staffConcerns',
              className: 'border-left error-spacing margin-bottom-0 padding-left-1 padding-top-2',
              label: 'Explain the concerns for safety of frontline staff',
              type: 'textarea',
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information relevant to officer safety',
                },
              ],
              showCharacterCount: true,
              show_when: {
                field: './types',
                op: 'contains',
                value: 'staff_concern',
              },
            },
            {
              id: 'poiConcerns',
              fieldId: 'poiConcerns',
              className: 'border-left no-bottom-spacing margin-bottom-0 padding-left-1 padding-top-2',
              label: 'Explain the concerns for person of interest',
              type: 'textarea',
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information relevant to passenger safety',
                },
              ],
              showCharacterCount: true,
              show_when: {
                field: './types',
                op: 'contains',
                value: 'poi_concern',
              },
            },
          ],
          show_when: [
            {
              field: './pncFound/safetyInformation',
              op: 'eq',
              value: 'Yes',
            },
            {
              field: './pncOutcome',
              op: 'eq',
              value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
            },
          ],
        },
        {
          id: 'pncCategories',
          fieldId: 'pncCategories',
          className: 'indent margin-bottom-2',
          type: 'container',
          components: [
            {
              type: 'html',
              tagName: 'p',
              content: 'Select all categories in which PNC information indicated potential involvement',
              className: 'margin-top-0 padding-top-3',
            },
            {
              id: 'drugsPossession',
              fieldId: 'drugsPossession',
              className: 'no-label margin-bottom-1',
              label: '',
              show_on_cya: false,
              type: 'checkboxes',
              data: {
                options: [
                  {
                    value: 'DRUGS',
                    label: 'Possession of drugs',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'possessionClass',
              fieldId: 'possessionClass',
              label: 'Possession of drugs',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'CLASS_A',
                    label: 'Class A',
                  },
                  {
                    value: 'CLASS_B',
                    label: 'Class B',
                  },
                  {
                    value: 'CLASS_C',
                    label: 'Class C',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with possession of drugs',
                },
              ],
              show_when: {
                field: './drugsPossession',
                op: 'contains',
                value: 'drugs',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'drugsSupply',
              fieldId: 'drugsSupply',
              className: 'no-label margin-bottom-1',
              label: 'Possession of drugs',
              show_on_cya: false,
              type: 'checkboxes',
              data: {
                options: [
                  {
                    value: 'SUPPLY',
                    label: 'Supply of drugs',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'supplyClass',
              fieldId: 'supplyClass',
              label: 'Supply of drugs',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'CLASS_A',
                    label: 'Class A',
                  },
                  {
                    value: 'CLASS_B',
                    label: 'Class B',
                  },
                  {
                    value: 'CLASS_C',
                    label: 'Class C',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with supply of drugs',
                },
              ],
              show_when: {
                field: './drugsSupply',
                op: 'contains',
                value: 'supply',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'drugsTrafficking',
              fieldId: 'drugsTrafficking',
              label: 'Trafficking of drugs',
              className: 'no-label margin-bottom-1',
              show_on_cya: false,
              type: 'checkboxes',
              data: {
                options: [
                  {
                    value: 'TRAFFICKING',
                    label: 'Trafficking of drugs',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'traffickingClass',
              fieldId: 'traffickingClass',
              label: 'Trafficking of drugs',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'CLASS_A',
                    label: 'Class A',
                  },
                  {
                    value: 'CLASS_B',
                    label: 'Class B',
                  },
                  {
                    value: 'CLASS_C',
                    label: 'Class C',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with trafficking of drugs',
                },
              ],
              show_when: {
                field: './drugsTrafficking',
                op: 'contains',
                value: 'trafficking',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'weaponsPossession',
              fieldId: 'weaponsPossession',
              label: 'Possession of firearms, weapons, or harmful materials',
              className: 'no-label margin-bottom-1',
              type: 'checkboxes',
              show_on_cya: false,
              data: {
                options: [
                  {
                    value: 'POSSESSION',
                    label: 'Possession of firearms, weapons, or harmful materials',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'weaponsPossessionOpts',
              fieldId: 'weaponsPossessionOpts',
              label: 'Possession of firearms, weapons, or harmful materials',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'FIREARMS',
                    label: 'Firearms',
                  },
                  {
                    value: 'OFFENSIVE_WEAPONS',
                    label: 'Offensive weapons',
                    hint: '(including non-lethal firearms)',
                  },
                  {
                    value: 'EXPLOSIVE_MATERIALS',
                    label: 'Explosive materials',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with possession of firearms, weapons, or harmful materials',
                },
              ],
              show_when: {
                field: './weaponsPossession',
                op: 'contains',
                value: 'possession',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'weaponsSupply',
              fieldId: 'weaponsSupply',
              label: 'Supply of firearms, weapons, or harmful materials',
              className: 'no-label margin-bottom-1',
              type: 'checkboxes',
              show_on_cya: false,
              data: {
                options: [
                  {
                    value: 'SUPPLY',
                    label: 'Supply of firearms, weapons, or harmful materials',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'weaponsSupplyOptions',
              fieldId: 'weaponsSupplyOptions',
              type: 'checkboxes',
              label: 'Supply of firearms, weapons, or harmful materials',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'FIREARMS',
                    label: 'Firearms',
                  },
                  {
                    value: 'OFFENSIVE_WEAPONS',
                    label: 'Offensive weapons',
                    hint: '(including non-lethal firearms)',
                  },
                  {
                    value: 'EXPLOSIVE_MATERIALS',
                    label: 'Explosive materials',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'Please provide additional details regarding potential involvement with supply of firearms, weapons, or harmful materials',
                },
              ],
              show_when: {
                field: './weaponsSupply',
                op: 'contains',
                value: 'supply',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'goodsSmuggling',
              fieldId: 'goodsSmuggling',
              label: 'Smuggling of goods',
              className: 'no-label margin-bottom-1',
              type: 'checkboxes',
              show_on_cya: false,
              data: {
                options: [
                  {
                    value: 'GOODS_SMUGGLING',
                    label: 'Smuggling of goods',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'goodsSmugglingOpts',
              fieldId: 'goodsSmugglingOpts',
              label: 'Smuggling of goods',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'EXCISE',
                    label: 'Actively relating to excise goods',
                  },
                  {
                    value: 'FALSE_IMPORT',
                    label: 'False import licences',
                  },
                  {
                    value: 'MONEY_LAUNDERING',
                    label: 'Money laundering',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information indicating potential involvement with smuggling of goods',
                },
              ],
              show_when: {
                field: './goodsSmuggling',
                op: 'contains',
                value: 'goods_smuggling',
              },
              description: 'Label added for cya page',
            },
            {
              id: 'peopleSmuggling',
              fieldId: 'peopleSmuggling',
              label: 'Smuggling of people',
              className: 'no-label margin-bottom-1',
              type: 'checkboxes',
              show_on_cya: false,
              data: {
                options: [
                  {
                    value: 'PEOPLE_SMUGGLING',
                    label: 'Smuggling of people',
                  },
                ],
              },
              description: 'Label added for cya page',
            },
            {
              id: 'peopleSmugglingOpts',
              fieldId: 'peopleSmugglingOpts',
              label: 'Smuggling of people',
              type: 'checkboxes',
              className: 'indent no-label',
              data: {
                options: [
                  {
                    value: 'HUMAN_TRAFFICKING',
                    label: 'Human trafficking',
                  },
                  {
                    value: 'FALSE_DOCUMENTS',
                    label: 'False identity documents',
                  },
                ],
              },
              required: true,
              custom_errors: [
                {
                  type: 'required',
                  message: 'You must provide details about PNC information indicating potential smuggling of people',
                },
              ],
              show_when: {
                field: './peopleSmuggling',
                op: 'contains',
                value: 'people_smuggling',
              },
              description: 'Label added for cya page',
            },
          ],
          show_when: [
            {
              field: './pncOutcome',
              op: 'eq',
              value: 'POTENTIALLY_RELEVANT_INFORMATION_FOUND',
            },
          ],
        },
      ],
    },
    {
      id: 'additionalInformation',
      fieldId: 'additionalInformation',
      label: 'Any additional information?',
      type: 'textarea',
    },
    {
      id: 'operation',
      fieldId: 'operation',
      label: 'Operation name',
      type: 'text',
    },
    {
      id: 'whySelected',
      fieldId: 'whySelected',
      label: 'Why was this target selected?',
      type: 'textarea',
      description: "Is this something that should be selected? Same as 'Comments on reason for selection'?",
    },
    {
      id: 'teamToReceiveTheTarget',
      fieldId: 'teamToReceiveTheTarget',
      label: 'Select the team that should receive the target',
      'aria-label': 'select the team that should receive the target',
      type: 'autocomplete',
      openOnClick: true,
      clearable: true,
      placeholder: '',
      item: {
        value: 'id',
        label: 'displayname',
      },
      required: true,
      data: {
        url: '${environmentContext.referenceDataUrl}/v2/entities/groups?limit=100&skip=0&sort=displayname.asc&filter=grouptypeid=eq.6&mode=dataOnly&validDateTime=true',
      },
    },
    {
      id: 'remarks',
      fieldId: 'remarks',
      label: 'Targeter actions / Remarks',
      'aria-label': 'targeter actions / remarks',
      type: 'text',
    },
    {
      id: 'publicInterestImmunity',
      fieldId: 'publicInterestImmunity',
      label: 'Public Interest Immunity',
      type: 'radios',
      required: true,
      data: {
        options: [
          {
            value: 'Yes',
            label: 'Yes',
          },
          {
            value: 'No',
            label: 'No',
          },
        ],
      },
    },
  ],
  pages: [
    {
      id: 'info',
      name: 'info',
      title: 'Target information',
      components: [
        {
          use: 'issuingHub',
        },
      ],
      actions: [
        {
          type: 'next',
          validate: true,
          label: 'Continue',
        },
      ],
      cya_link: {
        page: 'info',
        aria_suffix: 'target information',
      },
    },
    {
      id: 'movement',
      name: 'movement',
      title: 'Movement details',
      components: [
        {
          use: 'movement',
        },
      ],
      actions: [
        {
          type: 'next',
          validate: true,
          label: 'Continue',
        },
      ],
      cya_link: {
        page: 'movement',
        aria_suffix: 'movement details',
      },
    },
    {
      id: 'passenger',
      name: 'passenger',
      title: 'Passenger 1 details',
      components: [
        {
          use: 'person',
        },
      ],
      actions: [
        {
          type: 'next',
          validate: true,
          label: 'Continue',
        },
      ],
      cya_link: {
        page: 'passenger',
        aria_suffix: 'passenger 1 details',
      },
    },
    {
      id: 'other-passengers',
      name: 'other-passengers',
      title: 'Other passenger details',
      components: [
        {
          use: 'otherPersons',
        },
      ],
      actions: [
        {
          type: 'next',
          validate: true,
          label: 'Continue',
        },
      ],
      cya_link: {
        page: 'other-passengers',
        aria_suffix: 'other passenger details',
      },
    },
    {
      id: 'selection',
      name: 'selection',
      title: 'Selection details',
      components: [
        {
          use: 'operation',
        },
        {
          use: 'controlStrategies',
        },
        {
          use: 'category',
        },
        {
          use: 'whySelected',
        },
        {
          use: 'additionalInformation',
        },
      ],
      actions: [
        {
          type: 'next',
          validate: true,
          label: 'Continue',
        },
      ],
      cya_link: {
        page: 'selection',
        aria_suffix: 'selection details',
      },
    },
    {
      id: 'nominalChecks',
      name: 'nominalChecks',
      title: 'Checks completed on nominals',
      components: [
        {
          use: 'nominalChecks',
          label: '',
        },
      ],
      actions: [
        {
          type: 'next',
          validate: true,
          label: 'Continue',
        },
      ],
      cya_link: {
        page: 'nominalChecks',
        aria_suffix: 'checks completed on nominals',
      },
    },
    {
      id: 'remarks',
      name: 'remarks',
      title: 'Targeter actions / Remarks',
      components: [
        {
          use: 'remarks',
        },
        {
          use: 'publicInterestImmunity',
        },
      ],
      actions: [
        {
          type: 'next',
          validate: true,
          label: 'Continue',
        },
      ],
      cya_link: {
        page: 'remarks',
        aria_suffix: 'remarks',
      },
    },
    {
      id: 'recipient-details',
      name: 'recipient-details',
      title: 'Recipient Details',
      components: [
        {
          use: 'teamToReceiveTheTarget',
        },
      ],
      actions: [
        {
          type: 'next',
          validate: true,
          label: 'Continue',
        },
      ],
      cya_link: {
        page: 'recipient-details',
        aria_suffix: 'recipient details',
      },
    },
  ],
  hub: {
    format: 'CYA',
  },
  cya: {
    actions: [
      {
        type: 'submit',
        validate: true,
        label: 'Accept and send',
      },
      {
        type: 'cancel',
        validate: false,
        label: 'Cancel',
        classModifiers: 'secondary',
      },
    ],
  },
};
