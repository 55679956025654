import { CONTENT_TYPE, DATE_FORMATS, MOVEMENT_MODES, SUB_MODES } from '../../constants';
import { DIRECTION } from '../constants';

import { replaceInvalidValues } from '../../String/stringUtil';
import { toId, toIssuingHub, toOperation, toTargetReceiptTeamSubmission } from './common';
import DateTimeUtil from '../../Datetime/datetimeUtil';
import JourneyUtil from '../../Journey/journeyUtil';
import toCredibilityChecksSubmission from '../../../routes/Task/Uplift/TaskDetails/helper/toCredibilityChecksSubmission';
import CredibilityChecksUtil from '../../Person/credibilityChecksUtil';
import TargetDocumentUtil from '../../Document/Target/targetDocumentUtil';

const formatHazardousValue = (hazardous) => {
  switch (hazardous) {
    case 'YES': {
      return true;
    }
    case 'NO': {
      return false;
    }
    default: {
      return null;
    }
  }
};

const toPersonEntity = (person, taskPerson) => {
  const taskPnc = CredibilityChecksUtil.getPnc(taskPerson);

  const personEntity = {
    pnc: {
      id: taskPnc?.id,
      pncCategories: person?.pncCategories,
      pncDetails: person?.pncDetails,
      pncFound: person?.pncFound,
      pncOutcome: person?.pncOutcome,
    },
    entityId: taskPerson?.entityId,
    otherChecks: null,
  };

  return toCredibilityChecksSubmission(personEntity);
};

const getDirectionFromPayload = (formData) => {
  const mode = formData.mode;
  if (mode === MOVEMENT_MODES.AIR_PASSENGER) {
    return formData?.movement?.direction;
  }
  return formData?.direction;
};

const toInformFreightAndTouristSubmission = (formData) => {
  const informFreightAndTourist = formData?.informFreightAndTourist;
  return informFreightAndTourist && informFreightAndTourist?.length;
};

const toControlStrategiesSubmission = (formData) => {
  return {
    controlStrategies: formData?.controlStrategies || [],
  };
};

const toNominalChecksSubmission = (formData) => {
  return {
    nominalChecks: formData?.nominalChecks?.map((nominalCheck) => {
      return {
        type: nominalCheck.nominalType.value,
        checks: nominalCheck.systemsCheck,
        comments: nominalCheck.comments,
      };
    }) || [],
  };
};

const toRisksSubmission = (formData) => {
  if (!formData) {
    return undefined;
  }

  return {
    risks: {
      targetingIndicators: formData?.targetingIndicators,
      selector: {
        category: replaceInvalidValues(formData?.category?.value),
        groupReference: replaceInvalidValues(formData?.groupReference),
      },
    },
  };
};

const toSubmittingUser = (formData, keycloak) => {
  const form = formData?.form;
  if (!form) {
    return undefined;
  }

  return {
    submittingUser: {
      name: replaceInvalidValues(`${keycloak.tokenParsed.given_name} ${keycloak.tokenParsed.family_name}`),
      email: replaceInvalidValues(form?.submittedBy),
    },
  };
};

const toReasoningSubmission = (formData) => {
  let whySelected;

  if ([
    MOVEMENT_MODES.ACCOMPANIED_FREIGHT,
    MOVEMENT_MODES.UNACCOMPANIED_FREIGHT,
    MOVEMENT_MODES.TOURIST,
  ].includes(formData.mode)) {
    whySelected = formData?.preArrival?.whySelected;
  } else {
    whySelected = formData?.whySelected;
  }

  if (!whySelected) {
    return undefined;
  }

  return {
    selectionReasoning: replaceInvalidValues(whySelected),
  };
};

const toRemarksSubmission = (formData) => {
  if (!formData?.remarks) {
    return undefined;
  }

  return {
    remarks: formData?.remarks,
  };
};

const toPublicInterestImmunity = (formData) => {
  return {
    publicInterestImmunity: formData?.publicInterestImmunity === 'Yes',
  };
};

const toAdditionalInformation = (formData) => {
  if (!formData?.additionalInformation) {
    return undefined;
  }

  return {
    additionalInformation: replaceInvalidValues(formData?.additionalInformation),
  };
};
const toDocumentSubmission = (document, taskDocument = null, dateFormat = 'DD-MM-YYYY') => {
  return {
    number: document?.documentNumber || taskDocument?.number,
    expiry: replaceInvalidValues(
      document?.documentExpiry
        ? DateTimeUtil.convertToUTC(document?.documentExpiry, dateFormat, DATE_FORMATS.UTC)
        : DateTimeUtil.format(taskDocument?.expiry, DATE_FORMATS.UTC),
    ),
    issueCountry: {
      isoCode: taskDocument?.countryOfIssue || document?.issueCountry?.isoCode,
      name: TargetDocumentUtil.format.country(taskDocument?.countryOfIssue) || TargetDocumentUtil.format.country(document?.issueCountry?.isoCode),
    },
  };
};

const toPersonSubmission = (person, meta, taskData, index = null) => {
  if (!person) {
    return undefined;
  }
  const taskPerson = taskData?.movement?.person;
  const dateFormat = 'DD-MM-YYYY';
  return {
    name: person?.name,
    dateOfBirth: replaceInvalidValues(DateTimeUtil.convertToUTC(person?.dateOfBirth, dateFormat, DATE_FORMATS.UTC)),
    gender: person?.sex,
    watchList: {
      added: person?.watchList === 'YES',
      referenceNumber: replaceInvalidValues(person?.individualReferenceNumber),
    },
    document: toDocumentSubmission(person?.document, taskPerson?.document, dateFormat),
    documents: index
      ? taskPerson?.documents?.map((_, docIndex) => toDocumentSubmission(null, taskData?.movement?.otherPersons[index].documents[docIndex])) || []
      : taskPerson?.documents?.map((_, docIndex) => toDocumentSubmission(null, taskPerson?.documents[docIndex])) || [],
    nationality: person?.nationality,
    ...(person?.photograph && {
      additionalContents: [{
        type: CONTENT_TYPE.PHOTO,
        url: (index || index === 0) ? replaceInvalidValues(meta?.documents[index + 1]?.url) : replaceInvalidValues(meta?.documents[0]?.url),
        filename: person?.photograph?.name,
        source: null, // We are unable to get the source so can be set to null
        approximateDateTaken: person?.photographDate && replaceInvalidValues(DateTimeUtil.convertToUTC(person?.photographDate, dateFormat, DATE_FORMATS.UTC)),
      }],
    }),
    credibilityChecks: toPersonEntity(person, taskPerson),
    passengerStatus: person?.passengerStatus,
    seatNumber: replaceInvalidValues(person?.seatNumber),
    baggage: {
      numberOfCheckedBags: replaceInvalidValues(person?.baggage?.bagCount),
      weight: replaceInvalidValues(person?.baggage?.weight),
      tags: replaceInvalidValues(person?.baggage?.tags),
    },
  };
};

const getDatetime = (formData, attr) => {
  switch (formData?.mode) {
    case MOVEMENT_MODES.TOURIST:
    case MOVEMENT_MODES.UNACCOMPANIED_FREIGHT:
    case MOVEMENT_MODES.ACCOMPANIED_FREIGHT: {
      return {
        date: formData?.interception?.[attr]?.date,
        time: formData?.interception?.[attr]?.time,
      };
    }
    case MOVEMENT_MODES.AIR_PASSENGER: {
      return {
        date: formData?.movement?.[attr]?.date,
        time: formData?.movement?.[attr]?.time,
      };
    }
    default: {
      return {
        date: undefined,
        time: undefined,
      };
    }
  }
};

const toDatetime = (formData, attr) => {
  const { date: arrivalDate, time: arrivalTime } = getDatetime(formData, attr);
  if (!arrivalDate || !arrivalTime) {
    return null;
  }
  return DateTimeUtil.convertToUTC(
    `${arrivalDate} ${arrivalTime}`, 'DD-MM-YYYY HH:mm', DATE_FORMATS.UTC,
  );
};

const toMovementSubmission = (taskData, formData) => {
  if (!taskData && !formData) {
    return undefined;
  }

  const journey = JourneyUtil.get(taskData);
  const arrivalDateTime = toDatetime(formData, 'arrival');
  const departureDateTime = toDatetime(formData, 'departure');
  return {
    movement: {
      id: taskData?.movement?.id,
      mode: taskData?.movement?.mode,
      refDataMode: formData?.refDataMode,
      journey: {
        id: journey?.id,
        direction: getDirectionFromPayload(formData),
        arrivalTime: arrivalDateTime,
        departureTime: departureDateTime,
        route: formData?.movement?.route,
        arrival: {
          ...formData?.movement?.arrival,
          country: journey?.arrival?.country,
          location: journey?.arrival?.location,
          date: arrivalDateTime,
          time: arrivalDateTime,
        },
        departure: {
          ...formData?.movement?.departure,
          country: journey?.departure?.country,
          location: journey?.departure?.location,
          date: departureDateTime,
          time: departureDateTime,
        },
      },
      person: toPersonSubmission(formData?.person, formData?.meta, taskData),
      otherPersons: formData?.otherPersons?.map((person, index) => toPersonSubmission(person, formData?.meta, taskData, index)) || [],
      baggage: {
        numberOfCheckedBags: formData?.person?.baggage?.bagCount,
        weight: formData?.person?.baggage?.weight,
        tags: formData?.person?.baggage?.tags,
      },
      vessel: {
        operator: replaceInvalidValues(formData?.interception?.shippingCompany),
        name: replaceInvalidValues(formData?.interception?.vesselName),
      },
      vehicle: formData?.vehicle,
      trailer: formData?.trailer,
      goods: {
        description: formData?.goods?.load,
        weight: formData?.goods?.manifestedWeight,
        grossWeight: formData?.goods?.grossWeight,
        netWeight: formData?.goods?.netWeight,
        destination: formData?.goods?.destinationCountry,
        hazardous: formatHazardousValue(formData?.goods?.hazardous),
      },
      account: {
        name: formData?.preArrival?.accountName,
        number: formData?.preArrival?.accountNumber,
      },
      haulier: {
        name: replaceInvalidValues(formData?.goods?.haulier?.name),
        address: {
          line1: replaceInvalidValues(formData?.goods?.haulier?.line1),
          line2: replaceInvalidValues(formData?.goods?.haulier?.line2),
          line3: replaceInvalidValues(formData?.goods?.haulier?.line3),
          city: replaceInvalidValues(formData?.goods?.haulier?.city),
          postcode: replaceInvalidValues(formData?.goods?.haulier?.postcode),
          country: replaceInvalidValues(formData?.goods?.haulier?.country),
        },
      },
      consignee: {
        name: replaceInvalidValues(formData?.goods?.consignee?.name),
        address: {
          line1: replaceInvalidValues(formData?.goods?.consignee?.line1),
          line2: replaceInvalidValues(formData?.goods?.consignee?.line2),
          line3: replaceInvalidValues(formData?.goods?.consignee?.line3),
          city: replaceInvalidValues(formData?.goods?.consignee?.city),
          postcode: replaceInvalidValues(formData?.goods?.consignee?.postcode),
          country: replaceInvalidValues(formData?.goods?.consignee?.country),
        },
      },
      consignor: {
        name: replaceInvalidValues(formData?.goods?.consignor?.name),
        address: {
          line1: replaceInvalidValues(formData?.goods?.consignor?.line1),
          line2: replaceInvalidValues(formData?.goods?.consignor?.line2),
          line3: replaceInvalidValues(formData?.goods?.consignor?.line3),
          city: replaceInvalidValues(formData?.goods?.consignor?.city),
          postcode: replaceInvalidValues(formData?.goods?.consignor?.postcode),
          country: replaceInvalidValues(formData?.goods?.consignor?.country),
        },
      },
    },
  };
};

const toPortSubmission = (formData) => {
  const direction = getDirectionFromPayload(formData);
  if (direction === DIRECTION.INBOUND && formData?.arrivalPort) {
    return {
      eventPort: formData?.arrivalPort,
    };
  }
  if (direction === DIRECTION.INBOUND && formData?.movement?.arrivalPort) {
    return {
      eventPort: formData?.movement?.arrivalPort,
    };
  }
  if (direction === DIRECTION.OUTBOUND && formData?.departurePort) {
    return {
      eventPort: formData?.departurePort,
    };
  }
  if (direction === DIRECTION.OUTBOUND && formData?.movement?.departurePort) {
    return {
      eventPort: formData?.movement?.departurePort,
    };
  }
  return undefined;
};

const toRecipients = (formData, taskData) => {
  if (taskData?.mode === SUB_MODES.RORO) {
    return {
      recipients: {
        type: 'RORO',
        group: toTargetReceiptTeamSubmission(formData),
        informFreightAndTourist: toInformFreightAndTouristSubmission(formData),
      },
    };
  }

  if ([SUB_MODES.COMMODITIES, SUB_MODES.IDP, null].includes(taskData?.mode)) {
    return {
      recipients: {
        type: 'AIRPAX_COMMODITIES',
        groups: [toTargetReceiptTeamSubmission(formData)],
      },
    };
  }

  return null;
};

const toTisSubmissionPayload = (taskData, formData, keycloak) => {
  let submissionPayload = {};
  if (formData) {
    submissionPayload = {
      ...toId(formData),
      ...toPortSubmission(formData),
      ...toMovementSubmission(taskData, formData),
      ...toRemarksSubmission(formData),
      ...toReasoningSubmission(formData),
      ...toRisksSubmission(formData),
      ...toPublicInterestImmunity(formData),
      ...toControlStrategiesSubmission(formData),
      ...toNominalChecksSubmission(formData),
      ...toIssuingHub(formData),
      ...toRecipients(formData, taskData),
      ...toOperation(formData),
      ...toSubmittingUser(formData, keycloak),
      ...toAdditionalInformation(formData),
      form: {
        ...formData?.form,
      },
    };
  }
  return submissionPayload;
};

export default toTisSubmissionPayload;
