import { useState } from 'react';
import { useInterval } from 'react-use';

import { NumberUtil } from '../index';

const toTimeValues = (countDown) => {
  const days = NumberUtil.zeroIfRequired(Math.floor(countDown / (1000 * 60 * 60 * 24)));
  const hours = NumberUtil.zeroIfRequired(Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
  const minutes = NumberUtil.zeroIfRequired(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)));
  const seconds = NumberUtil.zeroIfRequired(Math.floor((countDown % (1000 * 60)) / 1000));
  return [days, hours, minutes, seconds];
};

const useTimeCountDown = (countDownDate, delay) => {
  const countDownDateUTC = new Date(countDownDate).getTime();

  const [countDown, setCountDown] = useState(countDownDateUTC - Date.now() + new Date().getTimezoneOffset() * 60 * 1000);

  useInterval(() => {
    setCountDown(countDownDateUTC - Date.now() + new Date().getTimezoneOffset() * 60 * 1000);
  }, delay);

  return toTimeValues(countDown);
};

export default useTimeCountDown;
