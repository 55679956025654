import React, { useState, useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import axios from 'axios';
import config from '../../../utils/config';
import AxiosRequests from '../../../api/axiosRequests';

// Contexts
import { usePnrAccess } from '../../../context/PnrAccessContext';

// Components / Pages
import Layout from '../../../components/Layout/Layout';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import LoginJustification from './login/LoginJustification';

// Styling
import './PnrAccessRequest.scss';
import { usePermission } from '../../../context/PermissionContext';

const toPNRJustificationURL = (redirectUrl) => {
  return `${config.justificationUIUrl}access?redirect=${encodeURIComponent(redirectUrl)}&step=1`;
};

const PnrAccessRequest = ({ children }) => {
  const source = axios.CancelToken.source();
  const justificationUIUrl = toPNRJustificationURL(window.location.href);
  const { trackPageView } = useMatomo();
  const { hasPnrAccess } = usePermission();
  const { userAccess, fetchUserAccess } = usePnrAccess();
  const [loading, setLoading] = useState(!hasPnrAccess);
  const [displayForm, setDisplayForm] = useState(null);
  const [completedForm, setCompletedForm] = useState(false);

  const handleException = () => {
    /**
     * If an error occurs then we won't block the user from seeing tasks,
     * they just won't have access to PNR data until relogging
     */
    setDisplayForm(false);
    setCompletedForm(true);
    setLoading(false);
  };

  useEffect(() => {
    trackPageView();
    fetchUserAccess().catch(handleException);

    return () => {
      AxiosRequests.cancel(source);
    };
  }, []);

  useEffect(() => {
    if (!userAccess) {
      return () => {};
    }

    /**
     * We only want to initialise the form state when the user access has been
     * loaded so that we don't display the form again in new tabs
     */
    if (displayForm === null) {
      setDisplayForm(!userAccess.submitted);
      setLoading(false);
    }

    if (config.justificationUIEnabled && userAccess.submitted) {
      setDisplayForm(false);
    }

    /**
     * If the justification UI is disabled then we need to wait for the user
     * to complete our form to close it. If we don't have the completedForm
     * state then the confirmation page will be skipped
     */
    if (!config.justificationUIEnabled && completedForm && userAccess.submitted) {
      setDisplayForm(false);
    }

    /**
     * If the token's session id or roles changes then we want to recheck if
     * the user needs PNR access
     */
    if (hasPnrAccess && !userAccess.submitted) {
      setDisplayForm(true);
      setCompletedForm(false);
    }
  }, [hasPnrAccess, userAccess, completedForm]);

  if (loading) {
    return (
      <Layout>
        <LoadingSpinner />
      </Layout>
    );
  }

  if (displayForm) {
    return (
      <>
        {config.justificationUIEnabled && window.location.replace(justificationUIUrl)}
        {!config.justificationUIEnabled && <LoginJustification onFinish={() => setCompletedForm(true)} />}
      </>
    );
  }

  return children;
};

export default PnrAccessRequest;
