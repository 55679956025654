import React from 'react';

// Component(s)
import CommonButton from '../../../../../components/Buttons/CommonButton';

// Hook
import { useFilterToggle } from '../../../../../context/FilterToggleContext';

const ShowHideFiltersButton = () => {
  const { toggleState, setToggleState } = useFilterToggle();

  const toClassName = () => {
    return 'govuk-button link-button show-hide-filters-button';
  };

  const toChildrenName = () => {
    return toggleState === 'hide' ? 'Show filters' : 'Hide filters';
  };

  const handleShowHideFilters = async () => {
    setToggleState();
  };

  return (
    <CommonButton
      commonProps={{
        className: toClassName(),
        id: 'show-hide-filters-button',
        onClick: handleShowHideFilters,
        children: toChildrenName(),
        label: toChildrenName(),
      }}
    />
  );
};

export default ShowHideFiltersButton;
