import { FORM_ACTIONS } from '../../utils/constants';

export default {
  id: 'relist-task',
  version: '1.0.0',
  name: 'relist-task',
  title: 'Relist Task',
  type: 'form',
  components: [],
  pages: [{
    id: 'relist-task',
    name: 'relist-task',
    components: [
      {
        type: 'heading',
        size: 'l',
        content: 'Relist the task',
      },
      {
        type: 'html',
        tagName: 'p',
        content: 'Enter a reason to relist the task.',
      },
      {
        id: 'addReason',
        fieldId: 'addReason',
        label: 'Add reason',
        'aria-label': 'add reason',
        type: 'textarea',
        rows: 3,
        required: true,
        showCharacterCount: true,
        custom_errors: [
          {
            type: 'required',
            message: 'Enter a reason to reopen the task',
          },
        ],
      },
      {
        type: 'html',
        tagName: 'p',
        className: 'font--grey',
        content: 'The reason will be saved in the task activity.',
      },
      {
        type: 'html',
        tagName: 'p',
        className: 'font--grey',
        content: 'The task will return to "in progress" where you will be able to action it.',
      },
    ],
    actions: [
      {
        type: FORM_ACTIONS.SUBMIT,
        validate: true,
        label: 'Continue',
      },
      {
        type: FORM_ACTIONS.CANCEL,
        label: 'Cancel',
        classModifiers: 'secondary',
      },
    ],
  }],
};
