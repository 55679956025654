import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Config
import config from '../../../../../../../utils/config';
import { CONTENT_TYPE, DATE_FORMATS, TASK_DETAIL_PAGE_PHOTO_LAST_ACTION } from '../../../../../../../utils/constants';

// Context(s)
import { useKeycloak } from '../../../../../../../context/Keycloak';
import { useTask } from '../../../../../../../context/TaskContext';

// Components
import DetailModal from './DetailModal';
import UpliftedForm from '../forms/UpliftedForm';

// Helpers
import addAdditionalContent from '../../../helper/addAdditionalContent';

// Hook(s)
import { useAxiosInstance } from '../../../../../../../utils/Axios/axiosInstance';
import useSetTabTitle, { TAB_TITLES } from '../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import BaggageUtil from '../../../../../../../utils/Baggage/baggageUtil';
import BookingUtil from '../../../../../../../utils/Booking/bookingUtil';
import { DateTimeUtil } from '../../../../../../../utils';
import DocumentUploader from '../../../../../../../utils/Form/ReactForm/Uplift/documentUploader';
import PersonUtil from '../../../../../../../utils/Person/Uplift/personUtil';

// Form
import form from '../../../../../../../forms/uplift/addDetail';

// Styling
import './AddDetail.scss';

const AddDetail = ({ onClose, offsetTop, ...props }) => {
  const keycloak = useKeycloak();
  const uploadApiClient = useAxiosInstance(keycloak, config.fileUploadApiUrl);
  const { task, setTask, originalTask, setOriginalTask, additionalContent, setAdditionalContent, taskId } = useTask();
  const booking = BookingUtil.get(task);
  const baggage = BaggageUtil.get(task);
  const persons = PersonUtil.allPersons(task);

  const [submissionPayload, setSubmissionPayload] = useState(null);

  useSetTabTitle(TAB_TITLES.ADD_TASK_DETAIL);

  const insertIf = (condition, elements) => {
    if (condition) {
      if (Array.isArray(elements)) {
        return elements;
      }
      return [elements];
    }

    return [];
  };

  const toAllEntityContent = (entities, data, dateTimeStamp) => {
    const toEntityPosition = (entityName) => {
      const nameParts = entityName.split('_');
      return {
        passengerIndex: Number(nameParts[1]),
        documentIndex: Number(nameParts[3]),
        documentNumber: nameParts[4],
      };
    };

    const toAllContent = () => {
      return entities.map((entity) => {
        const entityPosition = toEntityPosition(entity);
        if (entity === 'BOOKING') {
          return [{
            type: 'BOOKING',
            content: {
              id: uuidv4(),
              type: CONTENT_TYPE.TEXT,
              content: data.bookingDetails,
            },
            timestamp: dateTimeStamp,
          }];
        }

        if (entity === 'BAGGAGE') {
          return [{
            type: 'BAGGAGE',
            content: {
              id: uuidv4(),
              type: CONTENT_TYPE.TEXT,
              content: data.baggageDetails,
            },
            timestamp: dateTimeStamp,
          }];
        }

        if (entity === 'VOYAGE') {
          return [{
            type: 'VOYAGE',
            content: {
              id: uuidv4(),
              type: CONTENT_TYPE.TEXT,
              content: data.voyageDetails,
            },
            timestamp: dateTimeStamp,
          }];
        }

        if (entity === `PASSENGER_${entityPosition.passengerIndex}`) {
          return [
            ...insertIf(data?.[`passenger${entityPosition.passengerIndex}Details`], {
              type: 'PASSENGER',
              entityId: entityPosition.passengerIndex > 1
                ? task.movement.otherPersons[entityPosition.passengerIndex - 2]?.entityId
                : task.movement.person?.entityId,
              content: {
                id: uuidv4(),
                type: CONTENT_TYPE.TEXT,
                content: data?.[`passenger${entityPosition.passengerIndex}Details`],
              },
              timestamp: dateTimeStamp,
            }),
          ];
        }

        if (entity === `PASSENGER_${entityPosition.passengerIndex}_PHOTO`) {
          return [
            ...insertIf(data?.[`passenger${entityPosition.passengerIndex}Photo`]?.photo, {
              type: 'PASSENGER',
              entityId: entityPosition.passengerIndex > 1
                ? task.movement.otherPersons[entityPosition.passengerIndex - 2]?.entityId
                : task.movement.person?.entityId,
              content: {
                id: uuidv4(),
                type: CONTENT_TYPE.PHOTO,
                file: data?.[`passenger${entityPosition.passengerIndex}Photo`]?.photo,
                filename: data?.[`passenger${entityPosition.passengerIndex}Photo`]?.photo?.name,
                source: data?.[`passenger${entityPosition.passengerIndex}Photo`]?.source,
                approximateDateTaken: data?.[`passenger${entityPosition.passengerIndex}Photo`]?.approximateDate,
              },
              timestamp: dateTimeStamp,
            }),
          ];
        }

        if (entity.startsWith(`PASSENGER_${entityPosition.passengerIndex}_DOCUMENT`)) {
          return [
            ...insertIf(data?.[`passenger${entityPosition.passengerIndex}DocumentDetails_${entityPosition.documentIndex}_${entityPosition.documentNumber}`], {
              type: 'DOCUMENT',
              entityId: entityPosition.passengerIndex > 1
                ? task.movement.otherPersons[entityPosition.passengerIndex - 2]?.documents?.[entityPosition.documentIndex - 1]?.entityId
                : task.movement.person?.documents?.[entityPosition.documentIndex - 1]?.entityId,
              content: {
                id: uuidv4(),
                type: CONTENT_TYPE.TEXT,
                content: data?.[`passenger${entityPosition.passengerIndex}DocumentDetails_${entityPosition.documentIndex}_${entityPosition.documentNumber}`],
              },
              timestamp: dateTimeStamp,
            }),
          ];
        }

        return [];
      })
        .flat(1);
    };

    return [
      ...toAllContent(),
    ];
  };

  const toSubmissionPayload = (data, dateTimeStamp) => {
    return toAllEntityContent(data.addDetail, data, dateTimeStamp);
  };

  const uploadPhotoDocument = async (photoSubmission) => {
    const uploadedToUrl = await DocumentUploader.upload(uploadApiClient, taskId, photoSubmission.content.file.file);
    if (uploadedToUrl) {
      photoSubmission.content.url = uploadedToUrl;
      delete photoSubmission.content.file;
    }
    return photoSubmission;
  };

  const uploadDocuments = async (payload) => {
    const photoSubmissions = payload.filter((submission) => submission.content.type === CONTENT_TYPE.PHOTO);
    if (photoSubmissions.length > 0) {
      return Promise.all(photoSubmissions.map((submission) => uploadPhotoDocument(submission)));
    }
    return Promise.resolve();
  };

  const checkIfOriginalTaskSet = () => !!originalTask;

  const onSubmit = async ({ data }) => {
    // #19821 Set the last action property;
    localStorage.setItem(TASK_DETAIL_PAGE_PHOTO_LAST_ACTION, 'added');

    const dateTimeStamp = DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC);
    const payload = toSubmissionPayload(data, dateTimeStamp);
    await uploadDocuments(payload);

    setSubmissionPayload(payload);
  };

  useEffect(() => {
    if (submissionPayload && submissionPayload.length > 0) {
      const dateTimeStamp = DateTimeUtil.format(new Date().toISOString(), DATE_FORMATS.UTC);
      const user = {
        active: true,
        firstName: keycloak.tokenParsed.given_name,
        lastName: keycloak.tokenParsed.family_name,
        email: keycloak.tokenParsed.email,
      };

      if (!checkIfOriginalTaskSet()) {
        setOriginalTask(JSON.parse(JSON.stringify(task)));
      }

      setTask(addAdditionalContent(task, submissionPayload, user, dateTimeStamp, !additionalContent || additionalContent.length === 0));
      setAdditionalContent(additionalContent ? [...additionalContent, ...submissionPayload] : submissionPayload);

      onClose();
    }
  }, [submissionPayload, onClose]);

  return (
    <div {...props}>
      <UpliftedForm
        form={form(booking, baggage, persons)}
        onSubmit={onSubmit}
        onCancel={onClose}
        offsetTop={offsetTop}
        uploadDocument={false}
        cancelModal={<DetailModal onProceed={onClose} />}
      />
    </div>
  );
};

AddDetail.propTypes = {
  onClose: PropTypes.func,
  offsetTop: PropTypes.number.isRequired,
};

AddDetail.defaultProps = {
  onClose: () => {},
};

export default AddDetail;
