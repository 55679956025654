// Global import(s)
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import useFetchUser from '../utils/Hooks/useFetchUser';

const UserContext = createContext({
  userGroupMember: null,
  userRole: null,
  userGroups: {},
  isRCCUOfficer: false,
  isHigherOfficer: false,
  isInterceptingOfficer: false,
});

const UserProvider = ({ children }) => {
  const value = useFetchUser();

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const useUser = () => useContext(UserContext);

export { UserProvider, UserContext, useUser };
