import { FormGroup, Heading, TextArea } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';

const ReferralReason = ({ componentErrors, onChange, formData }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.REFERRAL_REASON}
      label={<Heading id="referral-reason-heading" size="m">Reason for referral</Heading>}
      error={componentErrors?.[COMPONENT_IDS.REFERRAL_REASON]?.message}
    >
      <p>Please do not include any PNR information in the reason for referral</p>
      <TextArea
        id={COMPONENT_IDS.REFERRAL_REASON}
        fieldId={COMPONENT_IDS.REFERRAL_REASON}
        rows="5"
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.REFERRAL_REASON]}
      />
    </FormGroup>
  );
};

ReferralReason.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

ReferralReason.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(ReferralReason);
