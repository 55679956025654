import React, { useMemo, useState, createContext, useContext, useRef, useEffect } from 'react';
import AxiosRequests from '../api/axiosRequests';
import { useAxiosInstance } from '../utils/Axios/axiosInstance';
import { useKeycloak } from './Keycloak';
import config from '../utils/config';

const SESSION_STATUS = {
  UNREGISTERED: 'UNREGISTERED',
  REGISTERED: 'REGISTERED',
};

const ACCESS_STATUS = {
  PENDING: 'PENDING',
  FETCHED: 'FETCHED',
};

const PnrAccessContext = createContext({});

const PnrAccessProvider = ({ children }) => {
  const [userAccess, setUserAccess] = useState(null);
  const [sessionStatus, setSessionStatus] = useState(SESSION_STATUS.UNREGISTERED);
  const [accessStatus, setAccessStatus] = useState(ACCESS_STATUS.PENDING);
  const keycloak = useKeycloak();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const sessionId = useRef(keycloak.sessionId);

  const updateUserAccess = (newUserAccess) => {
    setUserAccess(newUserAccess);
    setAccessStatus(ACCESS_STATUS.FETCHED);
  };

  const registerSession = async () => {
    const response = await AxiosRequests.registerSession(apiClient);
    setSessionStatus(SESSION_STATUS.REGISTERED);
    return response.data;
  };

  const registerIfRequired = async (newUserAccess) => {
    if (sessionStatus === SESSION_STATUS.REGISTERED) {
      return newUserAccess;
    }

    if (!config.justificationUIEnabled || newUserAccess.submitted) {
      setSessionStatus(SESSION_STATUS.REGISTERED);
      return newUserAccess;
    }

    return registerSession();
  };

  const fetchUserAccess = async () => {
    const response = await AxiosRequests.pnrAccessPrivilege(apiClient);
    return response.data;
  };

  const fetchUserAccessIfPending = async () => {
    if (accessStatus === ACCESS_STATUS.PENDING) {
      return fetchUserAccess();
    }
    return userAccess;
  };

  const updateUserAccessIfPending = () => {
    return fetchUserAccessIfPending().then(registerIfRequired).then(updateUserAccess);
  };

  const requestPnrAccess = async (payload) => {
    const response = await AxiosRequests.initialPnrForm(apiClient, payload);
    return response.data;
  };

  const requestPnrAccessAndThenRegisterIfRequired = (payload) => {
    return requestPnrAccess(payload).then(registerIfRequired).then(updateUserAccess);
  };

  useEffect(() => {
    const newSessionId = keycloak.sessionId;

    /**
     * This will probably never happen because in order to have a new session
     * the user will probably have to come from the login page which is on a
     * different site but for completeness sake I've refreshed all this
     */
    if (sessionId.current !== newSessionId) {
      setSessionStatus(SESSION_STATUS.UNREGISTERED);
      setAccessStatus(ACCESS_STATUS.PENDING);
      setUserAccess({});
      updateUserAccessIfPending();
    }

    sessionId.current = keycloak.sessionId;
  }, [keycloak]);

  const value = useMemo(() => ({
    userAccess,
    fetchUserAccess: updateUserAccessIfPending,
    requestPnrAccess: requestPnrAccessAndThenRegisterIfRequired,
  }), [
    userAccess,
    updateUserAccessIfPending,
    requestPnrAccessAndThenRegisterIfRequired,
  ]);

  return (
    <PnrAccessContext.Provider value={value}>{children}</PnrAccessContext.Provider>
  );
};

const usePnrAccess = () => useContext(PnrAccessContext);

export { PnrAccessContext, PnrAccessProvider, usePnrAccess };
