import React from 'react';
import classNames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';

const ExtendedListRowElement = ({ value, index, id, mobile, activeClass, onResizeFactory }) => {
  const { ref } = useResizeDetector({
    handleHeight: false,
    refreshMode: 'throttle',
    refreshRate: 50,
    onResize: onResizeFactory(index),
  });

  if (Array.isArray(value)) {
    if (value[1] === undefined || value[1] === null) {
      return <div ref={ref} key={`${id}-${value}`}>&nbsp;</div>;
    }
    return (
      <div ref={ref} className="content" key={`${id}-${value}`}>
        <span
          className={classNames(
            'font--grey label govuk-extended-summary-list__key',
            mobile && 'ho-secondary-text-xs',
          )}
        >
          {value[0]}
        </span>
        <span
          className={classNames(
            'information govuk-extended-summary-list__value',
            mobile && 'ho-body-s',
          )}
        >
          {value[1]}
        </span>
      </div>
    );
  }
  return (
    <div
      ref={ref}
      className="short-content"
      key={`${id}-${value}`}
    >
      <span
        className={classNames(
          'govuk-extended-summary-list__value',
          activeClass !== 'mobile' && mobile && 'ho-body-s',
        )}
      >
        {value}
      </span>
    </div>
  );
};

export default ExtendedListRowElement;
