import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import LinkButton from '../../../../../../../../components/Buttons/LinkButton';
import Table from '../../../../../../../../components/Table/Table';

// Styling
import './CommoditiesTargetRecipients.scss';

const CommoditiesTargetRecipients = ({ formData, onChangeRecipient, onRemoveRecipient, onChangeBorderRecipient, onRemoveBorderRecipient, recipientType, ...props }) => {
  const TABLE_HEADINGS = ['Recipient', 'Telephone', 'Actions'];

  if ((!Array.isArray(formData?.targetRecipients) || !formData?.targetRecipients?.length) && (!Array.isArray(formData?.borderOfficers) || !formData?.borderOfficers?.length)) {
    return null;
  }

  const replaceSpacesWithDashes = (recipientName) => {
    return recipientName.split(' ').join('-');
  };
  const numOfficers = formData?.borderOfficers?.length ? formData.borderOfficers?.length : 0;
  const numTeams = formData?.targetRecipients?.length ? formData.targetRecipients?.length : 0;

  const getTargetRecipents = () => {
    if (!Array.isArray(formData?.targetRecipients) || !formData?.targetRecipients?.length) {
      return [];
    }

    return formData.targetRecipients.map((targetRecipient) => [
      targetRecipient.customName,
      targetRecipient.telephone,
      <LinkButton
        key={targetRecipient.customName}
        id={`change-recipient-${replaceSpacesWithDashes(targetRecipient.customName)}`}
        className="govuk-link"
        data-recipientname={targetRecipient.customName}
        onClick={(e) => {
          const { dataset } = e.target;
          onChangeRecipient(dataset.recipientname);
        }}
      >
        Change
      </LinkButton>,
      ((numOfficers + numTeams) > 1 ? (
        <LinkButton
          id={`remove-recipient-${replaceSpacesWithDashes(targetRecipient.customName)}`}
          className="govuk-link"
          data-recipientname={targetRecipient.customName}
          onClick={(e) => {
            const { dataset } = e.target;
            onRemoveRecipient(dataset.recipientname);
          }}
        >
          Remove
        </LinkButton>
      ) : null),
    ].filter((item) => !!item));
  };

  const getBorderOfficers = () => {
    if (!Array.isArray(formData?.borderOfficers) || !formData?.borderOfficers?.length) {
      return [];
    }

    return formData.borderOfficers.map((borderOfficer) => [
      borderOfficer.email,
      <LinkButton
        key={borderOfficer.email}
        id={`change-officer-${borderOfficer.email}`}
        className="govuk-link"
        data-borderOfficer={borderOfficer.email}
        onClick={(e) => {
          const { dataset } = e.target;
          onChangeBorderRecipient(dataset.borderofficer);
        }}
      >
        Change
      </LinkButton>,
      ((numOfficers + numTeams) > 1 ? (
        <LinkButton
          id={`remove-officer-${replaceSpacesWithDashes(borderOfficer.email)}`}
          className="govuk-link"
          data-borderOfficer={borderOfficer.email}
          onClick={(e) => {
            const { dataset } = e.target;
            onRemoveBorderRecipient(dataset.borderofficer);
          }}
        >
          Remove
        </LinkButton>
      ) : null),
    ].filter((item) => !!item));
  };

  const toRows = () => {
    return (
      getTargetRecipents().concat(getBorderOfficers())
    );
  };

  return (
    <Table
      {...props}
      className="target-recipients"
      headings={TABLE_HEADINGS}
      rows={toRows()}
    />
  );
};

CommoditiesTargetRecipients.propTypes = {
  formData: PropTypes.shape({}),
  onChangeRecipient: PropTypes.func.isRequired,
  onRemoveRecipient: PropTypes.func.isRequired,
  onChangeBorderRecipient: PropTypes.func.isRequired,
  onRemoveBorderRecipient: PropTypes.func.isRequired,
  recipientType: PropTypes.string.isRequired,
};

CommoditiesTargetRecipients.defaultProps = {
  formData: null,
};

export default CommoditiesTargetRecipients;
