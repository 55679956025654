import PropTypes from 'prop-types';
import React from 'react';

// Component(s)
import Document from './Document';

const Documents = ({ documents, journey, movementId, movementIds }) => {
  if (!documents?.length) {
    return null;
  }

  return (
    <>
      {documents.map((document, index) => (
        <Document key={document.id} document={document} journey={journey} movementId={movementId} movementIds={movementIds} positionedAt={index} />
      ))}
    </>
  );
};

Documents.propTypes = {
  movementId: PropTypes.string.isRequired,
  movementIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  journey: PropTypes.shape({}).isRequired,
};
export default Documents;
