// Global import(s)
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { useAxiosInstance } from '../Axios/axiosInstance';
import { usePermission } from '../../context/PermissionContext';
import { useView } from '../../context/ViewContext';
import { useTasks } from '../../context/TasksContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Utils
import { useTabs } from '../../context/TabContext';
import { toGetTaskFilters } from '../../routes/Task/TaskList/helper/adaptFilters';

// Services
import AxiosRequests from '../../api/axiosRequests';

const useFetchTaskFilterCounts = (filters, enabled) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { view } = useView();
  const { setTaskFilterCounts } = useTasks();

  const getFilterCounts = async (payload) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return {};
    }
    return AxiosRequests.getTaskFilterCounts(apiClient, toGetTaskFilters(payload.filterParams, view));
  };

  const { data: taskFilterCounts } = useQuery({
    queryKey: [QUERY_KEYS.TASKS_FILTER_COUNTS, filters],
    queryFn: () => getFilterCounts(filters),
    refetchInterval: config.taskList.refreshInterval,
    initialData: {},
    enabled: enabled && !!filters.filterParams?.taskModes?.length,
  });

  useEffect(() => {
    if (taskFilterCounts) {
      setTaskFilterCounts(taskFilterCounts);
      return () => AxiosRequests.cancel(source);
    }
  }, [taskFilterCounts]);
};

export default useFetchTaskFilterCounts;
