const deleteAdditionalContentToTask = (task, id, deleteActivity) => {
  const deleteTaskActivity = (taskActivities, content) => {
    const mostRecentIndex = taskActivities.findIndex((item) => item.content === content);
    if (mostRecentIndex !== -1) {
      const updatedActivities = [
        ...taskActivities.slice(0, mostRecentIndex),
        ...taskActivities.slice(mostRecentIndex + 1),
      ];
      return updatedActivities;
    }
    return taskActivities;
  };

  if (task.movement.booking?.additionalContent.find((content) => content.id === id)) {
    task.movement.booking.additionalContent = task.movement.booking.additionalContent.filter((content) => content.id !== id);
  } else if (task.movement.baggage?.additionalContent.find((content) => content.id === id)) {
    task.movement.baggage.additionalContent = task.movement.baggage.additionalContent.filter((content) => content.id !== id);
  } else if (task.movement.journey?.additionalContent.find((content) => content.id === id)) {
    task.movement.journey.additionalContent = task.movement.journey.additionalContent.filter((content) => content.id !== id);
  }
  const personsToUpdate = [task.movement.person, ...task.movement.otherPersons];
  personsToUpdate.forEach((person) => {
    if (person?.additionalContent?.find((content) => content.id === id)) {
      person.additionalContent = person.additionalContent.filter((content) => content.id !== id);
    }

    if (person.documents) {
      person.documents.forEach((document, index) => {
        if (document.additionalContent?.find((content) => content.id === id)) {
          person.documents[index].additionalContent = document.additionalContent.filter((content) => content.id !== id);
        }
      });
    }
  });

  task.movement.person = personsToUpdate[0];
  task.movement.otherPersons = personsToUpdate.slice(1);

  if (deleteActivity) {
    task.activities = deleteTaskActivity(task.activities, 'additional details added');
  }

  return task;
};

const deleteAdditionalContent = (task, id, deleteActivity) => {
  const updatedTask = deleteAdditionalContentToTask(task, id, deleteActivity);
  return updatedTask;
};

export default deleteAdditionalContent;
