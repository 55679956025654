// Config(s)
import { PNC_OUTCOMES } from '../../../../../utils/TargetInformation/constants';
import { STRINGS } from '../../../../../utils/constants';
import { SYSTEM_CHECKS } from '../components/shared/checks/constants';

const toPncResult = (pnc) => {
  if (!pnc || !pnc?.pncOutcome) {
    return null;
  }

  const { pncOutcome, pncDetails, pncFound, pncCategories } = pnc;

  return {
    outcome: pncOutcome,
    ...(pncOutcome === PNC_OUTCOMES.POTENTIALLY_RELEVANT_INFORMATION_FOUND && {
      found: {
        pncId: pncFound?.pncId,
        safetyInformation: pncFound?.safetyInformation === STRINGS.YES,
      },
      ...(pncFound.safetyInformation === STRINGS.YES ? {
        details: {
          types: pncDetails?.types || [],
          staffConcerns: pncDetails?.types.includes('STAFF_CONCERN') ? pncDetails?.staffConcerns : null,
          poiConcerns: pncDetails?.types.includes('POI_CONCERN') ? pncDetails?.poiConcerns : null,
        },
      } : null),
      categories: {
        drugsPossession: pncCategories?.drugsPossession?.length ? pncCategories?.possessionClass : [],
        drugsSupply: pncCategories?.drugsSupply?.length ? pncCategories?.supplyClass : [],
        drugsTrafficking: pncCategories?.drugsTrafficking?.length ? pncCategories?.traffickingClass : [],
        weaponsPossession: pncCategories?.weaponsPossession?.length ? pncCategories?.weaponsPossessionOpts : [],
        weaponsSupply: pncCategories?.weaponsSupply?.length ? pncCategories?.weaponsSupplyOptions : [],
        goodsSmuggling: pncCategories?.goodsSmuggling?.length ? pncCategories?.goodsSmugglingOpts : [],
        peopleSmuggling: pncCategories?.peopleSmuggling?.length ? pncCategories?.peopleSmugglingOpts : [],
      },
    }),
  };
};

const toPncCredibilityCheck = ({ pnc }) => {
  return {
    id: pnc?.id || null,
    system: 'PNC',
    type: 'PNC',
    result: toPncResult(pnc),
  };
};

const isTrace = (trace) => {
  return trace === 'TRACE';
};

const toBooleanTrace = (trace) => {
  return trace === 'TRACE';
};

const toResult = (trace, comment, isSelectedCheck) => {
  if (!trace || !isSelectedCheck) {
    return null;
  }

  const toComment = () => {
    if (!comment) {
      return null;
    }
    return comment;
  };

  return {
    trace: toBooleanTrace(trace),
    comment: isTrace(trace) ? toComment() : null,
  };
};

const toSystemResult = (otherSystem, isSelectedCheck) => {
  delete otherSystem.dataId;
  if (!otherSystem || !isSelectedCheck || !Object.keys(otherSystem).length) {
    return null;
  }

  return {
    systemChecked: otherSystem?.systemChecked || null,
    ...toResult(otherSystem?.trace, otherSystem?.comment, isSelectedCheck),
  };
};

const toOtherSystems = (otherSystems, isSelectedCheck) => {
  return otherSystems
    .map((otherSystem) => ({
      id: otherSystem?.id || null,
      system: SYSTEM_CHECKS.OTHER,
      type: SYSTEM_CHECKS.OTHER,
      result: toSystemResult(otherSystem, isSelectedCheck),
    }));
};

const toTrace = (system, result, isSelectedCheck) => {
  const { id, trace, comment } = result;
  return {
    id,
    system,
    type: 'TRACE',
    result: toResult(trace, comment, isSelectedCheck),
  };
};

const toCheck = (system, check, isSelectedCheck) => {
  if (system === SYSTEM_CHECKS.OTHER) {
    return toOtherSystems(check, isSelectedCheck);
  }

  return toTrace(
    system,
    {
      id: check?.id || null,
      trace: check?.trace,
      comment: check?.comment,
    },
    isSelectedCheck);
};

const toOtherChecks = ({ otherChecks }) => {
  const { checks } = otherChecks;

  return Object.keys(otherChecks)
    .filter((system) => system in SYSTEM_CHECKS)
    .map((system) => toCheck(system, otherChecks[system], checks?.includes(system)))
    .flat()
    .filter((item) => !!item);
};

const toCredibilityChecksSubmission = (entityData) => {
  return {
    entityId: entityData.entityId,
    notNecessary: !!entityData?.otherChecks?.checks?.includes(STRINGS.FORM.NOT_REQUIRED),
    notNecessaryReason: entityData?.otherChecks?.checks?.includes(STRINGS.FORM.NOT_REQUIRED) ? entityData?.otherChecks?.notRequired : null,
    credibilityChecks: [
      ...(entityData?.pnc ? [toPncCredibilityCheck(entityData)] : []),
      ...(entityData?.otherChecks ? toOtherChecks(entityData) : []),
    ].filter((item) => !!item),
  };
};

export default toCredibilityChecksSubmission;
