import { AnimatePresence, motion } from 'framer-motion';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Heading } from '@ukhomeoffice/cop-react-components';
import { isEqual, omit } from 'lodash';
import qs from 'qs';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Config(s)
import DEFAULTS, { TASKS_TABS } from './constants';
import { LOCAL_STORAGE_KEYS, PATHS, TAB_ID, VIEW } from '../../../utils/constants';

// Context(s)
import { useErrorAlert } from '../../../context/ErrorAlertContext';
import { useFilterToggle } from '../../../context/FilterToggleContext';
import { useKeycloak } from '../../../context/Keycloak';
import { useNotification } from '../../../context/NotificationContext';
import { usePermission } from '../../../context/PermissionContext';
import { useTabs } from '../../../context/TabContext';
import { useTasks } from '../../../context/TasksContext';
import { useView } from '../../../context/ViewContext';

// Components/Pages
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';
import DismissButton from '../../../components/Buttons/DismissButton';
import DismissFilteredButton from '../Uplift/TaskDetails/components/shared/buttons/DismissFilteredButton';
import Filter from '../../../components/Filter/Filter';
import InternalHeader from './components/shared/InternalHeader';
import Tabs from '../../../components/Tabs/Tabs';
import TasksTab from './components/shared/TasksTab';

// Animations
import taskCardAnimations from './helper/taskCardAnimations';

// Hooks
import useFetchSingleTask from '../../../utils/Hooks/useFetchSingleTask';
import useFetchTaskIds from '../../../utils/Hooks/useFetchTaskIds';
import useFetchTasks from '../../../utils/Hooks/useFetchTasks';
import useFetchTaskFilterCounts from '../../../utils/Hooks/useFetchTaskFilterCounts';
import useScreenSize from '../../../utils/Hooks/useScreenSize';
import useTaskListPage from '../Uplift/TaskList/hooks/useTaskListPage.hooks';

// Utils
import { shouldIncludeIssuedByUsingId, toFilterPayload, toTasksPayload, toFilterCounts } from './helper/adaptFilters';
import { CommonUtil, StorageUtil } from '../../../utils';
import { savePreviousLocation } from '../../../components/Utils/lastKnownLocationCheck';
import { toMainContentClassname } from '../../../components/Utils/Component/toClassName';

const InternalTaskListPage = () => {
  const keycloak = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = keycloak.tokenParsed.email;
  const { trackPageView } = useMatomo();
  const { setErrors } = useErrorAlert();
  const { canUpdateTask } = usePermission();
  const { toggleState, setToggleState } = useFilterToggle();
  const { isDismissTab, isNewTab, selectedTab, setSelectedTab, tabIndex, setTabIndex } = useTabs();
  const { view } = useView();
  const { selectedForDismissal, setSelectedForDismissal, tasks, taskIds, setIsSelecting, isLoadingTasks, taskFilterCounts } = useTasks();
  const { setNotification } = useNotification();
  const { isNineteenTwentyOrMorePixels } = useScreenSize();
  const [appliedFilters, setAppliedFilters] = useState(StorageUtil.get(DEFAULTS[view].filters.key) || DEFAULTS[view].filters.default || {});
  const [activePage, setActivePage] = useState(0);
  const isFiltersApplied = useMemo(() => {
    return !isEqual(appliedFilters, DEFAULTS[view].filters.default);
  }, [appliedFilters]);

  // PAGINATION SETTINGS
  const index = activePage - 1;
  const itemsPerPage = DEFAULTS[view].maxResults;
  const offset = index * itemsPerPage < 0 ? 0 : index * itemsPerPage;

  const filterParams = useMemo(() => toTasksPayload({
    appliedFilters,
    tabIndex,
    view,
    itemsPerPage,
    offset,
    currentUser,
  }), [appliedFilters, tabIndex, view, itemsPerPage, offset, currentUser]);

  useFetchTaskFilterCounts(filterParams, [VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.COMMODITIES, VIEW.CTBP, VIEW.IDP, VIEW.OSDT, VIEW.GENERAL_AVIATION, VIEW.RORO].includes(view));

  const targetingTeams = taskFilterCounts.targetingTeams?.map((targetingTeam) => { return targetingTeam.value; });
  const rulesMatched = taskFilterCounts.rules?.map((rule) => { return rule.value; });
  const taskCountsByStatus = Object.fromEntries((taskFilterCounts.taskStatuses?.map((taskStatus) => ([taskStatus.value, taskStatus.count]))) || []);
  const filterCounts = toFilterCounts(taskFilterCounts, filterParams);

  useFetchTasks(filterParams);
  useFetchSingleTask(filterParams, (offset + itemsPerPage));
  useFetchTaskIds(filterParams.filterParams, (isDismissTab || (isNewTab && isFiltersApplied)));
  useTaskListPage();

  const handleAssigneeFilter = async (tabId) => {
    setAppliedFilters({
      ...appliedFilters,
      assignees: ((tabId === TAB_ID.IN_PROGRESS)
        && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
      issuedBy: (shouldIncludeIssuedByUsingId(tabId)
        && CommonUtil.hasAssignee(DEFAULTS[view].filters.key)) ? [currentUser] : [],
    });
  };

  const restorePreFilterPage = () => {
    // Return the user back to the page they were on prior to applying the filters.
    const pageBeforeSearch = StorageUtil.get(`${LOCAL_STORAGE_KEYS.FILTER_PAGE_TRACKER}-${view}`);
    if (pageBeforeSearch) {
      setActivePage(pageBeforeSearch);
      navigate(pageBeforeSearch === 1
        ? location.pathname : `${location.pathname}?page=${pageBeforeSearch}`);
      StorageUtil.remove(`${LOCAL_STORAGE_KEYS.FILTER_PAGE_TRACKER}-${view}`);
    }
  };

  const storePreFilterPage = () => {
    // Store the page number the user is currently on
    if (StorageUtil.get(`${LOCAL_STORAGE_KEYS.FILTER_PAGE_TRACKER}-${view}`) === null) {
      // If activePage is 0, nothing is stored. The 0 value is handled by save function within storage util
      StorageUtil.add(`${LOCAL_STORAGE_KEYS.FILTER_PAGE_TRACKER}-${view}`, activePage);
      navigate(location.pathname);
    }
    setActivePage(1);
  };

  // Used for when filters are applied via the filter component
  const handleOnApplyFilter = async (payload) => {
    payload = toFilterPayload(payload, DEFAULTS[view].filters.default, selectedTab);
    StorageUtil.add(DEFAULTS[view].filters.key, JSON.stringify(payload));
    storePreFilterPage();
    setAppliedFilters(payload);
  };

  const handleOnFilterReset = async (e) => {
    e.preventDefault();
    localStorage.removeItem(DEFAULTS[view].filters.key);
    restorePreFilterPage();
    setAppliedFilters(DEFAULTS[view].filters.default);
  };

  const enableSelectionIfRequired = (tabId) => {
    setIsSelecting(tabId === TAB_ID.SELECTED_FOR_DISMISSAL);
  };

  const clearSearchUrl = () => {
    if (location.search) {
      location.search = null;
      window.history.pushState({}, null, location.pathname);
    }
  };

  const onTabClick = async (item, itemIndex) => {
    if (selectedTab !== item.id) {
      setTabIndex(itemIndex);
      setSelectedTab(item.id);
      clearSearchUrl();
      setNotification(null);
      setSelectedForDismissal([]);
      setIsSelecting(item.id === TAB_ID.SELECTED_FOR_DISMISSAL);
      await handleAssigneeFilter(item.id);
      enableSelectionIfRequired(item.id);
    }
  };

  const isFiltersWithoutClaimantTheSame = () => {
    return isEqual(omit(appliedFilters, ['claimedByMe']), DEFAULTS[view].filters.default);
  };

  const showDismissFilteredTasksButton = () => {
    return isNewTab && !isFiltersWithoutClaimantTheSame() && selectedForDismissal.length && tasks.length && !isLoadingTasks && canUpdateTask;
  };

  const getContentWidthClass = () => {
    return toggleState === 'hide'
      ? 'task-list-grid-column govuk-grid-column govuk-grid-column-full-page-width'
      : 'task-list-grid-column govuk-grid-column-three-quarters';
  };

  const getTaskListWidthClass = () => {
    return toggleState === 'hide'
      ? 'roro-task-list-full'
      : 'roro-task-list';
  };

  useEffect(() => {
    trackPageView();
    savePreviousLocation();
    setToggleState('hide');
  }, []);

  useEffect(() => {
    const { page } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const newActivePage = parseInt(page || 1, 10);
    setActivePage(newActivePage);
  }, [location.search]);

  useEffect(() => {
    enableSelectionIfRequired(TASKS_TABS[tabIndex].id);
  }, [tabIndex]);

  useEffect(() => {
    if (isFiltersApplied && isNewTab) {
      setSelectedForDismissal(taskIds);
    } else {
      setSelectedForDismissal([]);
    }
  }, [isFiltersApplied, taskIds]);

  return (
    <div className={getTaskListWidthClass()}>
      <div className="govuk-!-margin-bottom-8">
        <InternalHeader
          headerLabel={DEFAULTS[view].headers.title}
          links={DEFAULTS[view].headers.links}
          size="xl"
        />
      </div>
      <div className="govuk-grid-row itlp-grid-row">
        <div className={`govuk-grid-column${toMainContentClassname(isNineteenTwentyOrMorePixels)} govuk-grid-column-trimmed govuk-grid-column-nbtc`}>
          <AnimatePresence mode="sync">
            {toggleState === 'show' && (
              <motion.section
                key="filters-left-side-tab"
                className="govuk-grid-column-one-quarter"
                {...taskCardAnimations}
              >
                <Filter
                  currentUser={currentUser}
                  data={appliedFilters}
                  filterCounts={filterCounts}
                  customOptions={{
                    rules: rulesMatched?.map((rule) => ({
                      value: rule,
                      label: rule,
                    })),
                    targetingTeams: targetingTeams?.map((targetingTeam) => ({
                      value: targetingTeam,
                      label: targetingTeam,
                    })),
                  }}
                  onApply={handleOnApplyFilter}
                  onClear={handleOnFilterReset}
                />
              </motion.section>
            )}
          </AnimatePresence>
          <section className={getContentWidthClass()}>
            <Tabs
              title="Title"
              id="tasks"
              onTabClick={onTabClick}
              items={TASKS_TABS.map((tab) => {
                return {
                  id: tab.id,
                  label: `${tab.label} (${taskCountsByStatus?.[tab.taskStatus] ?? 0})`,
                  panel: (
                    <>
                      <div id="task-list-header-container" className="task-list-header-container">
                        <ComponentWrapper show={isNewTab && !isFiltersWithoutClaimantTheSame()}>
                          <Heading size="l" aria-label="filtered tasks" useHodsTag>Filtered tasks</Heading>
                        </ComponentWrapper>
                        <ComponentWrapper show={(isNewTab && isFiltersWithoutClaimantTheSame()) || !isNewTab}>
                          <Heading size="l" aria-label={tab.heading} useHodsTag>{tab.heading}</Heading>
                        </ComponentWrapper>
                        <ComponentWrapper show={isDismissTab && tasks.length && canUpdateTask}>
                          <DismissButton
                            aria-label="dismiss selected tasks"
                            label="Dismiss selected tasks"
                            onClick={() => {
                              if (!selectedForDismissal.length) {
                                setErrors([{
                                  id: 'dismiss-button',
                                  error: 'You must select a task',
                                }]);
                                return;
                              }
                              navigate(`${DEFAULTS[view].redirectPath}${PATHS.BULK_DISMISSALS}`);
                            }}
                          />
                        </ComponentWrapper>
                        <ComponentWrapper show={showDismissFilteredTasksButton()}>
                          <DismissFilteredButton />
                        </ComponentWrapper>
                      </div>
                      <TasksTab
                        isLoading={isLoadingTasks}
                        tabId={tab.id}
                        itemsPerPage={itemsPerPage}
                        totalNumberOfTasks={taskCountsByStatus?.[TASKS_TABS[tabIndex].taskStatus]}
                        totalPages={Math.ceil(taskCountsByStatus?.[TASKS_TABS[tabIndex].taskStatus] / itemsPerPage)}
                        redirectPath={DEFAULTS[view].redirectPath}
                        activePage={activePage}
                        setActivePage={setActivePage}
                        filtersApplied={isFiltersApplied}
                      />
                    </>
                  ),
                };
              })}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default InternalTaskListPage;
