import React from 'react';
import { useLocation } from 'react-router-dom';

// Context
import { FullScreenProvider } from '../../../context/FullScreenContext';
import { useView } from '../../../context/ViewContext';

// Components
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import Footer from './Footer/Footer';
import Header from '../Header/Header';
import Scrim from './Scrim';
import PhaseBanner from '../../PhaseBanner/PhaseBanner';

// Hook(s)
import useScreenSize from '../../../utils/Hooks/useScreenSize';

// Util(s)
import { toContainerClassname, toPathClassname } from '../../Utils/Component/toClassName';

// Styles
import './Layout.scss';

const Layout = ({ beforeMain, children }) => {
  const location = useLocation();
  const { isNineteenTwentyOrMorePixels } = useScreenSize();
  const { isTargetsPath, isUseFullScreen } = useView();

  return (
    <>
      <Header />
      <div className="hods-width-wrapper">
        {isTargetsPath && <PhaseBanner />}
        <div className={`${toContainerClassname(isNineteenTwentyOrMorePixels, isUseFullScreen)} ${toPathClassname(location.pathname)}`}>
          <Scrim id="page-scrim-outer" />
          {beforeMain}
          <main className="hods-main-wrapper" id="main-content" role="main">
            <ErrorBoundary>
              <FullScreenProvider>
                {children}
              </FullScreenProvider>
            </ErrorBoundary>
          </main>
        </div>
      </div>
      <Footer classBlock={toPathClassname(location.pathname)} />
    </>
  );
};

export default Layout;
