// Global import(s)
import { BackToTop } from '@ukhomeoffice/cop-react-components';
import React from 'react';

// Component(s)
import ApplicationSpinner from '../../../components/LoadingSpinner/ApplicationSpinner';
import OfficialWarning from './components/header/OfficialWarning';
import TargetDetails from './components/target/TargetDetails';
import TargetHeader from './components/header/TargetHeader';
import TargetInformation from './components/movement/information/TargetInformation';
import Warnings from './components/banners/Warnings';

// Styling
import './TargetSheetPage.scss';

const TargetSheet = ({
  mode,
  isAllowedToViewTargetSheet,
  isSettingView,
  informationSheet,
}) => {
  if (isSettingView) {
    return <ApplicationSpinner />;
  }

  if (!isAllowedToViewTargetSheet) {
    return <p>You are not authorised to view this target.</p>;
  }

  return (
    <div id="target-sheet" className="target-sheet-page">
      <OfficialWarning position="top" taskdetails />
      <TargetHeader
        canRecordOutcome={false}
        onRecordOutcome={() => {}}
        mode={mode}
        informationSheet={informationSheet}
      />
      <Warnings informationSheet={informationSheet} />
      <TargetDetails mode={mode} informationSheet={informationSheet} />
      <div className="govuk-grid-row">
        <div className="govuk-grid-column-full">
          <TargetInformation informationSheet={informationSheet} />
        </div>
      </div>
      <BackToTop />
      <OfficialWarning position="bottom" />
    </div>
  );
};

export default TargetSheet;
