// Global import(s)
import { Button, Heading } from '@ukhomeoffice/cop-react-components';
import classNames from 'classnames';
import { withCount } from 'pluralise';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Config(s)
import { GROUP_MEMBER, VIEW } from '../../../utils/constants';

// Context(s)
import { usePermission } from '../../../context/PermissionContext';
import { useTabs } from '../../../context/TabContext';
import { useUser } from '../../../context/UserContext';
import { useView } from '../../../context/ViewContext';

// Component(s)
import ApplicationSpinner from '../../../components/LoadingSpinner/ApplicationSpinner';
import ComponentWrapper from '../../../components/ComponentWrapper/ComponentWrapper';
import Filter from '../../../components/Filter/Filter';
import MovementModeSelector from './components/mode/MovementModeSelector';
import SortOrderSelector from './components/sort/SortOrderSelector';
import Tabs from '../../../components/Tabs/Tabs';
import TargetsTab from './TargetsTab';

// Hook(s)
import useSetTabTitle, { TAB_TITLES } from '../../../utils/Hooks/useSetTabTitle';
import useTargetsList from './hooks/useTargetsList.hooks';

// Utils(s)
import { TargetCommonUtil } from '../../../utils';
import { savePreviousLocation } from '../../../components/Utils/lastKnownLocationCheck';

// Styling
import './TargetsListPage.scss';

const TargetsListPage = () => {
  const location = useLocation();
  const { DEFAULTS } = useTabs();
  const { canReadTarget } = usePermission();
  const { isSettingView, view } = useView();
  const { userRole, userGroupMember, isInterceptingOfficer } = useUser();
  const isRoRoView = view === VIEW.RORO_IO;

  useSetTabTitle(TAB_TITLES.TARGETS_LIST_PAGE);

  const {
    targets,
    totalTargets,
    targetsTabCounts,
    groupCounts,
    groups,
    isLoadingTargets,
    isLoadingGroupCounts,
    isLoadingTabCounts,
    itemsPerPage,
    activePage,
    setActivePage,
    showSortParams,
    sortParamsOrder,
    currentUser,
    appliedFilters,
    showFilters,
    filtersRef,
    onShowOrHideFilters,
    onSortOrderChange,
    onModeChange,
    onTabClick,
    onApplyFilter,
    onClearFilter,
  } = useTargetsList();

  useEffect(() => {
    savePreviousLocation();
  }, []);

  if (isSettingView) {
    return <ApplicationSpinner />;
  }

  if (!TargetCommonUtil.targetListAuthorisationCheck(canReadTarget, userGroupMember, location, userRole, isInterceptingOfficer)) {
    return <p>You are not authorised to view these targets.</p>;
  }

  return (
    <div className="targets">
      <ComponentWrapper show={(userGroupMember === GROUP_MEMBER.BOTH) || (userGroupMember === GROUP_MEMBER.RORO && isInterceptingOfficer)}>
        <MovementModeSelector view={view} onChange={onModeChange} userRole={userRole} userGroupMember={userGroupMember} isInterceptingOfficer={isInterceptingOfficer} />
      </ComponentWrapper>
      <Heading size="xl" caption="All targets" useHodsTag>
        {withCount(totalTargets, '% target', '% targets', '% targets')}
      </Heading>

      <div className="govuk-grid-row">
        <div className="govuk-grid-column-one-quarter">
          <Button
            className="govuk-!-margin-bottom-1"
            classModifiers={showFilters ? 'secondary' : 'primary'}
            id="cop-filters-visibility-control"
            onClick={onShowOrHideFilters}
          >
            {showFilters ? 'Hide filters' : 'Show filters'}
          </Button>
          <Filter
            currentUser={currentUser}
            data={appliedFilters}
            groupCounts={groupCounts}
            customOptions={{}}
            onApply={onApplyFilter}
            onClear={onClearFilter}
            groups={groups}
            ref={filtersRef}
          />
        </div>
        <div className="govuk-grid-column-three-quarters">
          <div className="cop-target-tasks-container">
            <Tabs
              id="targets-tab"
              title="Contents"
              onTabClick={onTabClick}
              items={DEFAULTS[view]?.tabs?.map((tab, index) => {
                return {
                  id: tab.id,
                  label: tab.displayCount ? `${tab.label} (${targetsTabCounts?.[index]?.statusCounts?.total || 0})` : tab.label,
                  panel: (
                    <>
                      <div className={classNames('govuk-grid-row', 'tab-header')}>
                        <div className="govuk-grid-column-two-thirds">
                          <ComponentWrapper show={isRoRoView}>
                            <Heading size="l" useHodsTag>{tab.heading}</Heading>
                          </ComponentWrapper>
                          <ComponentWrapper show={!isRoRoView}>
                            <div className="ho-heading-m">
                              {tab.heading}
                            </div>
                          </ComponentWrapper>
                        </div>
                        <ComponentWrapper show={showSortParams && !isLoadingTargets && !isLoadingGroupCounts && !isLoadingTabCounts}>
                          <div className="govuk-grid-column-one-third">
                            <SortOrderSelector sortParamsOrder={sortParamsOrder} onChange={onSortOrderChange} />
                          </div>
                        </ComponentWrapper>
                      </div>
                      <TargetsTab
                        isLoading={isLoadingTargets && isLoadingGroupCounts && isLoadingTabCounts}
                        targets={targets}
                        totalTargets={totalTargets}
                        itemsPerPage={itemsPerPage}
                        activePage={activePage}
                        setActivePage={setActivePage}
                      />
                    </>
                  ),
                };
              })}
              setActivePage={setActivePage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TargetsListPage;
