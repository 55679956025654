import React from 'react';

import { useView } from '../../../../../../context/ViewContext';

import { VIEW } from '../../../../../../utils/constants';

import AirpaxTargetSheet from '../../../../../Target/TargetSheetPage/TargetSheet';
import TargetSheet from '../TargetSheet';

const toTargetSheetTab = (targetSheet, isWithdrawnByTargeter = false) => {
  const { view } = useView();
  if (!targetSheet) {
    return null;
  }

  const isAirPaxView = () => {
    return [VIEW.AIRPAX_IO, VIEW.AIRPAX_HO, VIEW.AIRPAX_RCCU, VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.COMMODITIES, VIEW.OSDT, VIEW.CTBP, VIEW.IDP].includes(view);
  };

  if (isAirPaxView()) {
    return {
      id: 'tab-target-sheet',
      label: isWithdrawnByTargeter ? 'Withdrawn target sheet' : 'Target sheet',
      panel: <AirpaxTargetSheet
        mode={targetSheet.informationSheet.movement.mode}
        isAllowedToViewTargetSheet
        isSettingView={false}
        informationSheet={targetSheet.informationSheet}
      />,
    };
  }

  return {
    id: 'tab-target-sheet',
    label: isWithdrawnByTargeter ? 'Withdrawn target sheet' : 'Target sheet',
    panel: <TargetSheet targetSheet={targetSheet} />,
  };
};

export default toTargetSheetTab;
