// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

// Config
import { SUMMARY_LIST_TYPES } from '../../../../../../utils/constants';

const SummaryListRow = ({ id, label, link, mobile, type, value }) => {
  const constructListRowLink = () => {
    if (link !== null && value !== 0) {
      return <a href={link}>{value}</a>;
    }
    if (Array.isArray(value)) {
      return value.reduce((acc, curr) => {
        const s = curr
          .toLowerCase()
          .split('_')
          .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
          .join(' ');
        acc += s;
        acc += '\n';
        return acc;
      }, '');
    }
    return value;
  };

  return (
    <dl
      className={classNames(
        'govuk-summary-list__row',
        `govuk-summary-list__row_${type}`,
      )}
      id={`${id}-list__row`}
      key={label}
    >
      <dt
        className={classNames(
          'govuk-summary-list__key',
          mobile && 'mobile-key',
          mobile && 'font--grey',
          type !== SUMMARY_LIST_TYPES.VERTICAL_SMALL && mobile && 'ho-heading-xs',
          type === SUMMARY_LIST_TYPES.VERTICAL_SMALL && mobile && 'ho-secondary-text-xs font--100',
        )}
        id={`${id}-label`}
      >
        {label}
      </dt>
      <dd
        className={classNames(
          'govuk-summary-list__value accordion_item',
          type === SUMMARY_LIST_TYPES.VERTICAL_SMALL && mobile && 'ho-body-s',
        )}
        id={`${id}-value`}
      >
        {constructListRowLink()}
      </dd>
    </dl>
  );
};

SummaryListRow.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  mobile: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

SummaryListRow.defaultProps = {
  link: null,
  mobile: false,
  type: 'horizontal',
  value: null,
};

export default memo(SummaryListRow);
