import { Heading, Tag } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import PropTypes from 'prop-types';

// Config(s)
import { TAGS } from '../../../../../../../../../utils/constants';

// Components
import ComponentWrapper from '../../../../../../../../../components/ComponentWrapper/ComponentWrapper';
import Details from '../../../generics/Details';
import Document from './Document';
import HeaderModule from '../../../generics/HeaderModule';
import LabelValuePair from '../../../generics/LabelValuePair';
import ValueField from '../../../generics/ValueField';

// Util(s)
import CommonUtil from '../../../../../../../../../utils/Common/Uplift/commonUtil';
import DocumentUtil from '../../../../../../../../../utils/Document/Uplift/documentUtil';
import JourneyUtil from '../../../../../../../../../utils/Journey/Uplift/journeyUtil';
import PersonUtil from '../../../../../../../../../utils/Person/Uplift/personUtil';
import { toAgeAtTravel, toDateOfBirth } from '../helper/common';

// Styling
import './Person.scss';

const Person = ({ person, journey, positionedAt }) => {
  const adjustedPositionedAt = positionedAt + 1;
  const documents = DocumentUtil.getDocs(person);
  const fullName = PersonUtil.formattedName(PersonUtil.firstname(person))(PersonUtil.lastname(person, true));
  const iso2NationalityCode = PersonUtil.nationality(person);
  const formattedNationality = CommonUtil.format.nationality(iso2NationalityCode);
  const iso3NationalityCode = CommonUtil.iso3Code(iso2NationalityCode);
  const departureTime = JourneyUtil.departureTime(journey);
  const gender = PersonUtil.gender(person);
  const formattedGender = PersonUtil.format.gender(gender);
  const dateOfBirth = PersonUtil.dob(person);

  if (!person) {
    return null;
  }

  return (
    <div id={`person-${adjustedPositionedAt}-module`} className="govuk-grid-column-full">
      <HeaderModule
        id="person-details"
        items={[
          {
            content: (
              <div key="1" className="grid-item">
                <Heading id={`person-${adjustedPositionedAt}-header`} size="m">{person.roleLabel}</Heading>
              </div>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={fullName}>
                <div className="grid-item grid-item-span-2">
                  <ValueField id={`person-${adjustedPositionedAt}-fullname`} value={fullName} />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={person.ofInterest}>
                <div className="grid-item grid-item-span-2">
                  <Tag
                    id={`person-${adjustedPositionedAt}-of-interest`}
                    className="tag tag--blue"
                    text={TAGS.PERSON_OF_INTEREST}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      <Details
        items={[
          {
            content: (
              <ComponentWrapper key="1" show={iso2NationalityCode && formattedNationality}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-nationality`}
                    label="Nationality"
                    value={`${formattedNationality} - ${iso3NationalityCode}`}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="2" show={gender}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-gender`}
                    label="Gender"
                    value={formattedGender}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="3" show={dateOfBirth}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-dob`}
                    label="Date of birth"
                    value={toDateOfBirth(dateOfBirth)}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
          {
            content: (
              <ComponentWrapper key="4" show={dateOfBirth && departureTime}>
                <div className="grid-item">
                  <LabelValuePair
                    id={`person-${adjustedPositionedAt}-age`}
                    label="Age at travel"
                    value={toAgeAtTravel(dateOfBirth, departureTime)}
                  />
                </div>
              </ComponentWrapper>
            ),
          },
        ]}
      />
      {documents.map((document, index) => {
        return (
          <Document
            document={document}
            journey={journey}
            key={`document-${index}`}
            positionedAt={adjustedPositionedAt}
          />
        );
      })}
    </div>
  );
};

Person.propTypes = {
  positionedAt: PropTypes.number.isRequired,
  person: PropTypes.shape({
    roleLabel: PropTypes.string,
  }),
  journey: PropTypes.shape({}).isRequired,
};

Person.defaultProps = {
  person: null,
};

export default Person;
