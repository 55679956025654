// Global import(s)
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

// Config(s)
import config from '../../../../../../../utils/config';

// Context
import { useFullScreen } from '../../../../../../../context/FullScreenContext';
import { useTask } from '../../../../../../../context/TaskContext';
import { useView } from '../../../../../../../context/ViewContext';

// Component
import ComponentWrapper from '../../../../../../../components/ComponentWrapper/ComponentWrapper';
import LinkButton from '../../../../../../../components/Buttons/LinkButton';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import PersonDetailFullScreen from './PersonDetailFullScreen';

// Hook(s)
import useFetchImage from '../../../../../../../utils/Hooks/useFetchImage';

// Util
import { isMobilePortrait } from '../../../../../../../utils/TargetSheet/targetSheetUtil';

// Styling
import './Photo.scss';

const Photo = ({ personPosition, photoContent, isPersonOfInterest }) => {
  const { url, isLoading } = useFetchImage(photoContent?.url);
  const { width } = useWindowSize();
  const { setFullScreen, closeFullScreen } = useFullScreen();
  const { setIsUseFullScreen } = useView();
  const { task } = useTask();

  const [, setIsPhotoDetailOpen] = useState(false);
  const toggleIsPhotoDetailOpen = () => {
    // Just for desktop at the moment
    if (isMobilePortrait(width)) {
      return;
    }

    const onClose = () => {
      toggleIsPhotoDetailOpen();
    };

    setIsPhotoDetailOpen((prev) => {
      const newIsPhotoDetailOpen = !prev;
      if (newIsPhotoDetailOpen) {
        setIsUseFullScreen(true);
        setFullScreen(
          <PersonDetailFullScreen
            onClose={onClose}
            personPosition={isPersonOfInterest ? 0 : personPosition + 1}
            targetId={task?.targetId}
            href={`#passenger-${personPosition}-photo`}
          />,
        );
      } else {
        setIsUseFullScreen(false);
        closeFullScreen();
      }

      return newIsPhotoDetailOpen;
    });
  };

  useEffect(() => {
    return () => URL.revokeObjectURL(url);
  }, []);

  if (!url) {
    return null;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="target-photo">
      <LinkButton
        onClick={toggleIsPhotoDetailOpen}
      >
        <img
          id={`passenger-${personPosition}-photo`}
          alt={`Taken ${photoContent?.approximateDateTaken}`}
          className="passport-photo hods-file-upload__thumb"
          src={url}
        />
      </LinkButton>
      <ComponentWrapper show={photoContent && config.enableUpliftTargetSheet}>
        <div>
          <div id={`passenger-${personPosition}-photo-date-label`}>
            <span>Date of photo:</span> {photoContent?.approximateDateTaken}
          </div>
        </div>
      </ComponentWrapper>
    </div>
  );
};

Photo.propTypes = {
  personPosition: PropTypes.number,
  photoContent: PropTypes.shape({
    url: PropTypes.string,
    source: PropTypes.string,
    filename: PropTypes.string,
    approximateDateTaken: PropTypes.string,
  }),
  isPersonOfInterest: PropTypes.bool,
};

Photo.defaultProps = {
  personPosition: 0,
  photoContent: null,
  isPersonOfInterest: false,
};

export default memo(Photo);
