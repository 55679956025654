// Global import(s)
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { STRINGS } from '../../../../../../utils/constants';

// Component(s)
import Accordion from '../../../../../../components/Accordion/Accordion';
import PersonTemplate from '../person/roro/PersonTemplate';
import SummaryListRow from '../generics/SummaryListRow';

// Util(s)
import {
  CommonUtil,
  TargetConsigneeUtil,
  TargetConsignorUtil,
  TargetGoodsUtil,
  TargetHaulierUtil,
  TargetPersonUtil,
  TargetTrailerUtil,
  TargetVehicleUtil,
} from '../../../../../../utils';
import { toPncWarningTag } from '../../../../../Task/TaskDetails/components/shared/helper/adaptPncContent';

const RoRoTargetInformation = ({ informationSheet, mode }) => {
  const toConsignmentAndHaulier = () => {
    const goods = TargetGoodsUtil.get(informationSheet);
    const haulier = TargetHaulierUtil.get(informationSheet);
    const consignor = TargetConsignorUtil.get(informationSheet);
    const consignee = TargetConsigneeUtil.get(informationSheet);

    return {
      id: 'consignment-haulier-details',
      heading: 'Consignment and haulier',
      children: (
        <dl className="govuk-summary-list">
          <SummaryListRow
            id="manifested-load"
            label="Manifested Load"
            value={TargetGoodsUtil.description(goods)}
          />
          <SummaryListRow
            id="manifested-weight"
            label="Manifested Weight"
            value={TargetGoodsUtil.weight(goods)}
          />
          <SummaryListRow
            id="gross-weight"
            label="Gross Weight"
            value={TargetGoodsUtil.grossWeight(goods)}
          />
          <SummaryListRow
            id="net-weight"
            label="Net Weight"
            value={TargetGoodsUtil.netWeight(goods)}
          />
          <SummaryListRow
            id="consignor"
            label="Consignor"
            value={TargetConsignorUtil.name(consignor)}
          />
          <SummaryListRow
            id="consignor-address"
            label="Address"
            value={TargetConsignorUtil.address(consignor)}
          />
          <SummaryListRow
            id="hazardous"
            label="Hazardous"
            value={TargetGoodsUtil.format.hazardous(TargetGoodsUtil.hazardous(goods))}
          />
          <SummaryListRow
            id="consignee"
            label="Consignee"
            value={TargetConsigneeUtil.name(consignee)}
          />
          <SummaryListRow
            id="consignee-address"
            label="Address"
            value={TargetConsigneeUtil.address(consignee)}
          />
          <SummaryListRow
            id="haulier"
            label="Haulier"
            value={TargetHaulierUtil.name(haulier)}
          />
          <SummaryListRow
            id="haulier-address"
            label="Address"
            value={TargetHaulierUtil.address(haulier)}
          />
        </dl>
      ),
      expanded: true,
    };
  };

  const toTrailer = () => {
    const trailer = TargetTrailerUtil.get(informationSheet);
    if (!trailer) {
      return null;
    }

    return {
      id: 'trailer-details',
      heading: `Trailer: ${TargetTrailerUtil.registration(trailer) || STRINGS.UNKNOWN}`,
      expanded: true,
      children: (
        <dl className="govuk-summary-list">
          <SummaryListRow
            id="trailer-type"
            label="Trailer type"
            value={TargetTrailerUtil.type(trailer)}
          />
          <SummaryListRow
            id="trailer-registration-nationality"
            label="Trailer registration nationality"
            value={TargetTrailerUtil.nationality(trailer)}
          />
        </dl>
      ),
    };
  };

  const toVehicle = () => {
    const vehicle = TargetVehicleUtil.get(informationSheet);
    if (!vehicle) {
      return null;
    }

    return {
      id: 'vehicle-details',
      heading: `Vehicle: ${TargetVehicleUtil.registration(vehicle) || STRINGS.UNKNOWN}`,
      children: (
        <dl className="govuk-summary-list">
          <SummaryListRow
            id="vehicle-make"
            label="Vehicle make"
            value={TargetVehicleUtil.make(vehicle)}
          />
          <SummaryListRow
            id="vehicle-model"
            label="Vehicle model"
            value={TargetVehicleUtil.model(vehicle)}
          />
          <SummaryListRow
            id="vehicle-colour"
            label="Vehicle colour"
            value={TargetVehicleUtil.colour(vehicle)}
          />
          <SummaryListRow
            id="vehicle-registration-number"
            label="Vehicle registration nationality"
            value={TargetVehicleUtil.nationality(vehicle)}
          />
        </dl>
      ),
      expanded: true,
    };
  };

  const toPassengers = () => {
    const coTravellers = CommonUtil.uniqueId(TargetPersonUtil.others(informationSheet));
    if (!coTravellers.length) {
      return [];
    }

    return coTravellers.map((person, index) => {
      return {
        id: `co-traveller-${index + 1}`,
        heading: `Associated passenger: ${TargetPersonUtil.fullName(person) || STRINGS.UNKNOWN}`,
        summary: (
          <div className="section-right">
            { toPncWarningTag(person) }
          </div>
        ),
        expanded: true,
        children: <PersonTemplate person={person} personPosition={index + 2} mode={mode} />,
      };
    });
  };

  const toMainPerson = () => {
    const person = TargetPersonUtil.person(informationSheet);
    if (!person) {
      return null;
    }

    return {
      id: 'main-person',
      heading: `Driver: ${TargetPersonUtil.fullName(person) || STRINGS.UNKNOWN}`,
      summary: (
        <div className="section-right">
          {toPncWarningTag(person)}
        </div>
      ),
      expanded: true,
      children: <PersonTemplate person={person} mode={mode} />,
    };
  };

  return (
    <div className="govuk-grid-row">
      <div className="govuk-grid-column-two-thirds">
        <Accordion
          className="target-info-accordion"
          id="roro-info-accordion"
          items={[
            toMainPerson(),
            ...toPassengers(),
            toVehicle(),
            toTrailer(),
            toConsignmentAndHaulier(),
          ].filter((item) => !!item)}
        />
      </div>
    </div>
  );
};

RoRoTargetInformation.propTypes = {
  informationSheet: PropTypes.shape({}),
  mode: PropTypes.string.isRequired,
};

RoRoTargetInformation.defaultProps = {
  informationSheet: null,
};

export default memo(RoRoTargetInformation);
