// Global import(s)
import { Label, Select } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { GROUP_DROPDOWN_OPTIONS } from '../../constants';

// Styling
import './MovementModeSelector.scss';

const MovementModeSelector = ({ view, userRole, userGroupMember, isInterceptingOfficer, onChange }) => {
  return (
    <div>
      <Label id="mode" aria-label="Movement mode" />
      <Select
        id="mode"
        fieldId="mode"
        className="mode-selector"
        defaultValue={view}
        options={GROUP_DROPDOWN_OPTIONS.filter((option) => {
          return (
            (option?.show?.role === userRole || option?.show?.enabled)
            && (option?.show.groupMember.includes(userGroupMember) || isInterceptingOfficer)
          );
        })}
        onChange={onChange}
      />
    </div>
  );
};

MovementModeSelector.propTypes = {
  view: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  userGroupMember: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MovementModeSelector;
