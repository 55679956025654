import PropTypes from 'prop-types';
import { Heading } from '@ukhomeoffice/cop-react-components';
import React from 'react';
import { useWindowSize } from 'react-use';

// Config(s)
import { MOVEMENT_MODES } from '../../../../../../utils/constants';

// Context(s)
import { useView } from '../../../../../../context/ViewContext';

// Component
import AirpaxPersonDetails from './airpax/PersonDetails';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';
import GeneralAviationPersonDetails from './general_aviation/PersonDetails';
import PreviousNext from '../generics/PreviousNext';

// Util
import TargetPersonUtil from '../../../../../../utils/Person/Target/targetPersonUtil';

import './PersonsOfInterest.scss';
import { isMobilePortrait } from '../../../../../../utils/TargetSheet/targetSheetUtil';

const PersonsOfInterest = ({ id, mode, persons, preview }) => {
  const { width } = useWindowSize();
  const { isVisibleTargetActionsToolbar } = useView();

  if (persons?.filter(Boolean).length < 1) {
    return null;
  }

  return (
    <div id="persons-of-interest">
      <hr />
      <ComponentWrapper show={persons?.length > 0}>
        <ComponentWrapper show={!isMobilePortrait(width)}>
          <Heading size="l">Persons of interest</Heading>
        </ComponentWrapper>
        <ComponentWrapper show={isMobilePortrait(width)}>
          <h2 className="ho-heading-m">Persons of interest</h2>
        </ComponentWrapper>
      </ComponentWrapper>
      {persons?.map((person, index) => {
        const document = TargetPersonUtil.document(person);

        return (
          <>
            <ComponentWrapper show={!isVisibleTargetActionsToolbar}>
              <PreviousNext
                id="persons-of-interest"
                index={index}
                size={persons.length}
              />
            </ComponentWrapper>
            <ComponentWrapper show={mode === MOVEMENT_MODES.AIR_PASSENGER}>
              <Heading size="s">Person of interest {index + 1}</Heading>
              <AirpaxPersonDetails id={id} person={person} personPosition={index} mode={mode} preview={preview} />
            </ComponentWrapper>
            <ComponentWrapper show={mode === MOVEMENT_MODES.GENERAL_AVIATION}>
              <GeneralAviationPersonDetails
                person={person}
                document={document}
                personPosition={index}
                mode={mode}
              />
            </ComponentWrapper>
          </>
        );
      })}
    </div>
  );
};

PersonsOfInterest.propTypes = {
  id: PropTypes.string,
  mode: PropTypes.string.isRequired,
  persons: PropTypes.shape([]).isRequired,
  preview: PropTypes.bool,
};

PersonsOfInterest.defaultProps = {
  id: '123-456',
  preview: false,
};

export default PersonsOfInterest;
