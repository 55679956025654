import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { DATE_FORMATS } from '../../../../../../utils/constants';

// Component(s)
import AccordionRow from '../../shared/AccordionRow';
import PncCheck from '../../shared/PncCheck';

// Util(s)
import { DateTimeUtil, TargetDocumentUtil, TargetPersonUtil } from '../../../../../../utils';

const PersonTemplate = ({ person, document }) => {
  return (
    <>
      <dl id="person" className="govuk-summary-list">
        <AccordionRow
          label="Has this person been added to Border Crossing?"
          value={TargetPersonUtil.watchList.added(person)}
          id="watchlist"
        />
        <AccordionRow
          label="Border Crossing (IRN) Individual Reference Number"
          value={TargetPersonUtil.watchList.referenceNumber(person)}
          id="watchlist-reference"
        />
        <AccordionRow
          label="Nationality"
          value={TargetPersonUtil.nationality(person)}
          id="nationality"
        />
        <AccordionRow
          label="Date of birth"
          value={DateTimeUtil.format(TargetPersonUtil.dob(person), DATE_FORMATS.SHORT)}
          id="date-of-birth"
        />
        <AccordionRow
          label="Sex"
          value={TargetPersonUtil.formattedGender(person)}
          id="sex"
        />
        <AccordionRow
          label="Travel document type"
          value={TargetDocumentUtil.docTypeDescription(document)}
          id="travel-document-type"
        />
        <AccordionRow
          label="Travel document number"
          value={TargetDocumentUtil.docNumber(document)}
          id="travel-document-number"
        />
        <AccordionRow
          label="Travel document expiry"
          value={DateTimeUtil.format(TargetDocumentUtil.docExpiry(document), DATE_FORMATS.SHORT)}
          id="travel-document-expiry"
        />
      </dl>
      <PncCheck person={person} />
    </>
  );
};

export default PersonTemplate;

PersonTemplate.propTypes = {
  person: PropTypes.shape({}),
  document: PropTypes.shape({}),
};

PersonTemplate.defaultProps = {
  person: null,
  document: null,
};
