import React, { useMemo, useState, createContext, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Config(s)
import TASK_DEFAULTS from '../routes/Task/TaskList/constants';
import TARGET_DEFAULTS from '../routes/Target/TargetsListPage/constants';
import { FILTER_TAB_ID, LOCAL_STORAGE_KEYS, PATHS, TAB_ID, VIEW } from '../utils/constants';

// Context(s)
import { useView } from './ViewContext';

// Util(s)
import { CommonUtil, StorageUtil } from '../utils';

const TabContext = createContext({
  tabIndex: 0,
  setTabIndex: () => {},
  selectedTab: null,
  setSelectedTab: () => {},
  selectedFilterTab: null,
  setSelectedFilterTab: () => {},
  DEFAULTS: {},
});

const TabProvider = ({ children }) => {
  const location = useLocation();
  const { isTargetsPath, isTasksPath, view } = useView();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedFilterTab, setSelectedFilterTab] = useState(null);
  const isDismissTab = useMemo(() => selectedTab === TAB_ID.SELECTED_FOR_DISMISSAL, [selectedTab]);
  const isNewTab = useMemo(() => selectedTab === TAB_ID.NEW, [selectedTab]);
  const isToolsTab = useMemo(() => selectedFilterTab === FILTER_TAB_ID.TOOLS, [selectedFilterTab]);
  const DEFAULTS = useMemo(() => {
    if ([VIEW.RORO, VIEW.AIRPAX, VIEW.AIRPAX_V2, VIEW.GENERAL_AVIATION, VIEW.IDP, VIEW.CTBP, VIEW.COMMODITIES, VIEW.OSDT].includes(view)) {
      return TASK_DEFAULTS;
    }
    return TARGET_DEFAULTS;
  }, [view]);

  const getTabIndex = (cachedTab) => {
    const path = [...PATHS.TASK_LISTS(), ...PATHS.TARGET_LISTS()].find((pathItem) => location.pathname.startsWith(pathItem));
    if (!path) {
      return 0;
    }
    return CommonUtil.findTabIndex(DEFAULTS?.[view]?.tabs, cachedTab);
  };

  const getCachedTab = () => {
    const cachedTab = StorageUtil.get(LOCAL_STORAGE_KEYS.CACHED_TAB);
    if (!cachedTab) {
      return DEFAULTS?.[view]?.tabs?.[0]?.id;
    }

    return cachedTab;
  };

  useEffect(() => {
    if (isTargetsPath || isTasksPath) {
      const cachedTab = getCachedTab();
      setTabIndex(getTabIndex(cachedTab));
      setSelectedTab(cachedTab);
    }
  }, [isTargetsPath, isTasksPath]);

  const value = useMemo(() => ({
    isDismissTab,
    isNewTab,
    isToolsTab,
    selectedTab,
    setSelectedTab,
    tabIndex,
    setTabIndex,
    selectedFilterTab,
    setSelectedFilterTab,
    DEFAULTS,
  }), [selectedTab, setSelectedTab, selectedFilterTab, setSelectedFilterTab, tabIndex, setTabIndex, isNewTab, isDismissTab, isToolsTab, DEFAULTS]);

  return <TabContext.Provider value={value}>{children}</TabContext.Provider>;
};

const useTabs = () => useContext(TabContext);

export { TabContext, TabProvider, useTabs };
