// Global import(s)
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, createContext, useContext } from 'react';

// Config(s)
import { RBAC } from '../utils/constants';

// Context(s)
import { useKeycloak } from './Keycloak';

const PermissionContext = createContext({
  canCreateTask: false,
  canReadTask: false,
  canUpdateTask: false,
  canCreateTarget: false,
  canReadTarget: false,
  canUpdateTarget: false,
  isLiveServicesUser: false,
  canViewCommodities: false,
  canViewIdp: false,
  canViewCtbp: false,
  canViewOsdt: false,
  isIdpUser: false,
  isCommoditiesUser: false,
  canBulkDelete: false,
  hasRole: () => {},
});

const PermissionProvider = ({ children }) => {
  const keycloak = useKeycloak();

  const hasRole = useCallback((role) => {
    const userRoles = keycloak?.tokenParsed?.realm_access?.roles;
    if (Array.isArray(role)) {
      return role.every((r) => userRoles.includes(r));
    }
    return userRoles.includes(role);
  }, [keycloak]);

  const hasRoRoFrontLineOfficerGroup = useCallback(() => {
    const userGroups = keycloak?.tokenParsed?.groups || [];
    return userGroups.some((group) => group.startsWith('/GP'));
  }, [keycloak]);

  const hasAirFrontLineOfficerGroup = useCallback(() => {
    const userGroups = keycloak?.tokenParsed?.groups || [];
    return userGroups.some((group) => group.startsWith('/AP'));
  }, [keycloak]);

  const hasLiveServicesRole = () => {
    return hasRole(RBAC.LIVE_SERVICES);
  };

  const hasTaskCreatePermission = () => {
    return hasRole(RBAC.TASK_CREATE);
  };

  const hasTaskReadPermission = () => {
    return hasRole(RBAC.TASK_READ);
  };

  const hasTaskUpdatePermission = () => {
    return hasRole(RBAC.TASK_UPDATE);
  };

  const hasTargetCreatePermission = () => {
    return hasRole(RBAC.TARGET_CREATE);
  };

  const hasTargetReadPermission = () => {
    return hasRole(RBAC.TARGET_READ);
  };

  const hasTargetUpdatePermission = () => {
    return hasRole(RBAC.TARGET_UPDATE);
  };

  const hasCommoditiesViewPermission = () => {
    return hasRole(RBAC.TASK_AIRPAX_COMMODITIES_READ);
  };

  const hasIdpViewPermission = () => {
    return hasRole(RBAC.TASK_AIRPAX_IDP_READ);
  };

  const hasCtbpViewPermission = () => {
    return hasRole(RBAC.TASK_AIRPAX_CTBP_READ);
  };

  const hasOsdtViewPermission = () => {
    return hasRole(RBAC.TARGETER_AIRPAX_OSDT);
  };

  const hasIdpTargeterPermission = () => {
    return hasRole(RBAC.TARGETER_AIRPAX_IDP);
  };

  const hasCommoditiesTargeterPermission = () => {
    return hasRole(RBAC.TARGETER_AIRPAX_COMMODITIES);
  };

  const hasBulkDeletePermission = () => {
    return hasRole(RBAC.TASK_RULE_BULK_DELETE);
  };

  const hasPnrAccessPermission = () => {
    return hasRole(RBAC.PNR_ACCESS);
  };

  const hasFrontLinePnrAccessPermission = () => {
    return hasRole(RBAC.FRONTLINE_PNR_ACCESS);
  };

  const value = useMemo(() => ({
    canCreateTask: hasTaskCreatePermission(),
    canReadTask: hasTaskReadPermission(),
    canUpdateTask: hasTaskUpdatePermission(),
    canCreateTarget: hasTargetCreatePermission(),
    canReadTarget: hasTargetReadPermission(),
    canUpdateTarget: hasTargetUpdatePermission(),
    isLiveServicesUser: hasLiveServicesRole(),
    canViewCommodities: hasCommoditiesViewPermission(),
    canViewIdp: hasIdpViewPermission(),
    canViewCtbp: hasCtbpViewPermission(),
    canViewOsdt: hasOsdtViewPermission(),
    isIdpUser: hasIdpTargeterPermission(),
    isCommoditiesUser: hasCommoditiesTargeterPermission(),
    canBulkDelete: hasBulkDeletePermission(),
    hasPnrAccess: hasPnrAccessPermission(),
    hasFrontLinePnrAccess: hasFrontLinePnrAccessPermission(),
    hasRole,
    hasRoRoFrontLineOfficerGroup,
    hasAirFrontLineOfficerGroup,
  }), [keycloak]);

  return (
    <PermissionContext.Provider value={value}>{children}</PermissionContext.Provider>
  );
};

const usePermission = () => useContext(PermissionContext);

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PermissionContext, PermissionProvider, usePermission };
