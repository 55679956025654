import { FormGroup, Heading, Radios } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

// Config(s)
import { COMPONENT_IDS } from '../constants';

const Watchlist = ({ componentErrors, onChange, formData }) => {
  return (
    <FormGroup
      id={COMPONENT_IDS.WATCHLIST}
      label={<Heading id="watchlist-heading" size="m">Passenger/s added to Border Crossing?</Heading>}
      error={componentErrors?.[COMPONENT_IDS.WATCHLIST]?.message}
    >
      <p>Check the box when you have added all passengers on the target sheet to
        Border Crossing to ensure they get stopped at the PCP?
      </p>
      <Radios
        classModifiers="inline"
        id={COMPONENT_IDS.WATCHLIST}
        fieldId={COMPONENT_IDS.WATCHLIST}
        options={[
          {
            value: 'true',
            label: 'Yes',
          },
          {
            value: 'false',
            label: 'No',
          },
        ]}
        onChange={onChange}
        value={formData?.[COMPONENT_IDS.WATCHLIST]}
      />
    </FormGroup>
  );
};

Watchlist.propTypes = {
  componentErrors: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.shape({}),
};

Watchlist.defaultProps = {
  componentErrors: null,
  formData: null,
};

export default memo(Watchlist);
