import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@ukhomeoffice/cop-react-components';

// Hooks
import useBulkFetchImages from '../../../../../../../utils/Hooks/useBulkFetchImages';
import useFetchTargetSheet from '../../../../../../../utils/Hooks/useFetchTargetSheet';

// Components
import ExtendedListRow from '../../generics/ExtendedListRow';
import LinkButton from '../../../../../../../components/Buttons/LinkButton';
import LoadingSpinner from '../../../../../../../components/LoadingSpinner/LoadingSpinner';
import { ArrowDownIcon, ArrowUpIcon } from '../../../../../../../components/Icon/Icon';

// Utils
import { DateTimeUtil } from '../../../../../../../utils';
import PersonUtil from '../../../../../../../utils/Person/personUtil';
import TargetPersonUtil from '../../../../../../../utils/Person/Target/targetPersonUtil';
import UpliftCommonUtil from '../../../../../../../utils/Common/Uplift/commonUtil';

// Image
import noPhotoAvailable from '../../../../../../../__assets__/images/person_photo_fallback.png';

import './PersonDetailFullScreen.scss';

const Previous = ({ onClick }) => {
  return (
    <LinkButton
      className="previous-link previous-next-link ho-nav-text-s super-align"
      onClick={onClick}
    >
      <ArrowUpIcon /> Previous
    </LinkButton>
  );
};

Previous.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const Next = ({ onClick }) => {
  return (
    <LinkButton
      className="next-link previous-next-link ho-nav-text-s super-align"
      onClick={onClick}
    >
      <ArrowDownIcon /> Next
    </LinkButton>
  );
};

Next.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const TAG_RIGHT_PADDING = 15;
const NAME_LEFT_PADDING = 10;

const getNationality = (person) => {
  const nationalityCodeIso2 = TargetPersonUtil.nationalityCountryIso2(person);
  const nationalityCodeIso3 = TargetPersonUtil.nationalityCountryIso3(person);
  const formattedNationality = UpliftCommonUtil.format.nationality(nationalityCodeIso2);
  return `${formattedNationality} - ${nationalityCodeIso3}`;
};

const PersonDetailFullScreen = ({ onClose, personPosition: initialPersonPosition, targetId, href }) => {
  const [personPosition, setPersonPosition] = useState(initialPersonPosition);
  const [listRowWidths, setListRowWidths] = useState({
    container: 0,
    0: 0,
    1: 0,
    2: 0,
  });
  const isUsingShortTagRef = useRef(false);
  const longTagWidthRef = useRef(null);

  const onResizeFactory = (elementKey) => ({ width }) => {
    setListRowWidths((prev) => ({
      ...prev,
      [elementKey]: width,
    }));
  };

  const { targetSheet, isLoading: isTargetSheetLoading } = useFetchTargetSheet(targetId, !!targetId);
  const { person: mainPersonOfInterest, otherPersons } = targetSheet?.informationSheet?.movement ?? {};
  const allPersonDetails = [mainPersonOfInterest, ...(otherPersons ?? [])];

  const person = allPersonDetails[personPosition];
  const { filename, approximateDateTaken } = person?.additionalContents?.[0] || {};

  const findPhotoContentUrl = (p) => p?.additionalContents?.find((content) => content?.type === 'PHOTO')?.url;
  const { results: imagesFetchResults } = useBulkFetchImages(allPersonDetails.map(findPhotoContentUrl));
  const { url: srcUrl, isLoading: isImageLoading } = imagesFetchResults?.[personPosition] ?? {};

  const shouldMovementStatusTagUseShortCode = () => {
    const containerWidth = listRowWidths.container;
    const nameWidth = listRowWidths['0'];
    const separatorWidth = listRowWidths['1'];
    const actualTagWidth = listRowWidths['2'];
    if (!containerWidth || !nameWidth || !separatorWidth || !actualTagWidth) {
      return false;
    }

    if (!isUsingShortTagRef.current) {
      longTagWidthRef.current = actualTagWidth;
    }

    const freeSpaceWithLongTag = containerWidth
        - nameWidth
        - NAME_LEFT_PADDING
        - separatorWidth
        - longTagWidthRef.current
        - TAG_RIGHT_PADDING;

    const isTooSmallSpaceForFullTag = freeSpaceWithLongTag < 30;
    isUsingShortTagRef.current = isTooSmallSpaceForFullTag;
    return isTooSmallSpaceForFullTag;
  };

  if (isTargetSheetLoading) {
    return <LoadingSpinner />;
  }

  const showPrevious = personPosition > 0;
  const showNext = personPosition < allPersonDetails.length - 1;

  return (
    <div id="person-details-full-screen">
      <div>
        <Link
          id="govuk-back-link"
          className="govuk-back-link"
          onClick={onClose}
          href={href}
        >
          Back to Target
        </Link>
      </div>
      <div id="person-details-header-container">
        <span className="ho-heading-l person-details-header">{`Person of Interest ${personPosition + 1}`}</span>
        {showPrevious && <Previous onClick={() => setPersonPosition(personPosition - 1)} />}
        {showPrevious && showNext && <span style={{ padding: '0px 10px' }} /> }
        {showNext && <Next onClick={() => setPersonPosition(personPosition + 1)} />}
      </div>
      <div id="photo-and-details-container">
        <LoadingSpinner loading={isImageLoading}>
          <div id="person-details-photo-container">
            <img id="person-details-photo" src={srcUrl || noPhotoAvailable} alt={filename || 'Photo'} />
            <div id="photo-date">
              <span>{`Date of photo: ${approximateDateTaken || ''}`}</span>
            </div>
          </div>
        </LoadingSpinner>
        <div className="govuk-grid-column-full">
          <div className="govuk-grid-row">
            <div id="person-details-display">
              <ExtendedListRow
                activeClass="badge-end"
                id={`person-${person.dateOfBirth}`}
                type="header-first"
                values={[
                  PersonUtil.formattedNameFromPerson(person, true),
                  '',
                  PersonUtil.movementStatusTag(person, null, shouldMovementStatusTagUseShortCode()),
                ]}
                onResizeFactory={onResizeFactory}
              />
              <ExtendedListRow
                id={`person-${person.dateOfBirth}-${person?.seatNumber}`}
                type="body"
                values={[
                  ['Nationality', getNationality(person)],
                  ['Date of birth', DateTimeUtil.format(person?.dateOfBirth, 'DD MMM YYYY')],
                  ['Gender', person?.gender?.name],
                ]}
              />
              {person?.seatNumber && (
                <ExtendedListRow
                  id={`person-${person.dateOfBirth}-${person?.seatNumber}`}
                  type="body"
                  values={[
                    ['Seat number', person?.seatNumber],
                  ]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PersonDetailFullScreen.propTypes = {
  onClose: PropTypes.func.isRequired,
  personPosition: PropTypes.number.isRequired,
  targetId: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default PersonDetailFullScreen;
