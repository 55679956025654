const isMatchingEntity = (entityId1, entityId2) => {
  if (!entityId1 || !entityId2) {
    return false;
  }

  return (
    entityId1.svxId === entityId2.svxId
    && entityId1.poleV1Id === entityId2.poleV1Id
    && entityId1.poleV2Id === entityId2.poleV2Id
    && entityId1.pnrPoleV1Id === entityId2.pnrPoleV1Id
    && entityId1.pnrPoleV2Id === entityId2.pnrPoleV2Id
  );
};

const EntityUtil = {
  isMatchingEntity,
};

export default EntityUtil;
