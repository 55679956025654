// Config
import { CHECK_TYPE, SYSTEM_CHECKS } from '../constants';
import { PNC_FIELDS } from '../../../../../../../../utils/TargetInformation/constants';
import { STRINGS } from '../../../../../../../../utils/constants';

// Util(s)
import { CredibilityChecksUtil } from '../../../../../../../../utils';

const formatTrace = (trace) => {
  if (trace === true) {
    return 'TRACE';
  }

  if (trace === false) {
    return 'NO_TRACE';
  }

  return null;
};

const toNotNecessaryCheck = (credibilityChecks) => {
  return {
    notRequired: CredibilityChecksUtil.notRequiredReason(credibilityChecks),
  };
};

const toOtherCheck = (credibilityCheck) => {
  return {
    id: credibilityCheck.id,
    systemChecked: credibilityCheck?.result?.systemChecked || null,
    trace: formatTrace(credibilityCheck?.result?.trace),
    comment: credibilityCheck?.result?.comment || null,
  };
};

const toTraceCheck = (credibilityCheck) => {
  return {
    [credibilityCheck.system]: {
      id: credibilityCheck.id,
      trace: formatTrace(credibilityCheck?.result?.trace) || null,
      comment: credibilityCheck?.result?.comment || null,
    },
  };
};

const toCredibilityCheck = (credibilityCheck) => {
  switch (credibilityCheck.type) {
    case 'TRACE': {
      return toTraceCheck(credibilityCheck);
    }
    case 'OTHER': {
      return toOtherCheck(credibilityCheck);
    }
    default: {
      return null;
    }
  }
};

const toChecks = (credibilityChecks, isChecksNotRequired) => {
  if (isChecksNotRequired) {
    return [STRINGS.FORM.NOT_REQUIRED];
  }

  const checks = credibilityChecks
    .filter((credibilityCheck) => !!credibilityCheck.result)
    .map((item) => item.system);
  return [...new Set(checks)]; // Deduped to remove duplicate OTHER entries.
};

export const toOtherChecksData = (passenger) => {
  const credibilityChecks = CredibilityChecksUtil.getChecksWithoutPnc(passenger);
  if (!credibilityChecks?.length) {
    return null;
  }

  const isChecksNotRequired = CredibilityChecksUtil.notRequired(passenger?.credibilityChecks);

  const traceChecksPerformed = credibilityChecks
    .filter((credibilityCheck) => credibilityCheck.type === CHECK_TYPE.TRACE)
    .map((credibilityCheck) => toCredibilityCheck(credibilityCheck))
    .filter((item) => !!item)
    .reduce((prev, current) => ({
      ...prev,
      ...current,
    }), {});

  const additionalChecksPerformed = credibilityChecks
    .filter((credibilityCheck) => credibilityCheck.type === CHECK_TYPE.OTHER)
    .map((credibilityCheck, index) => ({
      dataId: index,
      ...toCredibilityCheck(credibilityCheck),
    }))
    .filter((item) => !!item);

  return {
    checks: toChecks(credibilityChecks, isChecksNotRequired),
    ...toNotNecessaryCheck(passenger.credibilityChecks),
    ...traceChecksPerformed,
    [SYSTEM_CHECKS.OTHER]: additionalChecksPerformed,
  };
};

export const toPncData = (passenger) => {
  const pncCheck = CredibilityChecksUtil.getPnc(passenger);
  const categories = CredibilityChecksUtil.categories(pncCheck);

  return {
    id: pncCheck?.id,
    pncOutcome: CredibilityChecksUtil.outcome(pncCheck),
    pncFound: {
      pncId: CredibilityChecksUtil.pncId(pncCheck),
      safetyInformation: CredibilityChecksUtil.format.safetyInformation(CredibilityChecksUtil.safety(pncCheck)),
    },
    pncDetails: {
      types: CredibilityChecksUtil.types(pncCheck),
      staffConcerns: CredibilityChecksUtil.staffConcerns(pncCheck),
      poiConcerns: CredibilityChecksUtil.poiConcerns(pncCheck),
    },
    pncCategories: {
      ...(categories?.drugsPossession?.length && {
        drugsPossession: [PNC_FIELDS.DRUGS],
        possessionClass: categories?.drugsPossession,
      }),
      ...(categories?.drugsSupply?.length && {
        drugsSupply: [PNC_FIELDS.SUPPLY],
        supplyClass: categories?.drugsSupply,
      }),
      ...(categories?.drugsTrafficking?.length && {
        drugsTrafficking: [PNC_FIELDS.TRAFFICKING],
        traffickingClass: categories?.drugsTrafficking,
      }),
      ...(categories?.weaponsPossession?.length && {
        weaponsPossession: [PNC_FIELDS.POSSESSION],
        weaponsPossessionOpts: categories?.weaponsPossession,
      }),
      ...(categories?.weaponsSupply?.length && {
        weaponsSupply: [PNC_FIELDS.SUPPLY],
        weaponsSupplyOptions: categories?.weaponsSupply,
      }),
      ...(categories?.goodsSmuggling?.length && {
        goodsSmuggling: [PNC_FIELDS.GOODS_SMUGGLING],
        goodsSmugglingOpts: categories?.goodsSmuggling,
      }),
      ...(categories?.peopleSmuggling?.length && {
        peopleSmuggling: [PNC_FIELDS.PEOPLE_SMUGGLING],
        peopleSmugglingOpts: categories?.peopleSmuggling,
      }),
    },
  };
};
