import useFetchImage from './useFetchImage';

/**
 * A bulk image fetcher which can resolves many URL's, returns result when available
 * @param imageUrls array of URL strings for images which require fetching
 * @returns {{ results, isSomeLoading: boolean}}
 */
const useBulkFetchImages = (imageUrls) => {
  // Map over URLs and use the singular hook for each URL
  const results = imageUrls.map((url) => useFetchImage(url));

  // Combine loading states
  const isSomeLoading = results.some((result) => result?.isLoading);

  return { results, isSomeLoading };
};

export default useBulkFetchImages;
