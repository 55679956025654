// Global import(s)
import { Button, ButtonGroup } from '@ukhomeoffice/cop-react-components';
import PropTypes from 'prop-types';
import React from 'react';

// Config(s)
import { TARGET_STATUS } from '../../../../../utils/constants';

// Contexts(s)
import { useTabs } from '../../../../../context/TabContext';
import { usePermission } from '../../../../../context/PermissionContext';
import { useView } from '../../../../../context/ViewContext';

// Component(s)
import TargetUserInfo from '../details/TargetUserInfo';

// Util(s)
import { TargetCommonUtil } from '../../../../../utils';

export const RCCUReceiptButton = ({ targetStatus, onClick, permission, view }) => {
  if (permission && targetStatus === TARGET_STATUS.RCCU_INCOMING && TargetCommonUtil.isRCCUAirpaxView(view)) {
    return (
      <Button
        id="confirmReceipt"
        classModifiers="blue"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="confirm target receipt"
      >
        Confirm receipt
      </Button>
    );
  }

  return null;
};

export const AirPaxReceiptButton = ({ targetStatus, issuedToRccu, onClick, permission, view }) => {
  const isRccuOfficerAllowedToViewButton = () => {
    return TargetCommonUtil.isRCCUAirpaxView(view) && !issuedToRccu && targetStatus === TARGET_STATUS.INCOMING;
  };

  const isHigherOfficerAllowedToViewButton = () => {
    return TargetCommonUtil.isHigherOfficerAirpaxView(view) && targetStatus === TARGET_STATUS.INCOMING;
  };

  if (permission && (isRccuOfficerAllowedToViewButton() || isHigherOfficerAllowedToViewButton())) {
    return (
      <Button
        id="confirmReceipt"
        classModifiers="blue"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="confirm target receipt"
      >
        Confirm receipt
      </Button>
    );
  }

  return null;
};

export const RoRoReceiptButton = ({ targetStatus, onClick, permission, view }) => {
  if (permission && [TARGET_STATUS.NEW, TARGET_STATUS.ASSIGNED].includes(targetStatus)
    && TargetCommonUtil.isViewTargetRoro(view)) {
    return (
      <Button
        id="confirmReceipt"
        classModifiers="blue"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="confirm target receipt"
      >
        Confirm receipt
      </Button>
    );
  }

  return null;
};

export const AssignButton = ({ targetStatus, issuedToRccu, onClick, permission, view }) => {
  const isRccuOfficerAllowedToViewButton = () => {
    return (TargetCommonUtil.isRCCUAirpaxView(view)
      && (targetStatus === TARGET_STATUS.RCCU_RECEIPTED || (!issuedToRccu && targetStatus === TARGET_STATUS.RECEIPTED)));
  };

  const isHigherOfficerAllowedToViewButton = () => {
    return TargetCommonUtil.isHigherOfficerAirpaxView(view) && targetStatus === TARGET_STATUS.RECEIPTED;
  };

  if (permission && (isRccuOfficerAllowedToViewButton() || isHigherOfficerAllowedToViewButton())) {
    return (
      <Button
        id="assignTarget"
        classModifiers="blue"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="assign target to recipients"
      >
        Assign
      </Button>
    );
  }

  return null;
};

export const ReAssignButton = ({ targetStatus, issuedToRccu, onClick, permission, view }) => {
  const isRccuOfficerAllowedToViewButton = () => {
    return (TargetCommonUtil.isRCCUAirpaxView(view)
      && ([TARGET_STATUS.ASSIGNED, TARGET_STATUS.ACCEPTED].includes(targetStatus)
        || (issuedToRccu && [TARGET_STATUS.INCOMING, TARGET_STATUS.RECEIPTED].includes(targetStatus))));
  };

  const isHigherOfficerAllowedToViewButton = () => {
    return (TargetCommonUtil.isHigherOfficerAirpaxView(view) && [TARGET_STATUS.ASSIGNED, TARGET_STATUS.ACCEPTED].includes(targetStatus));
  };

  if (permission && (isRccuOfficerAllowedToViewButton() || isHigherOfficerAllowedToViewButton())) {
    return (
      <Button
        id="reAssignTarget"
        classModifiers="blue"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="reassign target to other recipients"
      >
        Reassign
      </Button>
    );
  }

  return null;
};

export const AcceptButton = ({ targetStatus, issuedToRccu, onClick, permission, view }) => {
  const isHigherOfficerAllowedToViewButton = () => {
    return TargetCommonUtil.isHigherOfficerAirpaxView(view) && targetStatus === TARGET_STATUS.ASSIGNED;
  };

  const isRccuOrInterceptingOfficerAllowedToViewButton = () => {
    return (
      (TargetCommonUtil.isRCCUAirpaxView(view) || TargetCommonUtil.isInterceptingOfficerAirpaxView(view))
      && (targetStatus === TARGET_STATUS.ASSIGNED
        || (issuedToRccu && [TARGET_STATUS.INCOMING, TARGET_STATUS.RECEIPTED].includes(targetStatus)))
    );
  };

  if (permission && (isHigherOfficerAllowedToViewButton() || isRccuOrInterceptingOfficerAllowedToViewButton())) {
    return (
      <Button
        id="acceptTarget"
        classModifiers="blue"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="accept assigned target"
      >
        Accept
      </Button>
    );
  }

  return null;
};

export const CaptureButton = ({ targetStatus, onClick, permission, view }) => {
  if (
    permission
    && [TARGET_STATUS.ACKNOWLEDGED, TARGET_STATUS.ACCEPTED].includes(targetStatus)
    && TargetCommonUtil.isViewTargetRoro(view)
  ) {
    return (
      <Button
        id="captureTarget"
        classModifiers="blue"
        className="margin-right-5"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="capture target"
      >
        Capture
      </Button>
    );
  }

  return null;
};

export const OutcomeButton = ({ targetStatus, onClick, permission, view }) => {
  const isAirpaxOfficerAllowedToViewButton = () => {
    return TargetCommonUtil.isViewTargetAirpax(view) && targetStatus === TARGET_STATUS.ACCEPTED;
  };

  const isRoroOfficerAllowedToViewButton = () => {
    return (
      TargetCommonUtil.isViewTargetRoro(view)
      && [TARGET_STATUS.ACKNOWLEDGED, TARGET_STATUS.CAPTURED].includes(targetStatus)
    );
  };

  if (permission && (isAirpaxOfficerAllowedToViewButton() || isRoroOfficerAllowedToViewButton())) {
    return (
      <Button
        id="recordOutcome"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="record target outcome"
      >
        Record outcome
      </Button>
    );
  }

  return null;
};

export const DetailsButton = ({ targetStatus, onClick, permission }) => {
  if (!permission || targetStatus === TARGET_STATUS.WITHDRAWN) {
    return null;
  }

  return (
    <Button
      id="viewDetails"
      classModifiers="secondary"
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      aria-label="view target details"
    >
      View details
    </Button>
  );
};

export const UnCaptureButton = ({ targetStatus, onClick, permission }) => {
  if (permission && targetStatus === TARGET_STATUS.CAPTURED) {
    return (
      <Button
        id="undoCapture"
        classBlock="govuk-link"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        aria-label="uncapture target"
      >
        Undo capture
      </Button>
    );
  }

  return null;
};

const TargetListItemOptions = ({
  handleOnCapture,
  handleOnRCCUReceipt,
  handleOnReceipt,
  handleOnAccept,
  handleUndoCapture,
  handleRecordOutcome,
  handleAssign,
  handleReAssign,
  handleViewDetails,
  selectedTarget,
}) => {
  const { selectedTab } = useTabs();
  const { canReadTarget, canUpdateTarget } = usePermission();
  const { view } = useView();
  const { status: targetStatus, assignees, actioners, issuedToRccu } = selectedTarget;

  return (
    <div>
      <TargetUserInfo
        selectedTab={selectedTab}
        assignedUsers={assignees?.users}
        assignedTeams={assignees?.teams}
        accepter={actioners?.accepter}
        view={view}
      />
      <ButtonGroup>
        <RCCUReceiptButton
          targetStatus={targetStatus}
          onClick={handleOnRCCUReceipt}
          permission={canUpdateTarget}
          view={view}
        />
        <AirPaxReceiptButton
          targetStatus={targetStatus}
          issuedToRccu={issuedToRccu}
          onClick={handleOnReceipt}
          permission={canUpdateTarget}
          view={view}
        />
        <RoRoReceiptButton
          targetStatus={targetStatus}
          onClick={handleOnAccept}
          permission={canUpdateTarget}
          view={view}
        />
        <AssignButton
          targetStatus={targetStatus}
          issuedToRccu={issuedToRccu}
          onClick={handleAssign}
          permission={canUpdateTarget}
          view={view}
        />
        <ReAssignButton
          targetStatus={targetStatus}
          issuedToRccu={issuedToRccu}
          onClick={handleReAssign}
          permission={canUpdateTarget}
          view={view}
        />
        <AcceptButton
          targetStatus={targetStatus}
          issuedToRccu={issuedToRccu}
          onClick={handleOnAccept}
          permission={canUpdateTarget}
          view={view}
        />
        <CaptureButton
          targetStatus={targetStatus}
          onClick={handleOnCapture}
          permission={canUpdateTarget}
          view={view}
        />
        <OutcomeButton
          targetStatus={targetStatus}
          onClick={handleRecordOutcome}
          permission={canUpdateTarget}
          view={view}
        />
        <DetailsButton
          targetStatus={targetStatus}
          onClick={handleViewDetails}
          permission={canReadTarget}
        />
        <UnCaptureButton
          targetStatus={targetStatus}
          onClick={handleUndoCapture}
          permission={canUpdateTarget}
        />
      </ButtonGroup>
    </div>
  );
};

TargetListItemOptions.propTypes = {
  handleOnCapture: PropTypes.func,
  handleOnRCCUReceipt: PropTypes.func,
  handleOnReceipt: PropTypes.func,
  handleOnAccept: PropTypes.func.isRequired,
  handleUndoCapture: PropTypes.func,
  handleRecordOutcome: PropTypes.func,
  handleAssign: PropTypes.func,
  handleReAssign: PropTypes.func,
  handleViewDetails: PropTypes.func.isRequired,
  selectedTarget: PropTypes.shape({
    informationSheet: PropTypes.shape({
      parentBusinessKey: PropTypes.shape({
        businessKey: PropTypes.string,
      }),
    }),
    status: PropTypes.string,
    issuedToRccu: PropTypes.bool,
    assignees: PropTypes.shape({
      users: PropTypes.arrayOf(PropTypes.shape({})),
      teams: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    actioners: PropTypes.shape({
      accepter: PropTypes.shape({}),
    }),
  }),
};

TargetListItemOptions.defaultProps = {
  selectedTarget: PropTypes.shape({}),
  handleOnCapture: undefined,
  handleOnRCCUReceipt: undefined,
  handleOnReceipt: undefined,
  handleRecordOutcome: undefined,
  handleAssign: undefined,
  handleReAssign: undefined,
  handleUndoCapture: undefined,
};

RCCUReceiptButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

AirPaxReceiptButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  issuedToRccu: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

RoRoReceiptButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

AssignButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  issuedToRccu: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

ReAssignButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  issuedToRccu: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

AcceptButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  issuedToRccu: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

CaptureButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

OutcomeButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
};

UnCaptureButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
};

DetailsButton.propTypes = {
  targetStatus: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  permission: PropTypes.bool.isRequired,
};

export default TargetListItemOptions;
