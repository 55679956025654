/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { lazy, Suspense } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';

// Context
import { useView } from '../../context/ViewContext';

// Config(s)
import config from '../../utils/config';
import { LOCAL_STORAGE_KEYS, PATHS } from '../../utils/constants';

// Components/ Pages
import Layout from '../../components/Layout/Layout';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import TargetsListPage from './TargetsListPage/TargetsListPage';
import TargetPnrAccess from './Access/TargetPnrAccess';

// Hook(s)
import useFetchTeam from '../../utils/Hooks/useFetchTeam';

// HOC(s)
import withWebSocketSession from '../../components/HOC/withWebSocketSession';

// Lazy Component(s)
const AssignTarget = lazy(() => import('./TargetAssign/AssignTarget'));
const ReAssignTarget = lazy(() => import('./TargetAssign/ReAssignTarget'));
const RecordOutcome = lazy(() => import('./TargetOutcome/RecordOutcome'));
const TargetSheetPage = lazy(() => import('./TargetSheetPage/TargetSheetPage'));

const TargetRouter = () => {
  const navigate = useNavigate();
  const { showHandlingInstructions, setShowHandlingInstructions, isTargetsPath } = useView();

  useFetchTeam();

  const goBack = async (e) => {
    e.preventDefault();
    if (showHandlingInstructions) {
      setShowHandlingInstructions(false);
      return;
    }

    const path = localStorage.getItem(LOCAL_STORAGE_KEYS.TARGET_DETAILS_BACK_PATH);
    if (path && path.length > 0) {
      try {
        setTimeout(() => {
          localStorage.removeItem(LOCAL_STORAGE_KEYS.TARGET_DETAILS_BACK_PATH);
        }, 500);

        navigate(path);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Navigation error', error);
      }
    } else if (isTargetsPath) {
      window.location.replace(config.copUiUrl);
    } else {
      navigate(-1);
    }
  };

  return (
    <TargetPnrAccess>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {[
            {
              path: `${PATHS.TARGETS.RORO}/`,
              enabled: true,
            },
            {
              path: `${PATHS.TARGETS.AIRPAX}/`,
              enabled: true,
            },
            {
              path: PATHS.TARGETS.RORO,
              enabled: true,
            },
            {
              path: PATHS.TARGETS.AIRPAX,
              enabled: true,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={(
                  <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={(e) => { goBack(e); }}>Back</Link>}>
                    <TargetsListPage />
                  </Layout>
                )}
                replace
              />
            ),
            )}
          {[
            {
              path: `${PATHS.TARGETS.RORO}/:targetId`,
              enabled: true,
            },
            {
              path: `${PATHS.TARGETS.AIRPAX}/:targetId`,
              enabled: true,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={(
                  <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={(e) => { goBack(e); }}>Back</Link>}>
                    <TargetSheetPage />
                  </Layout>
                )}
                replace
              />
            ),
            )}
          {[
            {
              path: `${PATHS.TARGETS.RORO}/:targetId/assign`,
              enabled: true,
            },
            {
              path: `${PATHS.TARGETS.AIRPAX}/:targetId/assign`,
              enabled: true,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={(
                  <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={(e) => { goBack(e); }}>Back</Link>}>
                    <AssignTarget />
                  </Layout>
                )}
                replace
              />
            ),
            )}
          {[
            {
              path: `${PATHS.TARGETS.RORO}/:targetId/reassign`,
              enabled: true,
            },
            {
              path: `${PATHS.TARGETS.AIRPAX}/:targetId/reassign`,
              enabled: true,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={(
                  <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={(e) => { goBack(e); }}>Back</Link>}>
                    <ReAssignTarget />
                  </Layout>
                )}
                replace
              />
            ),
            )}
          {[
            {
              path: `${PATHS.TARGETS.RORO}/:targetId/record-outcome`,
              enabled: true,
            },
            {
              path: `${PATHS.TARGETS.AIRPAX}/:targetId/record-outcome`,
              enabled: true,
            },
          ].filter((item) => !!item.enabled)
            .map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={(
                  <Layout beforeMain={<Link id="govuk-back-link" className="govuk-back-link" role="button" onClick={(e) => { goBack(e); }}>Back</Link>}>
                    <RecordOutcome />
                  </Layout>
                )}
                replace
              />
            ),
            )}
        </Routes>
      </Suspense>
    </TargetPnrAccess>
  );
};

export default withWebSocketSession(TargetRouter);
