import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import InternalFilter from '../../../../../../components/Filter/InternalFilter';
import { useTasks } from '../../../../../../context/TasksContext';

import matchRules from '../../../../../../forms/uplift/matchRules';

const RuleToolsTab = forwardRef((props, ref) => {
  const { data, customOptions, onApply, onClear, filterControlStyles, showFilterControlsAtTop } = props;
  const { setRules, rules } = useTasks();

  const onInternalApply = (payload) => {
    const rulesValue = Array.isArray(payload?.rules) ? [payload?.rules?.[0].value] : [payload?.rules?.value];

    onApply({
      ...payload,
      rules: rulesValue,
    });

    setRules(rulesValue);
  };

  const transformRules = () => {
    return {
      ...data,
      rules: [{ value: rules?.[0], label: rules?.[0] }],
    };
  };

  return (
    <InternalFilter
      form={matchRules}
      data={transformRules(data)}
      ref={ref}
      customOptions={{
        rules: customOptions?.rules,
      }}
      filterControlStyles={filterControlStyles}
      showFilterControlsAtTop={showFilterControlsAtTop}
      onApply={onInternalApply}
      onClear={onClear}
      isToolsTab
    />
  );
});

RuleToolsTab.propTypes = {
  data: PropTypes.shape({}),
  customOptions: PropTypes.shape({}),
  onApply: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  filterControlStyles: PropTypes.shape({}),
  showFilterControlsAtTop: PropTypes.bool,
};

RuleToolsTab.defaultProps = {
  data: PropTypes.shape({}),
  customOptions: PropTypes.shape({}),
  filterControlStyles: {
    clearFiltersText: 'Clear filters',
  },
  showFilterControlsAtTop: true,
};

export default RuleToolsTab;
