// Global import(s)
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { QueryClient, QueryClientProvider, notifyManager } from '@tanstack/react-query';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// Context(s)
import config from './utils/config';

// Context(s)
import { ApplicationContextProvider } from './context/ApplicationContext';
import { ErrorAlertProvider } from './context/ErrorAlertContext';
import { FilterToggleProvider } from './context/FilterToggleContext';
import { KeycloakProvider } from './context/Keycloak';
import { ModuleProvider } from './context/ModuleContext';
import { NotificationProvider } from './context/NotificationContext';
import { PermissionProvider } from './context/PermissionContext';
import { ScrimProvider } from './context/ScrimContext';
import { TabProvider } from './context/TabContext';
import { TasksProvider } from './context/TasksContext';
import { TeamProvider } from './context/TeamContext';
import { UserProvider } from './context/UserContext';
import { ViewProvider } from './context/ViewContext';
import { PnrAccessProvider } from './context/PnrAccessContext';

// Route(s)
import AppRouter from './routes';

// Util(s)
import reportAccessibilityIfEnabled from './utils/Accessibility/reportAccessibilityIfEnabled';

// Styling
import './__assets__/index.scss';

const App = () => {
  const tracker = createInstance({
    siteId: config.analyticsSiteId,
    urlBase: config.analyticsUrlBase,
  });

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  notifyManager.setScheduler((cb) => cb());

  return (
    <BrowserRouter>
      <KeycloakProvider>
        <QueryClientProvider client={queryClient}>
          <MatomoProvider value={tracker}>
            <PermissionProvider>
              <PnrAccessProvider>
                <ApplicationContextProvider>
                  <TeamProvider>
                    <UserProvider>
                      <ViewProvider>
                        <TabProvider>
                          <NotificationProvider>
                            <TasksProvider>
                              <FilterToggleProvider>
                                <ScrimProvider>
                                  <ErrorAlertProvider>
                                    <ModuleProvider>
                                      <AppRouter />
                                    </ModuleProvider>
                                  </ErrorAlertProvider>
                                </ScrimProvider>
                              </FilterToggleProvider>
                            </TasksProvider>
                          </NotificationProvider>
                        </TabProvider>
                      </ViewProvider>
                    </UserProvider>
                  </TeamProvider>
                </ApplicationContextProvider>
              </PnrAccessProvider>
            </PermissionProvider>
          </MatomoProvider>
        </QueryClientProvider>
      </KeycloakProvider>
    </BrowserRouter>
  );
};

reportAccessibilityIfEnabled();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
