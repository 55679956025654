/**
 * Checks if the current commodities recipient selection is a previous selection.
 *
 * @param {Object} formData - An object representing the selected options within a form.
 * @param {Object} data - An object representing the selected option.
 * @returns {boolean} Returns true if the formData contains the current selection otherwise, false.
 */
const containsRecipient = (formData, data) => {
  return !!formData?.targetRecipients?.find((opt) => opt.value === data?.targetRecipient?.value);
};

export default containsRecipient;
