import { Header as HodsHeader } from '@hods/components';
import React from 'react';
import { useLocation } from 'react-router-dom';

// Config(s)
import config from '../../../utils/config';
import { PATHS } from '../../../utils/constants';

// Context(s)
import { useView } from '../../../context/ViewContext';

// Component
import SkipLink from '../SkipLink/SkipLink';

// Hook(s)
import { useKeycloak } from '../../../context/Keycloak';
import useScreenSize from '../../../utils/Hooks/useScreenSize';

// Util
import { toContainerClassname, toPathClassname } from '../../Utils/Component/toClassName';

// Styling
import './Header.scss';

const Header = () => {
  const keycloak = useKeycloak();
  const location = useLocation();
  const { isNineteenTwentyOrMorePixels } = useScreenSize();
  const { isTargetsPath, isUseFullScreen } = useView();
  const wideHeaderClass = isUseFullScreen ? 'wide-header' : '';

  const isAirpaxPath = () => {
    return location.pathname.startsWith(PATHS.UPLIFT.AIRPAX);
  };

  const isGeneralAviationPath = () => {
    return location.pathname.startsWith(PATHS.GENERAL_AVIATION);
  };

  const toServiceName = () => {
    if (location?.pathname.startsWith('/targets')) {
      return 'Central Operations Platform';
    }
    return 'Cerberus: Task Management';
  };

  const toAccountURL = () => {
    if (isTargetsPath) {
      return config.cerberusProfileURL;
    }
    return '';
  };

  const toUsername = () => {
    if (isTargetsPath) {
      return 'Your profile';
    }
    return keycloak?.tokenParsed?.name;
  };

  const toHomeURL = () => {
    if (isAirpaxPath()) {
      return PATHS.UPLIFT.AIRPAX;
    }

    if (isGeneralAviationPath()) {
      return PATHS.GENERAL_AVIATION;
    }

    if (isTargetsPath) {
      return config.copUiUrl;
    }

    return PATHS.RORO;
  };

  return (
    <div>
      <SkipLink />
      <HodsHeader
        className={`${toContainerClassname(isNineteenTwentyOrMorePixels, isUseFullScreen)} ${toPathClassname(location.pathname)} ${wideHeaderClass}`}
        accountHref={toAccountURL()}
        serviceName={toServiceName()}
        serviceHref={toHomeURL()}
        username={toUsername()}
        signOutText="Sign out"
        signOutHref="/sign-out"
      />
    </div>
  );
};

export default Header;
