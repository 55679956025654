import { PropTypes } from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import './OfficialWarning.scss';

const OfficialWarning = ({ position, preview, taskdetails }) => {
  let view = 'official-warning';

  if (preview) {
    view += '-preview';
  } else if (taskdetails) {
    view += '-task-details';
  }

  return (
    <span
      className={classNames('ho-heading-s', view)}
      id={`official-warning__${position}`}
    >
      Official sensitive
    </span>
  );
};

OfficialWarning.propTypes = {
  position: PropTypes.string.isRequired,
  preview: PropTypes.bool,
};

OfficialWarning.defaultProps = {
  preview: false,
};

export default OfficialWarning;
