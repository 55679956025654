const taskCardAnimations = {
  initial: {
    opacity: 0,
    width: 0,
  },
  animate: {
    opacity: 1,
    width: 'auto',
  },
  exit: {
    opacity: 0,
    width: 0,
  },
  transition: {
    ease: 'easeInOut',
    duration: 0.5,
  },
};

export default taskCardAnimations;
