import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// Config
import { TARGET_OPERATION } from '../../../../../../../../../utils/constants';

// Component(s)
import LoadingSpinner from '../../../../../../../../../components/LoadingSpinner/LoadingSpinner';
import RenderForm from '../../../../../../../../../components/RenderForm/RenderForm';

// Hook
import { setTabTitle, TAB_TITLES } from '../../../../../../../../../utils/Hooks/useSetTabTitle';

// Util(s)
import addPreviousSelection from '../../helper/addPreviousSelection';
import { Renderers } from '../../../../../../../../../utils/Form/ReactForm';
import updateIdpRecipients from './updateIdpRecipients';

// Form(s)
import targetRecipientIdp, { TARGET_RECIPIENT_ID } from '../../../../../../../../../forms/uplift/targetRecipientIdp';

import './IdpAddRecipient.scss';

const IdpAddRecipient = ({ actionType, previouslySelectedRecipient, onSave, setFormData, onCancel, targetRecipientOptions }) => {
  const [form, setForm] = useState(null);

  setTabTitle(TAB_TITLES.ADD_TARGET_SHEET_RECIPIENT);

  const onInternalSaveAndClose = (data) => {
    //
    // This is a stub for a recipient;
    // REMOVE the FALSE data later on when the BE
    // has caught up;
    //
    const createNewRecipient = (email) => {
      return {
        email,
        telephone: '01234-567-8901',
        recipientName: 'Winston Churchill',
      };
    };

    const newRecipients = [
      data?.email,
      ...(data?.emailRecipients?.map((recipient) => recipient.email) || []),
    ]
      .filter((email) => email !== null)
      .map(createNewRecipient);

    setFormData((prev) => ({
      ...prev,
      targetRecipients: updateIdpRecipients(prev.targetRecipients, newRecipients),
    }));

    if (typeof onSave === 'function') {
      onSave();
    }
  };

  useEffect(() => {
    if (!previouslySelectedRecipient) {
      // Setup component options
      const _form = {
        ...targetRecipientIdp,
      };
      _form.pages = _form.pages.map((page) => {
        return {
          ...page,
          components: page.components.map((component) => {
            if (component.id !== TARGET_RECIPIENT_ID) {
              return component;
            }

            return {
              ...component,
              data: {
                options: targetRecipientOptions,
              },
            };
          }),
        };
      });
      setForm(_form);
      return;
    }

    setForm(addPreviousSelection(targetRecipientIdp, targetRecipientOptions, TARGET_RECIPIENT_ID, targetRecipientOptions, previouslySelectedRecipient));
  }, []);

  useEffect(() => {
    return () => setTabTitle(actionType === TARGET_OPERATION.CREATE ? TAB_TITLES.CREATE_TARGET : TAB_TITLES.EDIT_TARGET);
  }, []);

  if (!form) {
    return <LoadingSpinner />;
  }

  return (
    <div id="add-recipient" className={classNames('govuk-grid-row', previouslySelectedRecipient && 'option-divider')}>
      <div className="govuk-grid-column-two-thirds">
        <RenderForm
          form={form}
          viewOnly={false}
          onSubmit={async ({ data }) => onInternalSaveAndClose(data)}
          renderer={Renderers.REACT}
          onCancel={onCancel}
        />
      </div>
    </div>
  );
};

IdpAddRecipient.propTypes = {
  actionType: PropTypes.string.isRequired,
  previouslySelectedRecipient: PropTypes.string,
  formData: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  targetRecipientOptions: PropTypes.arrayOf(PropTypes.shape({})),
};

IdpAddRecipient.defaultProps = {
  previouslySelectedRecipient: null,
  targetRecipientOptions: [],
};

export default IdpAddRecipient;
