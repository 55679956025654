// Global import(s)
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

// Config
import config from '../config';
import QUERY_KEYS from './constants';
import { PATHS, RBAC } from '../constants';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTask } from '../../context/TaskContext';
import { useView } from '../../context/ViewContext';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

const findRoleNeededGivenUrlPath = (path) => {
  if (path.startsWith(PATHS.AIRPAX) || path.startsWith(PATHS.UPLIFT.AIRPAX)) {
    return RBAC.TASK_AIRPAX_READ;
  } if (path.startsWith(PATHS.RORO) || path.startsWith(PATHS.UPLIFT.RORO)) {
    return RBAC.TASK_RORO_READ;
  } if (path.startsWith(PATHS.GENERAL_AVIATION)) {
    return RBAC.TASK_GA_READ;
  }
  // Could not determine mode given path, return general task read RBAC
  return RBAC.TASK_READ;
};

const useFetchTask = (taskId, justificationId) => {
  const keycloak = useKeycloak();
  const location = useLocation();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { setTask } = useTask();
  const { setView } = useView();

  const getTask = async () => {
    if (!canReadTask || !hasRole(findRoleNeededGivenUrlPath(location.pathname))) {
      return null;
    }

    return AxiosRequests.taskData(apiClient, taskId, justificationId);
  };

  const { data: task, isFetching: isLoading } = useQuery({
    queryKey: [QUERY_KEYS.TASK, taskId, justificationId],
    queryFn: () => getTask(),
    initialData: null,
    cacheTime: 0,
    enabled: justificationId !== 'CREATE_JUSTIFICATION',
  });

  useEffect(() => {
    if (task) {
      setTask(task);
      setView(task.mode);
    }
  }, [task]);

  return { task, isLoading };
};

export default useFetchTask;
