const containsRecipient = (recipient, recipients) => {
  return recipients.map((r) => r.email).includes(recipient.email);
};

/**
 * Checks if the current recipient selection is a previous selection.
 *
 * @param {Array} existingRecipients - An array of IDP recipients
 * @param {Array} newRecipients - An array of IDP recipients
 * @returns {Array} Returns the existing and new array of IDP recipients without duplicates
 */
const updateIdpRecipients = (existingRecipients, newRecipients) => {
  const existingRecipientsWithoutDuplicates = existingRecipients.filter((recipient) => !containsRecipient(recipient, newRecipients));
  return [
    ...existingRecipientsWithoutDuplicates,
    ...newRecipients,
  ];
};

export default updateIdpRecipients;
