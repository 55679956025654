import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { memo, useEffect } from 'react';

// Context(s)
import { useView } from '../../../../../../context/ViewContext';

// Component(s)
import LinkButton from '../../../../../../components/Buttons/LinkButton';
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';

const HandlingInstructions = ({ showBackLink = false }) => {
  const { trackPageView } = useMatomo();
  const { setShowHandlingInstructions } = useView();

  useEffect(() => {
    trackPageView();
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(1, 1);
    };
  }, []);

  return (
    <div id="handling-instructions-page">
      <ComponentWrapper show={showBackLink}>
        <LinkButton
          id="back-to-create-target"
          className="govuk-back-link"
          onClick={() => setShowHandlingInstructions(false)}
        >
          Back
        </LinkButton>
      </ComponentWrapper>
      <div className="govuk-width-container" style={{ marginLeft: '0px' }}>
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l">Handling Instructions</h1>
            <p className="govuk-body">
              This target is subject to Public Interest Immunity. Dissemination is permitted between Border Force,
              HMRC, NCA and the Police in line with IANA 2006 (36)&nbsp;
              <a href="https://www.legislation.gov.uk/ukpga/2006/13/section/36" target="_blank" rel="noopener noreferrer">
                Immigration, Asylum and Nationality Act 2006 (legislation.gov.uk)
              </a>
              &nbsp;for the purposes of seizure adoption only.
            </p>
            <p className="govuk-body">
              No further dissemination is permitted without reference to the originator.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HandlingInstructions);
