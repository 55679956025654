import { startCase, toLower } from 'lodash';
import lookup from 'country-code-lookup';

// Util(s)
import { shouldMaskDate, shouldMaskString, getMaskedValue } from '../../Masking/maskingUtil';
import { shouldHideDate, shouldHideString } from '../../Hiding/hidingUtil';

const getDocumentTypeDetail = (document) => {
  if (!document?.otherTypeDetail) {
    return null;
  }

  if (shouldHideString(document.otherTypeDetail)) {
    return null;
  }

  if (shouldMaskString(document.otherTypeDetail)) {
    return getMaskedValue();
  }

  return document.otherTypeDetail;
};

const getAdditionalContent = (document) => {
  if (!document?.additionalContent || !document?.additionalContent?.length) {
    return [];
  }

  return document.additionalContent;
};

const getDocumentExpiry = (document) => {
  if (!document?.expiry) {
    return null;
  }

  if (shouldHideDate(document.expiry)) {
    return null;
  }

  if (shouldMaskDate(document.expiry)) {
    return getMaskedValue();
  }

  return document.expiry;
};

const getDocumentValidFrom = (document) => {
  if (!document?.validFrom) {
    return null;
  }

  if (shouldHideDate(document.validFrom)) {
    return null;
  }

  if (shouldMaskDate(document.validFrom)) {
    return getMaskedValue();
  }

  return document.validFrom;
};

const toFormattedCountryOfIssue = (iso2Code) => {
  if (!iso2Code) {
    return null;
  }

  try {
    return lookup?.byIso(iso2Code)?.country;
  } catch {
    return null;
  }
};

const toFormattedDocumentType = (type) => {
  if (!type) {
    return null;
  }
  return startCase(toLower(type));
};

const getDocumentType = (document) => {
  if (!document?.type) {
    return null;
  }

  if (shouldHideString(document.type)) {
    return null;
  }

  if (shouldMaskString(document.type)) {
    return getMaskedValue();
  }

  return document.type;
};

const getDocumentNumber = (document) => {
  if (!document?.number) {
    return null;
  }

  if (shouldHideString(document.number)) {
    return null;
  }

  if (shouldMaskString(document.number)) {
    return getMaskedValue();
  }

  return document.number;
};

const getDocumentCountryOfIssueCode = (document) => {
  if (!document?.countryOfIssue) {
    return null;
  }

  if (shouldHideString(document.countryOfIssue)) {
    return null;
  }

  if (shouldMaskString(document.countryOfIssue)) {
    return getMaskedValue();
  }

  return document.countryOfIssue;
};

const getDocument = (person) => {
  if (!person?.document) {
    return null;
  }

  return person.document;
};

const getDocuments = (person) => {
  if (!person?.documents) {
    return null;
  }

  return person.documents;
};

const DocumentUtil = {
  get: getDocument,
  getDocs: getDocuments,
  expiry: getDocumentExpiry,
  validFrom: getDocumentValidFrom,
  type: getDocumentType,
  number: getDocumentNumber,
  countryOfIssue: getDocumentCountryOfIssueCode,
  additionalContent: getAdditionalContent,
  otherTypeDetail: getDocumentTypeDetail,
  format: {
    type: toFormattedDocumentType,
    countryOfIssue: toFormattedCountryOfIssue,
  },
};

export default DocumentUtil;

export {
  getAdditionalContent,
  getDocument,
  getDocuments,
  getDocumentExpiry,
  getDocumentValidFrom,
  getDocumentType,
  getDocumentTypeDetail,
  getDocumentNumber,
  getDocumentCountryOfIssueCode,
  toFormattedDocumentType,
  toFormattedCountryOfIssue,
};
