import { FRONTLINE_OUTCOME, PATHS, TARGETER_OUTCOME, TASK_STATUS } from '../../../utils/constants';

export const DEFAULT_WIDTH_CONTAINER_CLASS = 'hods-width-container';

export const GRID_ITEM_CLASSES = {
  0: 'odd',
  1: 'even',
};

export const toPathClassname = (pathname) => {
  if (pathname === PATHS.UPLIFT.AIRPAX) {
    return 'task-list';
  }

  if ([PATHS.TARGETS.AIRPAX, PATHS.TARGETS.RORO].some((path) => pathname === path)) {
    return 'targets-list';
  }

  if ([PATHS.TARGETS.AIRPAX, PATHS.TARGETS.RORO].some((path) => pathname.startsWith(path))) {
    return 'targets-page';
  }

  return '';
};

export const toMainContentClassname = (isNineteenTwentyOrMorePixels) => {
  if (isNineteenTwentyOrMorePixels) {
    return '-two-thirds';
  }
  return '-full';
};

export const toContainerClassname = (isNineteenTwentyOrMorePixels, override = false) => {
  if (isNineteenTwentyOrMorePixels || override) {
    return DEFAULT_WIDTH_CONTAINER_CLASS;
  }
  return `${DEFAULT_WIDTH_CONTAINER_CLASS}--center`;
};

const toFrontlineOutcomeClassname = (frontlineOutcome) => {
  switch (frontlineOutcome) {
    case FRONTLINE_OUTCOME.POSITIVE:
      return 'tag--positive-exam';
    case FRONTLINE_OUTCOME.NEGATIVE:
      return 'tag--negative-exam';
    case FRONTLINE_OUTCOME.NO_SHOW:
      return 'tag--no-show-exam';
    case FRONTLINE_OUTCOME.MISSED:
      return 'tag--missed-exam';
    case FRONTLINE_OUTCOME.INSUFFICIENT_RESOURCES:
      return 'tag--insufficient-resources-exam';
    case FRONTLINE_OUTCOME.CLOSED_HIT_WITHOUT_FEEDBACK:
    case FRONTLINE_OUTCOME.CLOSED_HIT_WITH_FEEDBACK:
    case FRONTLINE_OUTCOME.HIT_AWAITING_FEEDBACK:
      return 'tag--dismissed-task';
    default:
      return null;
  }
};

const toCompletedClassname = (isWithdrawnByTargeter, targeterOutcome) => {
  if (isWithdrawnByTargeter) {
    return 'tag--withdrawn-target';
  }

  if ([TARGETER_OUTCOME.DISMISSED, TARGETER_OUTCOME.ASSESSMENT_COMPLETED, TARGETER_OUTCOME.HIT, TARGETER_OUTCOME.TARGET_PENDING].includes(targeterOutcome)) {
    return 'tag--dismissed-task';
  }

  return null;
};
export const toTaskStatusTagClassname = (taskStatus, targeterOutcome, frontlineOutcome, isWithdrawnByTargeter, isDismissedByTargeter) => {
  if (taskStatus === TASK_STATUS.ISSUED) {
    return 'tag--issued-task';
  }

  if (taskStatus === TASK_STATUS.RECEIVED) {
    return 'tag--received-target';
  }

  if (taskStatus === TASK_STATUS.COMPLETE && (isWithdrawnByTargeter || isDismissedByTargeter || (targeterOutcome && (targeterOutcome === 'HIT' || targeterOutcome === 'TARGET_PENDING')))) {
    return toCompletedClassname(isWithdrawnByTargeter, targeterOutcome);
  }

  if (taskStatus === TASK_STATUS.COMPLETE && !isWithdrawnByTargeter && !isDismissedByTargeter) {
    return toFrontlineOutcomeClassname(frontlineOutcome);
  }

  return null;
};

export const toGridItemClassname = (index) => {
  return GRID_ITEM_CLASSES[index % 2];
};
