export const COMPONENT_IDS = {
  CHECKS: 'checks',
  ATLAS_TRACE: 'atlasTrace',
  CRS_TRACE: 'crsTrace',
  CENTAUR_TRACE: 'centaurTrace',
  DVA_TRACE: 'dvaTrace',
  DDA_TRACE: 'ddaTrace',
  ENTITY_SEARCH_TRACE: 'entitySearchTrace',
  HMRC_TRACE: 'hmrcTrace',
  INTERPOL_TRACE: 'interpolTrace',
  OPEN_SOURCE_TRACE: 'openSourceTrace',
  PEGA_TRACE: 'pegaTrace',
  SIP_TRACE: 'sipTrace',
  OTHER: 'otherSystems',
  NOT_REQUIRED: 'notRequired',
};

export const CHECK_TYPE = {
  TRACE: 'TRACE',
  OTHER: 'OTHER',
};

export const SYSTEM_CHECKS = {
  ATLAS: 'ATLAS',
  CRS: 'CRS',
  CENTAUR: 'CENTAUR',
  DVA: 'DVA',
  DDA: 'DDA',
  ENTITY_SEARCH: 'ENTITY_SEARCH',
  HMRC: 'HMRC',
  INTERPOL: 'INTERPOL',
  OPEN_SOURCE: 'OPEN_SOURCE',
  PEGA: 'PEGA',
  SIP: 'SIP',
  OTHER: 'OTHER',
};

export const COMPONENT_IDS_TO_SYSTEM_CHECKS = {
  [COMPONENT_IDS.ATLAS_TRACE]: SYSTEM_CHECKS.ATLAS,
  [COMPONENT_IDS.CRS_TRACE]: SYSTEM_CHECKS.CRS,
  [COMPONENT_IDS.CENTAUR_TRACE]: SYSTEM_CHECKS.CENTAUR,
  [COMPONENT_IDS.DVA_TRACE]: SYSTEM_CHECKS.DVA,
  [COMPONENT_IDS.DDA_TRACE]: SYSTEM_CHECKS.DDA,
  [COMPONENT_IDS.ENTITY_SEARCH_TRACE]: SYSTEM_CHECKS.ENTITY_SEARCH,
  [COMPONENT_IDS.HMRC_TRACE]: SYSTEM_CHECKS.HMRC,
  [COMPONENT_IDS.INTERPOL_TRACE]: SYSTEM_CHECKS.INTERPOL,
  [COMPONENT_IDS.OPEN_SOURCE_TRACE]: SYSTEM_CHECKS.OPEN_SOURCE,
  [COMPONENT_IDS.PEGA_TRACE]: SYSTEM_CHECKS.PEGA,
  [COMPONENT_IDS.SIP_TRACE]: SYSTEM_CHECKS.SIP,
  [COMPONENT_IDS.OTHER]: SYSTEM_CHECKS.OTHER,
};

export const SYSTEM_CHECKS_TO_COMPONENT_IDS = Object.fromEntries(Object.entries(COMPONENT_IDS_TO_SYSTEM_CHECKS).map((entry) => entry.reverse()));

export const CREDIBILITY_FORM_PREFIX = 'passenger';
