import React from 'react';
import PropTypes from 'prop-types';

// Config
import { TIME_FORMATS } from '../../../../../../utils/constants';

// Component(s)
import ComponentWrapper from '../../../../../../components/ComponentWrapper/ComponentWrapper';

// Util(s)
import { getTaskId } from '../../../../../../utils/Task/taskUtil';
import { toEventTime } from '../../helper/toEventDate';
import JourneyUtil from '../../../../../../utils/Journey/Uplift/journeyUtil';

const TaskJourneyDetail = ({ task, width }) => {
  const taskId = getTaskId(task);
  const journey = JourneyUtil.get(task);
  const departureTime = JourneyUtil.departureTime(journey);
  const departureLocation = JourneyUtil.departureLoc(journey);
  const arrivalTime = JourneyUtil.arrivalTime(journey);
  const arrivalLocation = JourneyUtil.arrivalLoc(journey);

  if (width < 750) {
    return (
      <div id={`movement-journey-detail-${taskId}`} className="ho-body-xs">
        <ComponentWrapper show={departureTime && departureLocation}>
          <p className="top">
            <span className="departure-time">
              {toEventTime(departureTime, departureLocation, TIME_FORMATS.SUMMARY)}
            </span>&nbsp;&nbsp;
            <ComponentWrapper show={departureLocation}>
              <span className="ho-heading-m govuk-!-font-weight-bold">{departureLocation}</span>
            </ComponentWrapper>
          </p>
        </ComponentWrapper>
        <ComponentWrapper show={departureLocation || arrivalLocation}>
          <p className="direction right-arrow">&#8594;</p>
        </ComponentWrapper>
        <ComponentWrapper show={arrivalLocation}>
          <p className="bottom">
            <ComponentWrapper show={arrivalTime && arrivalLocation}>
              <span className="arrival-time">{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.SUMMARY)}</span>&nbsp;&nbsp;
            </ComponentWrapper>
            <span className="ho-heading-m govuk-!-font-weight-bold">
              {arrivalLocation}
            </span>
          </p>
        </ComponentWrapper>
      </div>
    );
  }
  return (
    <p id={`movement-journey-detail-${taskId}`} className="ho-body-xs">
      <ComponentWrapper show={departureTime && departureLocation}>
        <span className="departure-time">{toEventTime(departureTime, departureLocation, TIME_FORMATS.SUMMARY)}</span>&nbsp;&nbsp;
      </ComponentWrapper>
      <ComponentWrapper show={departureLocation}>
        <span className="ho-heading-m govuk-!-font-weight-bold">{departureLocation}</span>&nbsp;&nbsp;
      </ComponentWrapper>
      <ComponentWrapper show={departureLocation || arrivalLocation}>
        <span className="right-arrow">&#8594;</span>&nbsp;&nbsp;
      </ComponentWrapper>
      <ComponentWrapper show={arrivalLocation}>
        <span className="ho-heading-m govuk-!-font-weight-bold">{arrivalLocation}</span>&nbsp;&nbsp;
      </ComponentWrapper>
      <ComponentWrapper show={arrivalTime && arrivalLocation}>
        <span className="arrival-time">{toEventTime(arrivalTime, arrivalLocation, TIME_FORMATS.SUMMARY)}</span>
      </ComponentWrapper>
    </p>
  );
};

TaskJourneyDetail.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  width: PropTypes.number.isRequired,
};

export default TaskJourneyDetail;
