import { useQueryClient } from '@tanstack/react-query';

// Config(s)
import { TAB_ID, TASK_STATUS } from '../../../../../utils/constants';
import QUERY_KEYS from '../../../../../utils/Hooks/constants';

// Context/ Hook(s)
import { useNotification } from '../../../../../context/NotificationContext';
import useListPageSubscription from './useListPageSubscription';
import { useTabs } from '../../../../../context/TabContext';
import { useTasks } from '../../../../../context/TasksContext';

const useTaskListPage = () => {
  const queryClient = useQueryClient();
  const { setNotification } = useNotification();
  const { tasks, removeTasksByIds } = useTasks();
  const { selectedTab } = useTabs();

  const TAB_LABEL = {
    [TASK_STATUS.SELECTED_FOR_DISMISSAL]: 'Dismiss',
    [TASK_STATUS.IN_PROGRESS]: 'In progress',
  };

  const invalidateQueries = async () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.TASKS_FILTER_COUNTS] });
  };

  const checkTaskExists = (taskId) => {
    return !!tasks.find(({ id }) => id === taskId);
  };

  const removeTask = async (taskId) => {
    removeTasksByIds(taskId);
  };

  const handleStompMessage = async (message) => {
    const taskId = message.taskId;
    const status = message.status;

    if (selectedTab !== TAB_ID.NEW && ![TASK_STATUS.SELECTED_FOR_DISMISSAL, TASK_STATUS.IN_PROGRESS].includes(status)) {
      return; // Do nothing
    }

    if (checkTaskExists(taskId)) {
      await removeTask(taskId)
        .then(() => {
          // Update this as functionality is extended
          setNotification(`Task moved to '${TAB_LABEL[status]}' tab`);
          invalidateQueries();
        });
    }
  };

  useListPageSubscription(handleStompMessage);
};

export default useTaskListPage;
