// Global import(s)
import lookup from 'country-code-lookup';
import moment from 'moment/moment';

// Config(s)
import { DATE_FORMATS, Seconds, STRINGS, UNITS } from '../constants';

// Util(s)
import CommonUtil from '../Common/commonUtil';
import DateTimeUtil from '../Datetime/Uplift/datetimeUtil';
import { calculateTimeDifference, getFormattedDate, toDateTimeList, validateDate } from '../Datetime/datetimeUtil';
import { formatField } from '../FieldFormat/fieldFormatterUtil';
import {
  getHiddenValue,
  shouldHideDate,
  shouldHideNumber,
  shouldHideObjectArrayByAttr,
  shouldHideString,
} from '../Hiding/hidingUtil';
import { getMaskedValue, shouldMaskString, shouldMaskObjectArrayByAttr, shouldMaskDate, shouldMaskNumber } from '../Masking/maskingUtil';

const toFormattedBookedPriorToDeparture = (bookedAt, departureTime) => {
  if (!bookedAt || !departureTime) {
    return null;
  }

  if (shouldHideDate(bookedAt) || shouldHideDate(departureTime)) {
    return null;
  }

  if (shouldMaskDate(bookedAt) || shouldMaskDate(departureTime)) {
    return getMaskedValue();
  }

  const dateTimeList = toDateTimeList(bookedAt, departureTime);
  return calculateTimeDifference(dateTimeList);
};

const toFormattedCheckInTimeText = (checkInAt) => {
  const checkinPrefix = 'Check-in';
  if (!checkInAt) {
    return null;
  }

  if (shouldHideDate(checkInAt)) {
    return null;
  }

  if (shouldMaskDate(checkInAt)) {
    return getMaskedValue();
  }

  return `${checkinPrefix} ${getFormattedDate(checkInAt, DATE_FORMATS.CUSTOM_HOUR_MINUTE)}`;
};

const toFormattedBookingDate = (bookedAt) => {
  if (!bookedAt) {
    return null;
  }

  if (shouldHideDate(bookedAt)) {
    return null;
  }

  if (shouldMaskDate(bookedAt)) {
    return getMaskedValue();
  }

  return getFormattedDate(bookedAt, DATE_FORMATS.SHORT_ALT);
};

const toFormattedBookingTimeDifference = (date, departureTime) => {
  if (!date || !departureTime) {
    return null;
  }

  if (date === getHiddenValue() || departureTime === getHiddenValue()) {
    return null;
  }

  if (!validateDate(date)) {
    return null;
  }

  const dateTimeList = toDateTimeList(date, departureTime);
  return calculateTimeDifference(dateTimeList).replace(STRINGS.DAYJS_FUTURE, STRINGS.DAYJS_FUTURE_REPLACE);
};

const toFormattedTicketTypes = (tickets) => {
  if (!tickets?.length) {
    return null;
  }

  if (shouldHideObjectArrayByAttr(tickets, 'type')) {
    return null;
  }

  if (shouldMaskObjectArrayByAttr(tickets, 'type')) {
    return getMaskedValue();
  }

  return tickets
    .filter((ticket) => !!ticket?.type)
    .map((ticket) => ticket?.type)
    .join(', ') || null;
};

const toFormattedTicketNumbers = (tickets) => {
  if (!tickets?.length) {
    return null;
  }

  if (shouldHideObjectArrayByAttr(tickets, 'number')) {
    return null;
  }

  if (shouldMaskObjectArrayByAttr(tickets, 'number')) {
    return getMaskedValue();
  }

  return tickets
    .filter((ticket) => !!ticket?.number)
    .map((ticket) => ticket?.number)
    .join(', ') || null;
};

const toFormattedTicketPrices = (tickets) => {
  if (!tickets?.length) {
    return null;
  }

  if (shouldHideObjectArrayByAttr(tickets, 'price')) {
    return null;
  }

  if (shouldMaskObjectArrayByAttr(tickets, 'price')) {
    return getMaskedValue();
  }

  return tickets
    .filter((ticket) => !!ticket.price)
    .map((ticket) => {
      if (ticket.price.startsWith(UNITS.CURRENCY.unit)) {
        return ticket.price;
      }

      return formatField(UNITS.CURRENCY.name, ticket.price);
    })
    .join(', ') || null;
};

const toFormattedAgentLocation = (location) => {
  const splitLoc = location?.split(',').map((val) => val.trim());

  if (!splitLoc?.length) {
    return null;
  }

  return [splitLoc[0], CommonUtil.iso3Code(splitLoc[1])].join(', ');
};

const toFormattedCardExpiry = (expiry) => {
  if (!expiry) {
    return null;
  }

  return DateTimeUtil.format.asUTC(expiry, null, DATE_FORMATS.CUSTOM_CARD_EXPIRY);
};

const toFormattedCountryName = (isoCode) => {
  if (!isoCode) {
    return null;
  }

  if (isoCode === getHiddenValue()) {
    return null;
  }

  if (shouldMaskString(isoCode)) {
    return getMaskedValue();
  }

  try {
    return lookup?.byIso(isoCode)?.country || null;
  } catch {
    return null;
  }
};

const getContactText = (booking) => {
  if (!booking?.contactText) {
    return null;
  }

  if (shouldHideString(booking.contactText)) {
    return null;
  }

  if (shouldMaskString(booking.contactText)) {
    return getMaskedValue();
  }

  return booking.contactText;
};

const getAgentName = (agent) => {
  return agent?.name || null;
};

const getPaymentMethod = (booking) => {
  return booking?.paymentMethod || null;
};

const getAgentLocation = (agent) => {
  if (!agent?.location) {
    return null;
  }

  if (shouldHideString(agent.location)) {
    return null;
  }

  if (shouldMaskString(agent.location)) {
    return getMaskedValue();
  }

  return agent.location;
};

const getContactTextMatches = (booking) => {
  if (!booking?.contactTextMatches || !booking?.contactTextMatches?.length) {
    return [];
  }

  if (shouldHideObjectArrayByAttr(booking.contactTextMatches, 'text')) {
    return null;
  }

  // When masked, the collection will also be returned
  return booking.contactTextMatches;
};

const getPaymentCard = (payment) => {
  return payment?.card || null;
};

const getCardExpiry = (card) => {
  return card?.expiry || null;
};

const getCardLastFourDigits = (card) => {
  if (!card?.number) {
    return null;
  }

  if (shouldHideString(card.number)) {
    return null;
  }

  if (shouldMaskString(card.number)) {
    return getMaskedValue();
  }

  return card.number.slice(-4);
};

const getPaymentAmount = (payment) => {
  if (!payment?.amount) {
    return null;
  }

  if (shouldHideNumber(payment.amount)) {
    return null;
  }

  if (shouldMaskNumber(payment.amount)) {
    return getMaskedValue();
  }

  return payment.amount;
};

const hasPayments = (booking) => {
  if (!booking?.payments) {
    return false;
  }

  if (Array.isArray(booking?.payments)) {
    return !!booking?.payments?.length;
  }

  return !!Object?.keys(booking?.payments)?.length;
};

const getPayments = (booking, defaultAsNull = false) => {
  if (!hasPayments(booking)) {
    return defaultAsNull ? null : [];
  }

  return booking.payments;
};

const getAgentTelephoneContact = (agent) => {
  if (!agent) {
    return null;
  }

  const contact = CommonUtil.telephoneContact(agent);
  if (!contact || !contact?.value || shouldHideString(contact?.value)) {
    return null;
  }

  return contact.value;
};

const getAgentMobileContact = (agent) => {
  if (!agent) {
    return null;
  }

  const contact = CommonUtil.mobileContact(agent);
  if (!contact || !contact?.value || shouldHideString(contact.value)) {
    return null;
  }

  return contact.value;
};

const getAgentTelephoneContactNumber = (agent) => {
  if (!agent) {
    return null;
  }

  const phoneNumber = CommonUtil.telephoneContactNumber(agent);
  if (!phoneNumber || shouldHideString(phoneNumber)) {
    return null;
  }

  return phoneNumber;
};

const getAgentMobileContactNumber = (agent) => {
  if (!agent) {
    return null;
  }

  const mobileNumber = CommonUtil.mobileContactNumber(agent);
  if (!mobileNumber || shouldHideString(mobileNumber)) {
    return null;
  }

  return mobileNumber;
};

const getAgentAddress = (agent) => {
  if (!agent?.address || shouldHideString(agent.address.line1)) { return null; }
  return agent.address;
};

const getAgentIata = (agent) => {
  if (!agent?.iataCode) {
    return null;
  }

  if (shouldHideString(agent.iataCode)) {
    return null;
  }

  if (shouldMaskString(agent.iataCode)) {
    return getMaskedValue();
  }

  return agent.iataCode;
};

const getTaskAgentIata = (agent) => {
  if (!agent?.iata) {
    return null;
  }

  if (shouldHideString(agent.iata)) {
    return null;
  }

  if (shouldMaskString(agent.iata)) {
    return getMaskedValue();
  }

  return agent.iata;
};

const getBookingAgentIataCode = (booking) => {
  if (!booking?.agentIataCode) {
    return null;
  }

  if (shouldHideString(booking.agentIataCode)) {
    return null;
  }

  if (shouldMaskString(booking.agentIataCode)) {
    return getMaskedValue();
  }

  return booking.agentIataCode;
};

const getAgent = (booking) => {
  return booking?.agent || null;
};

const getBookedAt = (booking) => {
  if (!booking?.bookedAt) {
    return null;
  }

  if (shouldHideDate(booking.bookedAt)) {
    return null;
  }

  if (shouldMaskDate(booking.bookedAt)) {
    return getMaskedValue();
  }

  return booking.bookedAt;
};

const getBookingReference = (booking) => {
  if (!booking?.reference) {
    return null;
  }

  if (shouldHideString(booking.reference)) {
    return null;
  }

  if (shouldMaskString(booking.reference)) {
    return getMaskedValue();
  }

  return booking.reference;
};

const getBookingType = (booking) => {
  if (!booking?.type) {
    return null;
  }

  if (shouldHideString(booking.type)) {
    return null;
  }

  if (shouldMaskString(booking.type)) {
    return getMaskedValue();
  }

  return booking.type;
};

const getBookingCountry = (booking) => {
  if (!booking?.country) {
    return null;
  }

  if (shouldHideString(booking.country)) {
    return null;
  }

  if (shouldMaskString(booking.country)) {
    return getMaskedValue();
  }

  return booking.country;
};

const getAdditionalContent = (booking) => {
  if (!booking?.additionalContent || !booking?.additionalContent?.length) {
    return [];
  }

  return booking.additionalContent;
};

const getCheckInAt = (booking) => {
  if (!booking?.checkInAt) {
    return null;
  }

  if (shouldHideDate(booking.checkInAt)) {
    return null;
  }

  if (shouldMaskDate(booking.checkInAt)) {
    return getMaskedValue();
  }

  return booking.checkInAt;
};

const hasTickets = (booking) => {
  if (!booking?.tickets) {
    return false;
  }

  if (Array.isArray(booking?.tickets)) {
    return !!booking?.tickets?.length;
  }

  return !!Object?.keys(booking?.tickets)?.length;
};

const getTickets = (booking, defaultAsNull = false) => {
  if (!hasTickets(booking)) {
    return defaultAsNull ? null : [];
  }

  return booking.tickets;
};

const getRawPnrUrl = (booking) => {
  return booking?.entitySearchUrls?.poleV1Url || null;
};

const getBooking = (task) => {
  return task?.movement?.booking || null;
};

const getTimeDifferenceFromSeconds = (
  differenceInSeconds,
  diffDuration,
) => {
  if (differenceInSeconds < Seconds.MOMENT) {
    return 'a moment';
  } if (differenceInSeconds < Seconds.MINUTE) {
    const seconds = Math.abs(diffDuration.seconds());
    return `${seconds} seconds`;
  } if (differenceInSeconds < Seconds.HOUR) {
    const minutes = Math.abs(diffDuration.minutes());
    return minutes < 2 ? `${minutes} minute` : `${minutes} minutes`;
  } if (differenceInSeconds < Seconds.DAY) {
    const hours = Math.abs(diffDuration.hours());
    return hours < 2 ? `${hours} hour` : `${hours} hours`;
  } if (differenceInSeconds < Seconds.THREE_MONTHS) {
    const days = Math.floor(diffDuration.asDays());
    const hours = Math.abs(diffDuration.hours());
    return `${days} ${(days < 2) ? 'day' : 'days'} and ${hours} ${(hours < 2) ? 'hour' : 'hours'}`;
  } if (differenceInSeconds < Seconds.YEAR) {
    const months = Math.abs(diffDuration.months());
    const days = Math.abs(diffDuration.days());
    return `${months} months ${days} days`;
  } if (differenceInSeconds >= Seconds.YEAR) {
    const years = Math.abs(diffDuration.years());
    const months = Math.abs(diffDuration.months());
    return `${years} years ${months} months`;
  }
};

const getTimeDifferenceAsStringFromMins = (differenceInMins) => {
  return getTimeDifferenceFromSeconds(
    Math.abs(moment.duration(differenceInMins, 'minutes').asSeconds()),
    moment.duration(differenceInMins, 'minutes'),
  );
};

const BookingUtil = {
  additionalContent: getAdditionalContent,
  agent: getAgent,
  agentAddress: getAgentAddress,
  agentIata: getAgentIata,
  agentIataCode: getBookingAgentIataCode,
  agentLocation: getAgentLocation,
  agentMobileContact: getAgentMobileContact,
  agentMobileContactNumber: getAgentMobileContactNumber,
  agentName: getAgentName,
  agentTelephoneContact: getAgentTelephoneContact,
  agentTelephoneContactNumber: getAgentTelephoneContactNumber,
  bookedAt: getBookedAt,
  bookingCountry: getBookingCountry,
  bookingRef: getBookingReference,
  bookingType: getBookingType,
  cardExpiry: getCardExpiry,
  cardLastFourDigits: getCardLastFourDigits,
  checkInAt: getCheckInAt,
  contactText: getContactText,
  contactTextMatches: getContactTextMatches,
  get: getBooking,
  iata: getTaskAgentIata,
  paymentAmount: getPaymentAmount,
  paymentCard: getPaymentCard,
  paymentMethod: getPaymentMethod,
  payments: getPayments,
  rawPnrUrl: getRawPnrUrl,
  tickets: getTickets,
  timeDifferenceAsStringFromMins: getTimeDifferenceAsStringFromMins,
  format: {
    agentLocation: toFormattedAgentLocation,
    bookedPrior: toFormattedBookedPriorToDeparture,
    bookingDate: toFormattedBookingDate,
    bookingTimeDifference: toFormattedBookingTimeDifference,
    cardExpiry: toFormattedCardExpiry,
    checkInText: toFormattedCheckInTimeText,
    countryName: toFormattedCountryName,
    ticketNumbers: toFormattedTicketNumbers,
    ticketPrices: toFormattedTicketPrices,
    ticketTypes: toFormattedTicketTypes,
  },
};

export default BookingUtil;

export {
  getAdditionalContent,
  getAgent,
  getAgentAddress,
  getAgentIata,
  getBookingAgentIataCode,
  getAgentLocation,
  getAgentMobileContact,
  getAgentMobileContactNumber,
  getAgentName,
  getAgentTelephoneContact,
  getAgentTelephoneContactNumber,
  getBookedAt,
  getBooking,
  getBookingCountry,
  getBookingReference,
  getBookingType,
  getCardExpiry,
  getCardLastFourDigits,
  getCheckInAt,
  getContactText,
  getContactTextMatches,
  getPaymentAmount,
  getPaymentCard,
  getPaymentMethod,
  getPayments,
  getRawPnrUrl,
  getTickets,
  getTimeDifferenceAsStringFromMins,
  toFormattedAgentLocation,
  toFormattedBookedPriorToDeparture,
  toFormattedBookingDate,
  toFormattedBookingTimeDifference,
  toFormattedCardExpiry,
  toFormattedCheckInTimeText,
  toFormattedCountryName,
  toFormattedTicketNumbers,
  toFormattedTicketPrices,
  toFormattedTicketTypes,
  getTaskAgentIata,
};
