// Global import(s)
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Context(s)
import { useKeycloak } from '../../context/Keycloak';
import { usePermission } from '../../context/PermissionContext';
import { useTabs } from '../../context/TabContext';
import { useTasks } from '../../context/TasksContext';
import { useView } from '../../context/ViewContext';

// Config
import config from '../config';
import QUERY_KEYS from './constants';

// Services
import AxiosRequests from '../../api/axiosRequests';

// Hook(s)
import { useAxiosInstance } from '../Axios/axiosInstance';

// Utils
import { filtersToRuleNames } from '../../routes/Task/TaskList/helper/adaptFilters';

const useFetchTaskIds = (requestParams, enabled) => {
  const keycloak = useKeycloak();
  const source = axios.CancelToken.source();
  const apiClient = useAxiosInstance(keycloak, config.taskApiUrl);
  const { canReadTask, hasRole } = usePermission();
  const { DEFAULTS } = useTabs();
  const { setTaskIds } = useTasks([]);
  const { view } = useView();

  const getTaskIds = async (params) => {
    if (!canReadTask || !hasRole(DEFAULTS?.[view]?.rbac?.readRole)) {
      return [];
    }
    return AxiosRequests.getTaskIds(apiClient, filtersToRuleNames(params));
  };

  const { data: taskIds } = useQuery({
    queryKey: [QUERY_KEYS.TASK_IDS, requestParams],
    queryFn: () => getTaskIds(requestParams),
    initialData: [],
    enabled,
  });

  useEffect(() => {
    setTaskIds(taskIds);

    return () => AxiosRequests.cancel(source);
  }, [taskIds]);
};

export default useFetchTaskIds;
